import { StoredCookies } from 'constants/constants';
import {
  ImmerReducer,
  createActionCreators,
  createReducerFunction,
} from 'immer-reducer';
import Cookies from 'js-cookie';
import { LanguageState } from 'types/state';

const initialState: LanguageState = {
  currentLocale: Cookies.get(StoredCookies.Locale),
};

class LanguageStateReducer extends ImmerReducer<LanguageState> {
  public setCurrentLocale(newLocale: string) {
    this.draftState.currentLocale = newLocale;
  }
}

export const LanguageStateActions = createActionCreators(LanguageStateReducer);
export default createReducerFunction(LanguageStateReducer, initialState);
