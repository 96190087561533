import React, { useState, Fragment, useEffect } from 'react';
import YouTube from 'react-youtube-embed';
import FooterLegal from 'components/Footer/FooterLegal';
import Icon from 'components/Icon';
import DonorReceipt from 'components/DonorReceipt';
import ActionButton from 'components/ActionButton';
import { Classes, Dialog } from '@blueprintjs/core';
import { useTheme } from 'theming';
import { Location } from 'history';
import {
  DonationTemplate,
  SuccessfulPaymentResponse,
} from '@smile-train/common';
import { DonationTheme } from 'themes';
import { Header } from 'forms';
import {
  checkWindowForUkUrl,
  getSeparatedTemplateContent,
  gtmEventSetConfirmationPageView,
} from 'shared/utils';
import { APP_THEME } from 'constants/constants';
import BannerAndTopText from './BannerAndTopText';

interface Props {
  donationTemplate: DonationTemplate;
  location: Location<SuccessfulPaymentResponse>;
}

const ConfirmationPageContent: React.FC<Props> = ({
  donationTemplate,
  location,
}) => {
  const {
    isUk,
    topTextAboveDonationButton,
    showThankYouVideo,
    thankYouVideoId,
    thankYouVideoOptions,
    templateTheme,
    domainUrl,
    donationFormConfirmationContent,
  } = donationTemplate;

  const {
    transactionId,
    transactionDetail: {
      cardholderName,
      billingAddress,
      billingCity,
      billingPostalCode,
      billingState,
      ipAddress,
      paymentDate,
      paymentPlan,
      paymentMethod,
      processorPayload,
      formattedAmount,
    },
    contactInfo: { email, firstName, lastName },
  } = location.state;

  const templatedContent = {
    pageHeader: getSeparatedTemplateContent(
      donationFormConfirmationContent.confirmationPageTitle
    ),
    pageText: getSeparatedTemplateContent(
      donationFormConfirmationContent.confirmationPageText
    ),
    receiptButtonDescription: getSeparatedTemplateContent(
      donationFormConfirmationContent.viewReceiptButtonDescription
    ),
  };

  const isUkUrl = checkWindowForUkUrl();

  const theme = useTheme();
  const appTheme: DonationTheme = theme ? theme[templateTheme] : '';

  const [shareOnSocialDialog, setShareOnSocialDialog] = useState(false);
  const [receiptDialog, setReceiptDialog] = useState(false);

  const sharePath = isUk ? 'tydonatefbuk' : 'tydonatetw';
  const shareTwitterHandle = isUk ? 'SmileTrainUK' : 'SmileTrain';
  const currencySymbol = isUk ? '£' : '$';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    gtmEventSetConfirmationPageView(domainUrl);
  }, [domainUrl]);

  return (
    <Fragment>
      <div style={{ ...appTheme.page }}>
        {(templateTheme !== APP_THEME.FIFTYFIFTY || showThankYouVideo) && (
          <Header
            headerStyle={appTheme.header}
            logoStyle={appTheme.logoImage}
            logoHref={
              isUkUrl
                ? 'https://www.smiletrain.org.uk'
                : 'https://www.smileTrain.org'
            }
          />
        )}
        {!showThankYouVideo && (
          <BannerAndTopText
            logoHref={
              isUkUrl
                ? 'https://www.smiletrain.org.uk'
                : 'https://www.smileTrain.org'
            }
            text={
              templatedContent.pageHeader[0] +
              firstName +
              templatedContent.pageHeader[1]
            }
          />
        )}
        <div style={appTheme.mainContent}>
          {showThankYouVideo && thankYouVideoId && (
            <div className="container thank-you-video">
              <YouTube id={thankYouVideoId} appendSrc={thankYouVideoOptions} />
            </div>
          )}
          <div className="container-pad module">
            <div style={{ textAlign: 'center' }}>
              <p id="confirmation-text-top">
                {templatedContent.pageText[0]}
                {`${paymentPlan.toLowerCase()}`}{' '}
                {`${currencySymbol}${formattedAmount}`}
                {templatedContent.pageText[1]}
                {paymentDate}
                {templatedContent.pageText[2]}
                {paymentMethod}
                {templatedContent.pageText[3]}
              </p>
              <p id="confirmation-text-bottom">
                {donationFormConfirmationContent.confirmationEmailText} {email}.
              </p>
              {paymentMethod === 'Apple Pay' && topTextAboveDonationButton && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: topTextAboveDonationButton,
                  }}
                />
              )}
            </div>

            <h3 className="confirmation-subheader" id="confirmation-subheader">
              {donationFormConfirmationContent.confirmationSubheader}
            </h3>

            <ActionButton>
              <button type="button" onClick={() => setReceiptDialog(true)}>
                <ActionButton.Title id="confirmation-view-receipt-title">
                  {donationFormConfirmationContent.viewReceiptButtonTitle}
                </ActionButton.Title>
                <p id="confirmation-view-receipt">
                  {templatedContent.receiptButtonDescription[0]}
                  {!isUk && ' tax-deductible '}
                  {templatedContent.receiptButtonDescription[1]}
                </p>
              </button>
            </ActionButton>
            <Dialog
              className="DonorReceipt__dialog"
              isOpen={receiptDialog}
              onClose={() => setReceiptDialog(false)}
            >
              <div className={Classes.DIALOG_BODY}>
                <DonorReceipt
                  isUk={isUk}
                  donorName={`${firstName} ${lastName}`}
                  billingAddress={billingAddress}
                  billingCity={billingCity}
                  billingState={billingState}
                  billingPostalCode={billingPostalCode}
                  giftAmount={formattedAmount}
                  currencySymbol={currencySymbol}
                  giftDate={paymentDate}
                  transactionId={transactionId}
                  ipAddress={ipAddress}
                  paymentPlan={paymentPlan}
                  paymentMethod={paymentMethod}
                  cardholderName={cardholderName}
                  processorPayload={processorPayload}
                  handleClose={() => setReceiptDialog(false)}
                />
              </div>
            </Dialog>
            <ActionButton>
              <button
                type="button"
                onClick={() => setShareOnSocialDialog(true)}
              >
                <ActionButton.Title id="confirmation-social-share-title">
                  {donationFormConfirmationContent.socialButtonTitle}
                </ActionButton.Title>
                <p id="confirmation-social-share">
                  {donationFormConfirmationContent.socialButtonDescription}
                </p>
              </button>
            </ActionButton>
            <Dialog
              title={donationFormConfirmationContent.socialModalTitle}
              isOpen={shareOnSocialDialog}
              onClose={() => setShareOnSocialDialog(false)}
            >
              <div className={Classes.DIALOG_BODY}>
                <button
                  type="button"
                  className="socialButton"
                  onClick={() =>
                    window.open(
                      `https://www.facebook.com/sharer/sharer.php?u=http://smiletra.in/${sharePath}&display=popup&ref=plugin&src=share_button`,
                      'sharer'
                    )
                  }
                >
                  <Icon name="Facebook" />
                  <h3
                    id="confirmation-social-share-facebook"
                    className="socialButton__title"
                  >
                    {donationFormConfirmationContent.socialModalFacebookText}
                  </h3>
                </button>
                <button
                  type="button"
                  className="socialButton"
                  onClick={() =>
                    window.open(
                      `http://twitter.com/share?text=I support @${shareTwitterHandle} giving children with clefts the %23PowerOfASmile &url=http://smiletra.in/${sharePath}`,
                      'sharer'
                    )
                  }
                >
                  <Icon name="Twitter" />
                  <h3
                    id="confirmation-social-share-twitter"
                    className="socialButton__title"
                  >
                    {donationFormConfirmationContent.socialModalTwitterText}
                  </h3>
                </button>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <button
                    type="button"
                    id="confirmation-social-share-close-button"
                    className="btn btn--secondary btn--solo"
                    onClick={() => setShareOnSocialDialog(false)}
                  >
                    {donationFormConfirmationContent.socialModalCloseButton}
                  </button>
                </div>
              </div>
            </Dialog>

            <ActionButton>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  !isUk
                    ? 'https://greatnonprofits.org/reviews/write/smile-train-inc'
                    : 'https://g.page/r/CXm7mMqtyaAqEB0/review'
                }
              >
                <ActionButton.Title id="confirmation-rate-us-title">
                  {donationFormConfirmationContent.rateUsButtonTitle}
                </ActionButton.Title>
                <p id="confirmation-rate-us">
                  {donationFormConfirmationContent.rateUsButtonDescription}
                </p>
              </a>
            </ActionButton>

            <ActionButton>
              <a
                href={
                  !isUk
                    ? 'https://www.smiletrain.org'
                    : 'https://smiletrain.org.uk'
                }
              >
                <ActionButton.Title id="confirmation-go-back-title">
                  {donationFormConfirmationContent.homepageButtonTitle}
                </ActionButton.Title>
                <p id="confirmation-go-back">
                  {donationFormConfirmationContent.homepageButtonDescription}
                </p>
              </a>
            </ActionButton>
            <div
              className="container"
              style={{ textAlign: 'center', marginTop: '30px' }}
            >
              <FooterLegal isUk={isUk} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmationPageContent;
