import React, { useEffect, useRef } from 'react';

import { useTypedSelector } from 'shared/utils';
import { DonationTemplateSchema } from 'types/shared';
import { DonationTheme } from 'themes';
import CommunicationPreferencesStatements from 'content/CommunicationPreferencesStatements';
import BillingInformation from './BillingInformation';

interface Props {
  theme: DonationTheme;
  values: any;
  setFieldValue: (
    field: string,
    newValue: any,
    shouldValidate?: boolean
  ) => void;
}

const AddressForm = ({ theme, values, setFieldValue }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const donationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  ) as DonationTemplateSchema;

  const {
    defaultCountry,
    donationTemplate: { hideExtraBillingFields },
  } = donationTemplateSchema;

  useEffect(() => {
    setFieldValue('billingCountry', defaultCountry.toString());
  }, [setFieldValue, defaultCountry]);

  return (
    <div className="container module" ref={inputRef}>
      {!hideExtraBillingFields && (
        <BillingInformation theme={theme} values={values} />
      )}
      <CommunicationPreferencesStatements
        theme={theme}
        values={values}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default AddressForm;
