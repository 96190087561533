import React from 'react';

export default function CheckCircle() {
  return (
    <svg width="24" height="24">
      <g>
        <path
          fill="#fff"
          d="M12,1A11,11,0,1,0,23,12,11.012,11.012,0,0,0,12,1Z"
        />
      </g>
    </svg>
  );
}
