import Backend from 'services/Backend/Backend';
import { SchemaActions } from 'redux/dux/donationTemplate';

export default function getDonationTemplate({
  topDomain,
  templateSlug,
  giftArrayParam,
  locale,
}: {
  topDomain: string;
  templateSlug: string;
  giftArrayParam: any;
  locale?: string;
}) {
  return async dispatch => {
    try {
      dispatch(SchemaActions.setDonationTemplateIsLoading(true));
      const normalizedTopDomain = topDomain.toLowerCase();
      const normalizedTemplateSlug = templateSlug.toLowerCase();

      const { result } = await Backend.getDonationTemplateSchema({
        topDomain: normalizedTopDomain,
        templateSlug: normalizedTemplateSlug,
        giftArrayParam,
        locale: locale,
      });

      if (result.redirect) {
        dispatch(SchemaActions.setDonationTemplateError(true));
        dispatch(SchemaActions.setDonationTemplateIsLoading(false));
        console.log('Error Getting Template');
      } else {
        dispatch(SchemaActions.setDonationTemplateSchema(result));
        dispatch(SchemaActions.setDonationTemplateError(false));
        dispatch(SchemaActions.setDonationTemplateIsLoading(false));
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };
}
