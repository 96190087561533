import { SchemaState } from 'types/state';
import { DonationTemplateSchema } from 'types/shared';
import {
  ImmerReducer,
  createActionCreators,
  createReducerFunction,
} from 'immer-reducer';

const initialState: SchemaState = {
  // @ts-ignore
  donationTemplateSchema: undefined,
  isLoading: true,
  donationTemplateError: false,
};

class DonationTemplateReducer extends ImmerReducer<SchemaState> {
  public setDonationTemplateSchema(
    donationTemplateSchema: DonationTemplateSchema
  ) {
    this.draftState.donationTemplateSchema = donationTemplateSchema;
  }

  public setDonationTemplateError(err: boolean) {
    this.draftState.donationTemplateError = err;
  }

  public setDonationTemplateIsLoading(isLoading: boolean) {
    this.draftState.isLoading = isLoading;
  }
}

export const SchemaActions = createActionCreators(DonationTemplateReducer);
export default createReducerFunction(DonationTemplateReducer, initialState);
