import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApplePaySession } from 'braintree-web';
import { css } from 'aphrodite';
import './apple-pay.css';
import { PaymentMethod } from 'constants/constants';
import { PaymentActions } from 'redux/dux/payment';
import BraintreeService from 'services/Braintree/Braintree.service';
import { FormikValues } from 'formik';
import { getSubmitValues } from './utils';
import { handlePaymentSuccess } from 'redux/thunks/handlePaymentSuccess';
import { GenericObject } from 'types/utils';
import { submitPayment } from 'redux/thunks/submitPayment';
import { EMAIL_REGEX } from '@smile-train/common';

interface Props {
  values: FormikValues;
  history: GenericObject;
  match: GenericObject;
  styleInfo: GenericObject;
  showGooglePay: boolean;
  validateForm: () => Record<string, string>;
}

const ApplePayButton = ({
  values,
  history,
  match,
  styleInfo,
  showGooglePay,
  validateForm,
}: Props) => {
  const dispatch = useDispatch();

  const [applePayStarting, setApplePayStarting] = useState(false);

  async function handleApplePayClick() {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length !== 0) {
        throw new Error(errors[Object.keys(errors)[0]]);
      }
      dispatch(PaymentActions.setPaymentMethod(PaymentMethod.APPLE_PAY));
      const donationAmount: string =
        values.donationAmount !== 'OtherAmount'
          ? values.donationAmount
          : values.otherAmount;

      if (!donationAmount) {
        dispatch(
          PaymentActions.setError(
            'You must set an amount before using Apple Pay.'
          )
        );
        return;
      }

      if (!values.personalFirstName || !values.personalLastName) {
        dispatch(
          PaymentActions.setError(
            'Please fill out the Personal Information fields.'
          )
        );
        return;
      }

      const regex = new RegExp(EMAIL_REGEX);

      if (!regex.test(values.personalEmail)) {
        dispatch(PaymentActions.setError('Please enter a valied email.'));
        return;
      }

      const braintreeService = await BraintreeService.init();
      const applePayPaymentRequest = await braintreeService.createApplePayPaymentRequest(
        donationAmount
      );

      if (!applePayPaymentRequest) {
        throw new Error(
          'Error creating Apple Pay request. Please try a different payment method, or try Apple Pay again after reloading page.'
        );
      }

      const session: ApplePaySession = new (window as any).ApplePaySession(
        3,
        applePayPaymentRequest
      );
      const applePayInstance = braintreeService.getApplePayClient();

      if (!session) {
        throw new Error('No Apple Pay session created');
      }
      if (!applePayInstance) {
        throw new Error('No Apple Pay instance available');
      }

      session.onvalidatemerchant = function(event) {
        try {
          applePayInstance.performValidation(
            {
              validationURL: event.validationURL,
              displayName: 'Smile Train',
            },
            (error, merchantSession) => {
              if (error) {
                session.abort();
                dispatch(
                  PaymentActions.setError(
                    error.message ? error.message : 'An unknown error occured.'
                  )
                );
              }

              session.completeMerchantValidation(merchantSession);
            }
          );
        } catch (error) {
          session.abort();
          dispatch(
            PaymentActions.setError(
              error.message ? error.message : 'An unknown error occured.'
            )
          );
        }
      };

      session.onpaymentauthorized = async function(event) {
        applePayInstance.tokenize(
          { token: event.payment.token },
          async (tokenizeError, payload) => {
            try {
              if (tokenizeError) {
                session.completePayment(1);
                throw new Error(
                  'Apple Pay failed. Please try another payment method or try Apple Pay again in a few moments.'
                );
              }
              const updatedValues = getSubmitValues(values, event);

              const returnValue = await submitPayment({
                history,
                match,
                options: {
                  isBraintree: true,
                  payload: payload,
                },
                formikValues: updatedValues,
                paymentType: PaymentMethod.APPLE_PAY,
              });

              const response = returnValue?.response;
              const success = returnValue?.success;
              if (success) {
                session.completePayment(0);
                dispatch(handlePaymentSuccess(history, match, response));
              } else {
                session.completePayment(1);
                throw new Error(
                  response?.data.result.paymentResponse.message ||
                    'An unknown error occurred. Payment Declined.'
                );
              }
            } catch (error) {
              dispatch(
                PaymentActions.setError(
                  error.message ? error.message : 'An unknown error occurred.'
                )
              );
            }
          }
        );
      };

      await session.begin();
    } catch (error) {
      // Displays tooltip if user presses the Apple Pay button more than once. Checking against error.message may be brittle?
      if (error.message === 'Page already has an active payment session.') {
        setApplePayStarting(true);
        setTimeout(() => {
          setApplePayStarting(false);
        }, 3000);
        return;
      }
      dispatch(
        PaymentActions.setError(
          error.message ? error.message : 'An unknown error occurred'
        )
      );
    }
  }

  return (
    <div
      className={`${css(
        showGooglePay
          ? styleInfo.GoogleAndAppleButtons
          : styleInfo.GoogleOrAppleButton
      )}`}
    >
      <div
        style={{ width: '100%', height: '100%' }}
        className={`apple-pay-button apple-pay-button-black`}
        onClick={handleApplePayClick}
      />
      {applePayStarting && (
        <div className={`${css(styleInfo.tooltip)}`}>
          <p style={{ margin: 0 }}>Initializing. Please wait.</p>
        </div>
      )}
    </div>
  );
};

export default ApplePayButton;
