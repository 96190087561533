import React from 'react';

export default function ChevronLeft() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36">
      <g fill="#6caadc">
        <path d="M23.12 11.12L21 9l-9 9 9 9 2.12-2.12L16.24 18z" />
      </g>
    </svg>
  );
}
