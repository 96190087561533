import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'theming';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { FocusStyleManager } from '@blueprintjs/core';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import configureStore from './redux/store';
import theme from './themes';
import AppRedirect from 'components/AppRedirect/AppRedirect';
import { history } from 'redux/store';
import { DEFAULT_TEMPLATE } from '@smile-train/common';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

// https://blueprintjs.com/docs/#core/accessibility.focus-management
FocusStyleManager.onlyShowFocusOnTabs();

const isIE = /Trident\/|MSIE /.test(window.navigator.userAgent);
isIE && document.body.classList.add('ie');

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route
            path="/.well-known/apple-developer-merchantid-domain-association"
            //@ts-ignore
            render={() => window.location.reload()}
          />
          <Route path="/:template" component={AppRedirect} />
          <Route render={() => <Redirect to={`/${DEFAULT_TEMPLATE}`} />} />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
