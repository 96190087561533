import React from 'react';

export default function Smile(props) {
  const color = props.color || '#fff';
  return (
    <svg viewBox="0 0 35.9 31.07" width="44" height="37">
      <path
        d="M31.42,13.52h0v0h-.15A13.47,13.47,0,0,0,18,2a13.83,13.83,0,0,0-2,.15A13.48,13.48,0,0,0,4.61,13.48H4.5a2.5,2.5,0,0,0,0,5l.26,0a13.49,13.49,0,0,0,26.37,0l.29,0h0a2.48,2.48,0,0,0,0-5Zm-5.71,5a8.31,8.31,0,0,1-15.54,0,1.08,1.08,0,0,1,.63-1.39,1.19,1.19,0,0,1,.37-.06,1.07,1.07,0,0,1,1,.69,6.16,6.16,0,0,0,11.52,0,1.06,1.06,0,0,1,1-.69,1.21,1.21,0,0,1,.38.06A1.08,1.08,0,0,1,25.71,18.55Z"
        transform="translate(0 0)"
        fill={color}
      />
      <path
        d="M33,11.8A15.59,15.59,0,0,0,18,0a16.46,16.46,0,0,0-2.32.17A15.4,15.4,0,0,0,2.92,11.76a4.5,4.5,0,0,0,.26,8.52A15.59,15.59,0,0,0,17.93,31.07a16,16,0,0,0,3.3-.35A15.53,15.53,0,0,0,32.7,20.29,4.48,4.48,0,0,0,33,11.8Zm-1.56,6.68h0l-.29,0a13.49,13.49,0,0,1-26.37,0l-.26,0a2.5,2.5,0,0,1,0-5h.11A13.48,13.48,0,0,1,15.94,2.15,13.83,13.83,0,0,1,18,2,13.47,13.47,0,0,1,31.27,13.48h.15v0h0a2.48,2.48,0,0,1,0,5Z"
        transform="translate(0 0)"
        fill="#4c79bc"
      />
      <path
        d="M25.08,17.16a1.21,1.21,0,0,0-.38-.06,1.06,1.06,0,0,0-1,.69,6.16,6.16,0,0,1-11.52,0,1.07,1.07,0,0,0-1-.69,1.19,1.19,0,0,0-.37.06,1.08,1.08,0,0,0-.63,1.39,8.31,8.31,0,0,0,15.54,0A1.08,1.08,0,0,0,25.08,17.16Z"
        transform="translate(0 0)"
        fill="#4c79bc"
      />
    </svg>
  );
}
