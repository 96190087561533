import React from 'react';

export default function PayPal(props) {
  const color = props.color || '#4c79bc';
  return (
    <svg viewBox="0 0 32 32" width="24" height="24">
      <g fill={color}>
        <path
          fill={color}
          d="M26.88634,8.09036c-0.00195,0.01318-0.00243,0.01621-0.00594,0.03976 C26.88039,8.13012,26.88326,8.11094,26.88634,8.09036z"
        />
        <path
          fill={color}
          d="M26.88634,8.09036C26.89547,8.02925,26.89174,8.05405,26.88634,8.09036L26.88634,8.09036z"
        />
        <path
          fill={color}
          d="M25.53143,2.41288C24.04968,0.72405,21.37121,0,17.94475,0H8.00008 c-0.70103,0-1.29677,0.50984-1.4065,1.20115L2.45281,27.46273c-0.08228,0.51778,0.3187,0.98694,0.84355,0.98694h6.13945 l1.54194-9.78006l-0.04781,0.30627c0.10978-0.69131,0.70103-1.20115,1.4012-1.20115h2.91748 c5.73134,0,10.21907-2.32795,11.52999-9.06222c0.0389-0.19914,0.10178-0.58239,0.10178-0.58239 C27.2522,5.6382,26.87774,3.94688,25.53143,2.41288z"
        />
        <path d="M28.43438,9.40539c-1.42551,6.63-5.9724,10.13932-13.18571,10.13932h-2.61656L10.68093,32 h4.24386c0.61254,0,1.13389-0.44609,1.22946-1.05069l0.05046-0.26375l0.97543-6.18191l0.06289-0.34166 c0.09557-0.6046,0.61692-1.05069,1.22946-1.05069h0.77451c5.0135,0,8.93915-2.03676,10.08632-7.92742 C29.79301,12.82066,29.57269,10.83793,28.43438,9.40539z" />
      </g>
    </svg>
  );
}
