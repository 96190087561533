import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { StyleSheet, css } from 'aphrodite';
import { useDispatch } from 'react-redux';

import './UpsellModal.css';
import { useTypedSelector } from 'shared/utils';
import { DonationTemplateSchema } from 'types/shared';
import { PaymentActions } from 'redux/dux/payment';
const smiletrainIcon = require('../../images/SmileTrain-brandicon.png');

interface Props {
  upsellAmount: number | undefined;
  originalAmount: number;
  showUpsell: boolean;
  setShowUpsell: (b: boolean) => void;
  setFieldValue: (
    field: string,
    newValue: any,
    shouldValidate?: boolean
  ) => void;
  monthlyAmounts: number[];
  setHasSelectedMonthly: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpsellModal = ({
  showUpsell,
  setShowUpsell,
  upsellAmount,
  originalAmount,
  setFieldValue,
  monthlyAmounts,
  setHasSelectedMonthly,
}: Props) => {
  const dispatch = useDispatch();
  const {
    donationTemplate: {
      defaultCurrency: { icon },
    },
  }: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  return (
    <Dialog
      isOpen={showUpsell}
      className={css(styles.dialog)}
      onClose={() => setShowUpsell(false)}
    >
      <div className={css(styles.container)}>
        <h2 className={css(styles.heading)} id="upsell-header">
          Become a monthly supporter
        </h2>
        <p className={css(styles.subHeading)} id="upsell-subheader">
          Your reliable monthly gift will help us save more children’s lives.
        </p>

        <button
          className={css(styles.donateButton)}
          id="upsell-accept-button"
          onClick={() => {
            setHasSelectedMonthly(true);
            setFieldValue('giftFrequency', 'isMonthly');
            if (upsellAmount && monthlyAmounts.includes(upsellAmount)) {
              // We're using setTimeout here to schedule these field value changes after all of the initial rendering has finished.
              // We could possibly refactor this to use a useEffect approach instead, but this felt like a simpler approach.
              setTimeout(() => {
                setFieldValue('donationAmount', upsellAmount.toString());
              }, 100);
            } else if (upsellAmount) {
              setTimeout(() => {
                setFieldValue('donationAmount', 'OtherAmount');
                setFieldValue('otherAmount', upsellAmount.toString());
              }, 100);
            } else {
              return;
            }
            setShowUpsell(false);
            dispatch(PaymentActions.setUpsell('Monthly'));
          }}
        >
          <img
            src={smiletrainIcon}
            alt="smile train icon"
            className={css(styles.smiletrainIcon)}
          />
          Donate {icon}
          {upsellAmount}/month
        </button>

        <button
          className={css(styles.keepMyGiftButton)}
          id="upsell-decline-button"
          onClick={() => {
            setShowUpsell(false);
          }}
        >
          Keep my one-time {icon}
          {originalAmount} gift
        </button>
      </div>
    </Dialog>
  );
};

export default UpsellModal;

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: '#efefef',
    maxWidth: 600,
    paddingBottom: 25,
    minWidth: 300,
    fontSize: 14,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#444',
  },
  heading: {
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '26px auto 14px',
  },
  subHeading: {
    '@media (max-width: 500px)': {
      padding: '0 10px',
    },
    padding: '0 75px 10px',
    textAlign: 'center',
  },
  donateButton: {
    '@media (max-width: 500px)': {
      width: 150,
    },
    width: 246,
    height: 51,
    margin: '0 auto',
    backgroundColor: '#4b79bc',
    color: '#fff',
    borderRadius: 8,
    border: 'none',
    fontSize: 18,
    fontWeight: 800,
    cursor: 'pointer',
    position: 'relative',
  },
  smiletrainIcon: {
    width: 63,
    height: 63,
    position: 'absolute',
    top: -6,
    left: -38,
  },
  keepMyGiftButton: {
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    margin: '18px auto 0',
    cursor: 'pointer',
  },
});
