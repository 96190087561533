import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import form from 'redux/dux/form/form';
import donationTemplateSchema from 'redux/dux/donationTemplate';
import payment from 'redux/dux/payment';
import currentLanguage from 'redux/dux/currentLanguage';

export default history =>
  combineReducers({
    router: connectRouter(history),
    form,
    payment,
    donationTemplateSchema,
    currentLanguage,
  });
