import { AppThunk } from 'redux/store';
import { PaymentMethod } from 'constants/constants';
import { PaymentActions } from 'redux/dux/payment';
import React from 'react';
import BraintreeService from 'services/Braintree/Braintree.service';

export const handlePaymentChange = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  paymentMethod: PaymentMethod
): AppThunk => async (dispatch, getState) => {
  e.preventDefault();
  // Close Paypal popup if it exists and switching to a different method
  if (paymentMethod !== PaymentMethod.PAY_PAL) {
    const braintreeService = await BraintreeService.init();
    braintreeService.closePaypalWindow();
  }
  dispatch(PaymentActions.setPaymentMethod(paymentMethod));
};
