import Axios, { AxiosResponse } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';

import { GenericObject } from 'types/utils';
import { PaymentActions } from 'redux/dux/payment';
import { getDonationPostData } from 'forms/PaymentForm/utils';
import { handlePaymentSuccess } from './handlePaymentSuccess';
import {
  getIpAddress,
  gtmEventDonationCompleted,
  gtmEventSetEeGeneralDonationComplete,
  returnThreeDSecureVerificationData,
} from 'shared/utils';
import {
  DefaultMailCodes,
  PaymentMethod,
  ThreeDSecureVerificationFailureStatus,
  IS_STAGING_OR_PRODUCTION,
} from 'constants/constants';
import { DonateResponse } from 'types/shared';
import BraintreeService from '../../services/Braintree/Braintree.service';
import { store } from 'index';
import { ThunkDispatch } from 'redux-thunk';
import { FormState } from 'types/state';
import { AnyAction } from 'redux';
import Backend from 'services/Backend/Backend';

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_USERNAME,
  REACT_APP_API_PASSWORD,
  REACT_APP_IS_3DS_ENABLED,
  REACT_APP_IS_3DS_ACTIVE_US,
} = process.env;

export interface Options {
  isBraintree?: boolean;
  payload?: GenericObject;
  isAch?: true;
  metadata?: any;
  public_token?: string;
  details?: any;
}
export type OptionsPartial = Partial<Options>;

interface SubmitPaymentParams {
  history: any;
  match: any;
  options: Options;
  googleRecaptchaToken?: string;
  formikValues: any;
  paymentType?: PaymentMethod;
}

export const submitPayment = async (
  submitValuesParams: SubmitPaymentParams
) => {
  const getState = store.getState;
  const dispatch: ThunkDispatch<FormState, unknown, AnyAction> = store.dispatch;
  const {
    donationTemplateSchema: {
      donationTemplateSchema: {
        donationTemplate: {
          hideExtraBillingFields,
          currency,
          donationTemplateId,
          domainUrl,
          isUk,
        },
        merchantAccountId,
      },
    },
    payment: {
      currentPaymentMethod,
      cardholderName,
      doubleTheDonation,
      giftAid,
    },
  } = getState();
  const {
    history,
    match,
    options,
    googleRecaptchaToken,
    formikValues,
    paymentType,
  } = submitValuesParams;
  const { donationName } = formikValues;
  const braintreeService = await BraintreeService.init();
  const braintreeDeviceData = await braintreeService.getDeviceData();
  const ipAddress = await getIpAddress();

  const threeDSecureVerificationData = returnThreeDSecureVerificationData({
    options,
    formikValues,
    ipAddress,
    hideExtraBillingFields,
  });

  await Backend.saveDonorMerchantAccount({
    firstName: formikValues.personalFirstName,
    lastName: formikValues.personalLastName,
    donationTemplateId,
    domainUrl,
    merchantAccountId,
    currency,
  });

  const shouldUse3DS =
    currentPaymentMethod === 'creditCard' &&
    REACT_APP_IS_3DS_ENABLED === 'true' &&
    (isUk || REACT_APP_IS_3DS_ACTIVE_US === 'true');

  let threeDSecureResult;
  if (shouldUse3DS) {
    dispatch(PaymentActions.setIsThreeDSecureProcessing(true));

    IS_STAGING_OR_PRODUCTION &&
      datadogLogs.setGlobalContextProperty('email', formikValues.personalEmail);

    try {
      threeDSecureResult = await braintreeService.threeDSecureVerifyCard(
        // Ignoring the types from `braintree-web` here because they don't line
        // up with BT's documentation.
        //@ts-ignore
        threeDSecureVerificationData
      );
    } catch (error) {
      await Backend.save3dsErrorLog({
        isUk,
        failureStatus: undefined,
        verificationResult: undefined,
        cardinalSdkActionCode: undefined,
        errorMessage: error.message,
      });

      if (
        error.details.originalError.details.originalError.error.message ===
        'Credit card type is not accepted by this merchant account.'
      ) {
        dispatch(PaymentActions.setIsThreeDSecureProcessing(false));
        dispatch(
          PaymentActions.setError(
            'American Express is not supported in your region, please try another payment method.'
          )
        );

        IS_STAGING_OR_PRODUCTION &&
          datadogLogs.logger.info('3DS Error', {
            isUk: isUk,
            paymentMethod: PaymentMethod.CREDIT_CARD,
            message:
              'Credit card type is not accepted by this merchant account.',
            error: error.message,
            environment: process.env.REACT_APP_NODE_ENV,
          });

        return;
      }
    }

    const threeDSecureVerificationStatus =
      threeDSecureResult?.threeDSecureInfo?.status;
    switch (true) {
      case !isUk: {
        break;
      }
      case !threeDSecureResult: {
        dispatch(PaymentActions.setIsThreeDSecureProcessing(false));
        dispatch(
          PaymentActions.setError(
            'Unable to verify credit card, please try again. If the issue persists please try a different payment method.'
          )
        );

        await Backend.save3dsErrorLog({
          isUk,
          failureStatus: undefined,
          verificationResult: threeDSecureResult,
          cardinalSdkActionCode: undefined,
          errorMessage:
            'No result returned from threeDSecureVerifyCard method.',
        });

        IS_STAGING_OR_PRODUCTION &&
          datadogLogs.logger.info('3DS Error', {
            isUk: isUk,
            paymentMethod: PaymentMethod.CREDIT_CARD,
            message: 'No result returned from threeDSecureVerifyCard method.',
            error: undefined,
            environment: process.env.REACT_APP_NODE_ENV,
          });

        return;
      }

      case Object.values(ThreeDSecureVerificationFailureStatus).includes(
        threeDSecureVerificationStatus
      ): {
        const failureStatus = Object.values(
          ThreeDSecureVerificationFailureStatus
        ).find(status => status === threeDSecureVerificationStatus);

        dispatch(PaymentActions.setIsThreeDSecureProcessing(false));
        dispatch(
          PaymentActions.setError(
            'Unable to verify credit card, please try again. If the issue persists please try a different payment method.'
          )
        );

        await Backend.save3dsErrorLog({
          isUk,
          failureStatus,
          verificationResult: threeDSecureResult,
          cardinalSdkActionCode: undefined,
          errorMessage: '3DS verification failure.',
        });

        IS_STAGING_OR_PRODUCTION &&
          datadogLogs.logger.info('3DS Error', {
            isUk: isUk,
            paymentMethod: PaymentMethod.CREDIT_CARD,
            message: '3DS verification failure.',
            error: failureStatus,
            environment: process.env.REACT_APP_NODE_ENV,
          });
        return;
      }

      case threeDSecureResult?.details?.cardType === 'American Express' &&
        currency === 'GBP': {
        dispatch(PaymentActions.setIsThreeDSecureProcessing(false));
        dispatch(
          PaymentActions.setError(
            'American Express is not supported in your region, please try another payment method.'
          )
        );

        IS_STAGING_OR_PRODUCTION &&
          datadogLogs.logger.info('3DS Error', {
            isUk: isUk,
            paymentMethod: PaymentMethod.CREDIT_CARD,
            message: 'American Express not supported for UK transactions',
            error: undefined,
            environment: process.env.REACT_APP_NODE_ENV,
          });

        return;
      }

      case threeDSecureResult?.rawCardinalSDKVerificationData?.ActionCode ===
        'FAILURE': {
        dispatch(PaymentActions.setIsThreeDSecureProcessing(false));
        dispatch(
          PaymentActions.setError(
            'Card verification is required. Please try again.'
          )
        );

        await Backend.save3dsErrorLog({
          isUk,
          failureStatus: undefined,
          verificationResult: threeDSecureResult,
          cardinalSdkActionCode: 'FAILURE',
          errorMessage: 'Cardinal SDK failure action code.',
        });

        IS_STAGING_OR_PRODUCTION &&
          datadogLogs.logger.info('3DS Error', {
            isUk: isUk,
            paymentMethod: PaymentMethod.CREDIT_CARD,
            message: 'Cardinal SDK failure action code.',
            error: threeDSecureResult,
            environment: process.env.REACT_APP_NODE_ENV,
          });
        return;
      }

      default: {
        dispatch(PaymentActions.setIsThreeDSecureProcessing(false));
      }
    }
  }

  // get double the donation company name
  const ddCompanyNameHiddenInput: HTMLInputElement | null = document.querySelector(
    '[name=doublethedonation_company_name]'
  );
  // get double the donation company id
  const ddCompanyIdHiddenInput: HTMLInputElement | null = document.querySelector(
    '[name=doublethedonation_company_id]'
  );

  const employerNameDonationMatch = ddCompanyNameHiddenInput?.value ?? '';
  const employerIdDonationMatch = ddCompanyIdHiddenInput?.value ?? '';

  // get form values
  const formValues: GenericObject = {};
  formValues.cardholderName = cardholderName;
  formValues.doubleTheDonation = doubleTheDonation;
  formValues.giftAid = giftAid;
  formValues.employerNameDonationMatch = employerNameDonationMatch;
  formValues.employerIdDonationMatch = employerIdDonationMatch;

  // set form values for DonorReceipt page
  dispatch(
    PaymentActions.setPayment({
      isAch: options.isAch,
      isBraintree: options.isBraintree,
      processorPayload: options.isBraintree
        ? options.payload
        : options.metadata,
      ...formValues,
    })
  );

  // get donation post data
  const postData = getDonationPostData(
    options,
    formValues,
    getState,
    formikValues,
    braintreeDeviceData,
    threeDSecureResult,
    googleRecaptchaToken
  );

  if (window.grecaptcha && paymentType !== PaymentMethod.APPLE_PAY) {
    try {
      dispatch(PaymentActions.setIsPaymentProcessing(true));

      const response: AxiosResponse<DonateResponse> = await Axios.post(
        `${REACT_APP_API_BASE_URL}/donate`,
        postData,
        {
          auth: {
            username: REACT_APP_API_USERNAME as string,
            password: REACT_APP_API_PASSWORD as string,
          },
        }
      );

      dispatch(PaymentActions.setIsPaymentProcessing(false));

      if (response.data.result.paymentResponse.transactionDetail) {
        const {
          paymentResponse: {
            transactionId,
            transactionDetail: { paymentPlan, paymentMethod, mailCode },
          },
        } = response.data.result;
        const { donationAmount, otherAmount } = formikValues;

        let submittedMailCode: string;
        if (mailCode === '') {
          if (postData.form.isUk) {
            submittedMailCode = DefaultMailCodes.UkDefault;
          } else {
            submittedMailCode = DefaultMailCodes.UsDefault;
          }
        } else {
          submittedMailCode = mailCode;
        }

        let submittedDonationAmount: number;
        let isPreselectedAmount: boolean;
        if (donationAmount === 'OtherAmount') {
          submittedDonationAmount = Number(otherAmount);
          isPreselectedAmount = false;
        } else {
          submittedDonationAmount = Number(donationAmount);
          isPreselectedAmount = true;
        }

        gtmEventDonationCompleted(
          paymentPlan,
          donationAmount,
          otherAmount,
          paymentMethod,
          transactionId,
          donationName,
          submittedMailCode
        );

        gtmEventSetEeGeneralDonationComplete(
          transactionId,
          submittedDonationAmount,
          isPreselectedAmount,
          submittedMailCode,
          postData.form.domainUrl,
          postData.honoraryInfo.giftType,
          paymentPlan
        );
      }

      dispatch(handlePaymentSuccess(history, match, response));
      return;
    } catch (error) {
      dispatch(PaymentActions.setIsPaymentProcessing(false));
      dispatch(PaymentActions.setError(error.message));
      return;
    }
  } else if (paymentType === PaymentMethod.APPLE_PAY) {
    const response: AxiosResponse<DonateResponse> = await Axios.post(
      `${REACT_APP_API_BASE_URL}/donate`,
      postData,
      {
        auth: {
          username: REACT_APP_API_USERNAME as string,
          password: REACT_APP_API_PASSWORD as string,
        },
      }
    );

    if (response.data.result.paymentResponse.transactionDetail) {
      const {
        paymentResponse: {
          transactionId,
          transactionDetail: { paymentPlan, paymentMethod, mailCode },
        },
      } = response.data.result;
      const { donationAmount, otherAmount } = formikValues;

      let submittedMailCode: string;
      if (mailCode === '') {
        if (postData.form.isUk) {
          submittedMailCode = DefaultMailCodes.UkDefault;
        } else {
          submittedMailCode = DefaultMailCodes.UsDefault;
        }
      } else {
        submittedMailCode = mailCode;
      }

      let submittedDonationAmount: number;
      let isPreselectedAmount: boolean;
      if (donationAmount === 'OtherAmount') {
        submittedDonationAmount = Number(otherAmount);
        isPreselectedAmount = false;
      } else {
        submittedDonationAmount = Number(donationAmount);
        isPreselectedAmount = true;
      }

      gtmEventDonationCompleted(
        paymentPlan,
        donationAmount,
        otherAmount,
        paymentMethod,
        transactionId,
        donationName,
        submittedMailCode
      );

      gtmEventSetEeGeneralDonationComplete(
        transactionId,
        submittedDonationAmount,
        isPreselectedAmount,
        submittedMailCode,
        postData.form.domainUrl,
        postData.honoraryInfo.giftType,
        paymentPlan
      );
    }

    if (response.data.result.paymentResponse.success) {
      // handlePaymentSuccess called in ApplePay.tsx after Apple Pay is resovled.
      return {
        response,
        success: response.data.result.paymentResponse.success,
      };
    } else {
      return { response, success: false };
    }
  } else {
    dispatch(PaymentActions.setIsPaymentProcessing(false));
    dispatch(PaymentActions.setError('Google Recaptcha failure.'));
    return;
  }
};
