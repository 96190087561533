import { StoreState } from 'types/state';
import { FREQUENCY, forwardQueryStringParams } from 'constants/constants';
import { Options } from 'redux/thunks/submitPayment';
import getJsonFromUrlParams from 'helpers/getJsonFromUrlParams';
import { history } from 'redux/store';
import {
  CAProvincesList,
  countriesList,
  USStateList,
} from '@smile-train/common';
import { FormikValues } from 'formik';
import { clone } from 'lodash';

export const getDonationPostData = (
  options: Options,
  formValues,
  getState: () => StoreState,
  formikValues: any,
  braintreeDeviceData: string,
  threeDSecureResult?: braintree.ThreeDSecureVerifyPayload,
  googleRecaptchaToken?: string
) => {
  // Values from Redux store
  const {
    payment: { upsell },
    currentLanguage: { currentLocale },
    donationTemplateSchema: {
      donationTemplateSchema: {
        countries,
        donationTemplate: {
          domainUrl,
          honoreeInformation: {
            emailFields: { images: ecardImages },
            footerImage,
          },
          donationTemplateId,
          currency,
          isUk,
          hideExtraBillingFields,
          legacyDonationMailCode,
        },
      },
    },
  } = getState();

  // Values from Formik's context
  const {
    billingAddress1,
    billingAddress2,
    billingCity,
    billingCountry,
    billingPhone,
    billingPhoneType,
    billingState,
    billingZipCode,
    donationAmount,
    donationName,
    eCardEmail,
    eCardEmailBody,
    ecardImage,
    eCardSubjectLine,
    emailSubscribe,
    giftFrequency,
    honoraryAddress1,
    honoraryAddress2,
    honoraryCity,
    honoraryCountry,
    honoraryFirstName,
    honoraryLastName,
    honoraryState,
    honoraryTitle,
    honoraryType,
    honoraryZipCode,
    isHonoreeInfo,
    isLegacyDonation,
    legacyFirstName,
    legacyLastName,
    legacyTitle,
    legacyCountry,
    legacyAddress1,
    legacyAddress2,
    legacyCity,
    legacyPostalCode,
    legacyState,
    mailCodeName,
    mailedFromName,
    mailedToName,
    mailedOrEcard,
    mailSubscribe,
    otherAmount,
    personalEmail,
    personalFirstName,
    personalLastName,
    phoneSubscribe,
    sendCopyOfEmail,
    smsSubscribe,
  } = formikValues;

  const { cardholderName } = formValues;

  // construct honorary info
  const honoraryInfo: Record<string, any> = {};
  if (isHonoreeInfo) {
    honoraryInfo.isHonoraryGift = isHonoreeInfo;
    honoraryInfo.giftType = honoraryType;
    honoraryInfo.title = honoraryTitle;
    honoraryInfo.selectedCard = mailedOrEcard;
    honoraryInfo.firstName = honoraryFirstName;
    honoraryInfo.lastName = honoraryLastName;
    honoraryInfo.emailCard = {};
    honoraryInfo.mailCard = {};

    switch (mailedOrEcard) {
      case 'eCard':
        honoraryInfo.emailCard = {
          eImage: ecardImages[ecardImage],
          recipientEmail: eCardEmail,
          subject: eCardSubjectLine,
          emailBody: eCardEmailBody,
          sendCopy: sendCopyOfEmail,
          footerImageUrl: footerImage,
        };
        break;
      case 'mailed':
        honoraryInfo.mailCard = {
          country: {
            countryId: honoraryCountry,
            country: countries[Number(honoraryCountry) - 1].country,
          },
          yourName: mailedFromName,
          toName: mailedToName,
          firstName: honoraryFirstName,
          lastName: honoraryLastName,
          address1: honoraryAddress1,
          address2: honoraryAddress2,
          city: honoraryCity,
          state: honoraryState,
          zipCode: honoraryZipCode,
        };
        break;
      default:
      // do nothing
    }
  }

  const legacyInfo = {
    isLegacyDonation,
    legacyDonationMailCode,
    legacyFirstName,
    legacyLastName,
    legacyTitle,
    legacyCountry: isLegacyDonation
      ? countries[Number(legacyCountry) - 1].country
      : '',
    legacyAddress1,
    legacyAddress2,
    legacyCity,
    legacyPostalCode,
    legacyState,
  };

  /**
   * A null default value from the CMS is represented as an empty string. This
   * function converts that empty string to null before sending to the backend.
   * */
  const createSubscriptionValue = (subscriptionValue: true | false | '') => {
    if (subscriptionValue === '') {
      return null;
    }
    return subscriptionValue;
  };

  const {
    giftAid,
    employerNameDonationMatch,
    employerIdDonationMatch,
  } = formValues;
  const donationPostData: Record<string, any> = {
    form: {
      donationTemplateId,
      donationDomain: isUk ? 'UK' : 'US',
      donationName,
      isMonthly: giftFrequency === FREQUENCY.MONTHLY,
      isAnnual: giftFrequency === FREQUENCY.ANNUAL,
      donationAmount:
        donationAmount === 'OtherAmount' ? otherAmount : donationAmount,
      employerNameDonationMatch,
      employerIdDonationMatch,
      shouldSubscribeEmail: createSubscriptionValue(emailSubscribe),
      shouldSubscribeMail: createSubscriptionValue(mailSubscribe),
      shouldSubscribeMobile: createSubscriptionValue(smsSubscribe),
      shouldSubscribePhone: createSubscriptionValue(phoneSubscribe),
      currency,
      giftAid,
      mailCodeName,
      domainUrl,
      isUk,
      braintreeDeviceData,
      googleRecaptchaToken,
      upsell,
    },
    payment: {
      threeDSecureResult: threeDSecureResult,
    },
    billingInfo: {
      hideExtraBillingFields,
      billingCountry: {
        countryId: billingCountry,
        country: countries[Number(billingCountry) - 1]?.country,
      },
      cardholderName,
      billingPhoneType:
        !billingPhone && !billingPhoneType ? 'None' : billingPhoneType,
      billingPhoneNumber: billingPhone,
      billingAddressLine1: billingAddress1,
      billingAddressLine2: billingAddress2,
      billingCity: billingCity,
      billingState: isUk ? 'none' : billingState,
      billingPostalCode: billingZipCode,
    },
    personalInfo: {
      firstName: personalFirstName,
      lastName: personalLastName,
      email: personalEmail,
    },
    honoraryInfo: { ...honoraryInfo },
    legacyInfo: { ...legacyInfo },
    locale: currentLocale,
  };

  if (options.isBraintree) {
    // This ternary excludes Discover credit card transactions from 3DS
    // verification because it is an unsupported card type. If the
    // user pays with Discover, use the original nonce.

    let paymentMethod = options.payload?.type;
    if (paymentMethod === 'CreditCard') {
      paymentMethod = 'creditCard';
    } else if (paymentMethod === 'PayPalAccount') {
      paymentMethod = 'paypal';
      donationPostData.payment.paypalDetails = options.payload?.details;
    } else if (paymentMethod === 'AndroidPayCard') {
      donationPostData.payment.googlePayDetails = options.payload?.details;
    } else if (paymentMethod === 'ApplePayCard') {
      donationPostData.payment.applePayDetails = options.payload?.details;
    }

    donationPostData.payment.paymentMethod = paymentMethod;

    const nonce =
      paymentMethod === 'creditCard' &&
      threeDSecureResult?.details.cardType !== 'Discover' &&
      threeDSecureResult?.nonce
        ? threeDSecureResult.nonce
        : options.payload?.nonce;

    donationPostData.payment.nonce = nonce;
  }

  if (options.isAch) {
    donationPostData.payment.paymentMethod = 'Bank Account';
    donationPostData.payment.bankAccountType = 'Checking';
    donationPostData.payment.ach = options.metadata;
  }

  // Forward analytics query string params
  const query = history.location.search;
  if (query) {
    const params = getJsonFromUrlParams(query);
    forwardQueryStringParams.forEach(analyticsParam => {
      let qsKey, outKey;
      if (typeof analyticsParam === 'object') {
        qsKey = analyticsParam.qs;
        outKey = analyticsParam.output;
      } else if (typeof analyticsParam === 'string') {
        qsKey = analyticsParam;
        outKey = '_st_' + analyticsParam;
      }
      if (
        qsKey in params &&
        typeof params[qsKey] === 'string' &&
        !(outKey in donationPostData.form)
      ) {
        donationPostData.form[outKey] = params[qsKey];
      }
    });
  }

  return donationPostData;
};

export const getCountryId = (countryCode?: string) => {
  if (!countryCode) {
    return '';
  }
  return countriesList
    .filter(({ country_code }) => country_code === countryCode)[0]
    ?.country_id.toString();
};

export const getAdminAreaId = (
  countryCode?: string,
  adminAreaAbbr?: string
) => {
  // Gets the State or province object, including the id, which matches the returned value from Google
  // This is then used to set the billingState value in the form.
  if (!countryCode || !adminAreaAbbr) {
    return '';
  }
  if (countryCode === 'US') {
    return (
      USStateList.filter(
        ({ state }) => state.substr(0, 2) === adminAreaAbbr
      )[0].state_id.toString() || ''
    );
  } else if (countryCode === 'CA') {
    return (
      CAProvincesList.filter(
        ({ province }) => province.substr(0, 2) === adminAreaAbbr
      )[0].province_id.toString() || ''
    );
  } else {
    return '';
  }
};

interface UpdateOptions {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  googleData: google.payments.api.PaymentData;
  fields: string[];
}

export const updateFormValues = (updateOptions: UpdateOptions) => {
  const { setFieldValue, googleData, fields } = updateOptions;
  if (fields.includes('Phone_Number')) {
    setFieldValue(
      'billingPhone',
      googleData.paymentMethodData.info?.billingAddress?.phoneNumber
    );
    setFieldValue('billingPhoneType', 'Home');
  }

  setFieldValue(
    'billingAddress1',
    googleData.paymentMethodData.info?.billingAddress?.address1
  );
  setFieldValue(
    'billingAddress2',
    googleData.paymentMethodData.info?.billingAddress?.address2
  );
  setFieldValue(
    'billingCity',
    googleData.paymentMethodData.info?.billingAddress?.locality
  );
  setFieldValue(
    'billingZipCode',
    googleData.paymentMethodData.info?.billingAddress?.postalCode
  );
  setFieldValue(
    'billingState',
    getAdminAreaId(
      googleData.paymentMethodData.info?.billingAddress?.countryCode,
      googleData.paymentMethodData.info?.billingAddress?.administrativeArea
    )
  );
  setFieldValue(
    'billingCountry',
    getCountryId(googleData.paymentMethodData.info?.billingAddress?.countryCode)
  );
};

export const getSubmitValues = (values: FormikValues, appleData) => {
  const clonedValues = clone(values);
  clonedValues['billingAddress1'] = appleData.payment.billingContact
    ?.addressLines
    ? appleData.payment.billingContact?.addressLines?.[0]
    : '';
  clonedValues['billingAddress2'] = appleData.payment.billingContact
    ?.addressLines?.[1]
    ? appleData.payment.billingContact?.addressLines[1]
    : '';

  clonedValues['billingCity'] = appleData.payment.billingContact?.locality;
  clonedValues['billingZipCode'] = appleData.payment.billingContact?.postalCode;
  clonedValues['billingState'] = getAdminAreaId(
    appleData.payment.billingContact?.countryCode,
    appleData.payment.billingContact?.administrativeArea
  );
  clonedValues['billingCountry'] = getCountryId(
    appleData.payment.billingContact?.countryCode
  );

  return clonedValues;
};
