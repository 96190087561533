import React from 'react';

export default function LangIcon() {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#naqr189pha)">
        <path
          d="M10.134.084C4.67.084.239 4.616.239 10.191c0 5.575 4.44 10.098 9.895 10.098 5.454 0 9.895-4.532 9.895-10.098 0-5.566-4.433-10.107-9.895-10.107zm8.362 9.342h-2.611c-.107-2.767-.84-5.205-1.953-6.887a8.614 8.614 0 0 1 4.564 6.887zM9.384 1.757v7.669H5.883c.173-3.81 1.647-6.988 3.501-7.669zm0 9.2v7.668c-1.854-.69-3.328-3.868-3.501-7.669h3.501zm1.5 7.668v-7.669h3.501c-.173 3.81-1.648 6.98-3.501 7.669zm0-9.2V1.758c1.853.681 3.328 3.868 3.501 7.669h-3.501zM6.336 2.54C5.224 4.221 4.49 6.651 4.383 9.426h-2.62a8.604 8.604 0 0 1 4.573-6.887zm-4.573 8.417h2.62c.107 2.767.832 5.188 1.936 6.878a8.573 8.573 0 0 1-4.556-6.878zm12.177 6.878c1.104-1.681 1.83-4.111 1.936-6.878h2.612a8.595 8.595 0 0 1-4.548 6.878z"
          fill="#445D94"
        />
        <path
          d="M13.174 14.244h9.763v7.912h-3.37l-4.49 4.583v-4.507H13.24s.297-7.988-.074-7.988h.008z"
          fill="#fff"
        />
        <path
          d="M13.182 13.941h9.895c.371 0 .676.311.676.69v7.752c0 .379-.305.69-.676.69h-1.096a3.974 3.974 0 0 1-.79-1.295c-.009-.025-.025-.05-.042-.076h1.253V15.32H13.85v6.382h2.81l-.578 3.498 3.271-3.498h2.785a.843.843 0 0 0-.033.664c.09.253.206.488.33.715h-2.497l-4.35 4.65a.648.648 0 0 1-.783.134.686.686 0 0 1-.362-.723l.643-4.06H13.19a.686.686 0 0 1-.675-.69v-7.753c0-.378.305-.69.675-.69l-.008-.008z"
          fill="#445D94"
        />
      </g>
      <defs>
        <clipPath id="naqr189pha">
          <path fill="#fff" d="M0 0h24v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
