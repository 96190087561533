import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { donationTemplateSelector } from 'selectors/schema';
import ConfirmationPageContentFunc from 'content/ConfirmationPageContent';
import { SuccessfulPaymentResponse } from '@smile-train/common';
import { StaticContext } from 'react-router';

const mapStateToProps = state => {
  return {
    donationTemplate: donationTemplateSelector(state),
  };
};

const connector = connect(mapStateToProps);

// Infers types from connect() and history.push()--see handlePaymentSuccess
type Props = ConnectedProps<typeof connector> &
  RouteComponentProps<{}, StaticContext, SuccessfulPaymentResponse>;

const ConfirmationContainer: React.FC<Props> = props => {
  const { location, donationTemplate } = props;

  return (
    <ConfirmationPageContentFunc
      location={location}
      donationTemplate={donationTemplate}
    />
  );
};

export default withRouter(connect(mapStateToProps)(ConfirmationContainer));
