import React, { Fragment, RefObject, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useDispatch } from 'react-redux';

import Grid from 'components/Grid';
import GooglePayButton from '@google-pay/button-react';
import PlaidLink from './PlaidLink';
import { PaymentMethod } from 'constants/constants';
import { useTypedSelector, gtmEventSetPaymentMethod } from 'shared/utils';
import { PaymentActions } from 'redux/dux/payment';
import { handlePaymentChange } from 'redux/thunks/handlePaymentChange';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import BraintreeService from 'services/Braintree/Braintree.service';
import { FormikValues } from 'formik';
import { updateFormValues } from './utils';
import ApplePayButton from './ApplePay';
import { GenericObject } from 'types/utils';
import { EMAIL_REGEX } from '@smile-train/common';

interface Props {
  paypalButtonRef: RefObject<HTMLButtonElement>;
  isScriptLoaded: boolean;
  values: FormikValues;
  nameRef: React.RefObject<HTMLInputElement>;
  sectionHeader: React.CSSProperties;
  history: GenericObject;
  match: GenericObject;
  validateForm: () => Record<string, string>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const PaymentMethodButtons = ({
  paypalButtonRef,
  isScriptLoaded,
  values,
  nameRef,
  sectionHeader,
  setFieldValue,
  history,
  match,
  validateForm,
}: Props) => {
  const dispatch = useDispatch();
  const {
    paymentDetails: {
      isCreditCard,
      isBankAccount,
      isPaypal,
      isGooglePay,
      isApplePay,
    },
    currentPaymentMethod,
    failedPaypal,
    isUk,
    fields,
    donationPaymentFormContent,
  } = useTypedSelector(
    ({
      donationTemplateSchema: {
        donationTemplateSchema: {
          donationTemplate: {
            paymentDetails,
            isUk,
            personalInformation: { fields },
            donationFormMainContent: { donationPaymentFormContent },
          },
        },
      },
      payment: { currentPaymentMethod, failedPaypal },
    }) => ({
      isUk,
      paymentDetails,
      currentPaymentMethod,
      failedPaypal,
      fields,
      donationPaymentFormContent,
    })
  );

  const [allowedMethods, setAllowedMethods] = useState<
    google.payments.api.PaymentMethodSpecification[]
  >([]);
  const [showGooglePay, setShowGoogle] = useState<boolean>(false);
  const [showApplePay, setShowApplePay] = useState(false);

  const paymentRequestObject: google.payments.api.PaymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: allowedMethods,
    emailRequired: true,
    merchantInfo: {
      merchantId: 'BCR2DN4TXC3OLUDK',
      merchantName: 'Smile Train',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice:
        values.donationAmount === 'OtherAmount'
          ? values.otherAmount.toString()
          : values.donationAmount,
      currencyCode: isUk ? 'GBP' : 'USD',
    },
  };

  const handleGoogleClick = e => {
    if (
      !values.donationAmount ||
      (values.donationAmount === 'OtherAmount' && !values.otherAmount)
    ) {
      e.preventDefault();
      dispatch(
        PaymentActions.setError(
          'You must set an amount before paying with Google.'
        )
      );
    }
    if (!values.personalFirstName || !values.personalLastName) {
      e.preventDefault();
      dispatch(
        PaymentActions.setError(
          'Please fill out the Personal Information fields.'
        )
      );
      return;
    }
    const regex = new RegExp(EMAIL_REGEX);
    if (!regex.test(values.personalEmail)) {
      e.preventDefault();
      dispatch(PaymentActions.setError('Please enter a valied email.'));
      return;
    }
  };

  const handleGooglePaySubmit = async (
    paymentRequest: google.payments.api.PaymentData
  ) => {
    try {
      const braintree = await BraintreeService.init();
      const paymentData = await braintree.getGooglePaymentData(paymentRequest);

      updateFormValues({ setFieldValue, googleData: paymentRequest, fields });
      dispatch(PaymentActions.setPaymentMethod(PaymentMethod.GOOGLE_PAY));
      dispatch(PaymentActions.setGooglePaymentData(paymentData));
    } catch (error) {
      if (error.message === 'User closed the Payment Request UI.') {
        console.log(error);
      } else if (error.message) {
        dispatch(PaymentActions.setError(error.message));
      } else {
        dispatch(
          PaymentActions.setError(
            'An error with Google Pay has occurred. Please try another payment method or contact an administrator.'
          )
        );
        console.log(error);
      }
    }
  };

  useEffect(() => {
    gtmEventSetPaymentMethod(currentPaymentMethod);
  }, [currentPaymentMethod]);

  const creditCardIsActive = currentPaymentMethod === PaymentMethod.CREDIT_CARD;
  const bankAccountIsActive =
    currentPaymentMethod === PaymentMethod.BANK_ACCOUNT;
  const payPalIsActive = currentPaymentMethod === PaymentMethod.PAY_PAL;

  useEffect(() => {
    if (
      (window as any).ApplePaySession?.supportsVersion(3) &&
      (window as any).ApplePaySession.canMakePayments() &&
      isApplePay
    ) {
      setShowApplePay(true);
    }
  }, [isApplePay]);

  useEffect(() => {
    try {
      const getAllowedMethods = async () => {
        const braintree = await BraintreeService.init();
        const methods = await braintree.getGooglePayAllowedMethods();
        setAllowedMethods(methods);
        setShowGoogle(isGooglePay && methods.length > 0);
      };
      getAllowedMethods();
    } catch (error) {
      console.log(error);
    }
  }, [isGooglePay]);

  return (
    <Fragment>
      <SectionHeader
        name={donationPaymentFormContent.paymentMethodHeader}
        sectionHeaderStyle={sectionHeader}
      />
      {(showGooglePay || showApplePay) && (
        <div className={css(styles.GoogleAndAppleContainer)}>
          {showApplePay && (
            <ApplePayButton
              match={match}
              history={history}
              values={values}
              styleInfo={styles}
              showGooglePay={showGooglePay}
              validateForm={validateForm}
            />
          )}
          {showGooglePay && (
            <GooglePayButton
              buttonType="donate"
              environment={
                process.env
                  .REACT_APP_GOOGLE_PAY_ENV as google.payments.api.Environment
              }
              paymentRequest={paymentRequestObject}
              onLoadPaymentData={paymentRequest => {
                handleGooglePaySubmit(paymentRequest);
              }}
              onClick={e => handleGoogleClick(e)}
              buttonSizeMode="fill"
              className={css(
                showApplePay
                  ? styles.GoogleAndAppleButtons
                  : styles.GoogleOrAppleButton
              )}
            />
          )}
        </div>
      )}
      <Grid
        columns={failedPaypal || isUk || !isBankAccount ? '2' : '3'}
        styles={styles.container}
      >
        {isCreditCard && (
          <div>
            <button
              type="button"
              id={PaymentMethod.CREDIT_CARD}
              onClick={e => {
                dispatch(
                  PaymentActions.setPaymentMethod(PaymentMethod.CREDIT_CARD)
                );
                if (nameRef?.current) {
                  nameRef.current?.focus();
                }
              }}
              disabled={!isScriptLoaded}
              className={css(
                styles.otherPaymentButton,
                styles.firstButton,
                creditCardIsActive && styles.activeButton
              )}
            >
              {donationPaymentFormContent.creditCardPaymentMethod}
            </button>
          </div>
        )}
        {isBankAccount && !isUk && (
          <div>
            <PlaidLink
              onSuccess={(public_token, metadata) => {
                dispatch(
                  PaymentActions.setPlaidPaymentData({
                    isAch: true,
                    metadata,
                    public_token,
                  })
                );
              }}
              onExit={err => {
                // metadata
                // The user exited the Link flow.
                // TODO: why does this not use handlePaymentChange?
                dispatch(
                  PaymentActions.setPaymentMethod(PaymentMethod.CREDIT_CARD)
                );
                if (err != null) {
                  // The user encountered a Plaid API error prior to exiting.
                  dispatch(PaymentActions.setError(err.message));
                }
              }}
              render={({ open, ready }) => (
                <button
                  type="button"
                  id={PaymentMethod.BANK_ACCOUNT}
                  onClick={e => {
                    open();
                    dispatch(
                      handlePaymentChange(e, PaymentMethod.BANK_ACCOUNT)
                    );
                  }}
                  disabled={!ready}
                  className={css(
                    styles.otherPaymentButton,
                    failedPaypal ? styles.thirdButton : styles.secondButton,
                    bankAccountIsActive && styles.activeButton
                  )}
                >
                  {donationPaymentFormContent.bankAccountPaymentMethod}
                </button>
              )}
            />
          </div>
        )}
        {isPaypal && !failedPaypal && (
          <div>
            <button
              type="button"
              className={`paypal-button ${css(
                styles.otherPaymentButton,
                styles.thirdButton,
                payPalIsActive && styles.activeButton
              )}`}
              id={PaymentMethod.PAY_PAL}
              ref={paypalButtonRef}
              onClick={e =>
                dispatch(handlePaymentChange(e, PaymentMethod.PAY_PAL))
              }
              disabled={!isScriptLoaded}
            >
              {donationPaymentFormContent.payPalPaymentMethod}
            </button>
          </div>
        )}
      </Grid>
    </Fragment>
  );
};

export default PaymentMethodButtons;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 0,
    marginBottom: '21px',
    flex: '0 0 100%',
    '@media (max-width: 500px)': {
      flexWrap: 'nowrap',
    },
  },
  GoogleAndAppleContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 0,
    marginBottom: '21px',
    flex: '0 0 100%',
    '@media (max-width: 500px)': {
      height: '90px',
      width: '100%',
      marginBottom: '7px',
    },
  },
  GoogleAndAppleButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '48.3%',
    minWidth: '200px',
    '@media (max-width: 500px)': {
      height: '40px',
      width: '100%',
    },
  },
  GoogleOrAppleButton: {
    display: 'flex',
    justifyContent: 'center',
    height: '40px',
    width: '100%',
    minWidth: '200px',
  },
  tooltip: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    height: '30px',
    width: '175px',
    borderRadius: '15px',
    background: 'rgba(0,0,0,.8)',
    padding: '0 10px',
    animationName: {
      '0%': {
        opacity: '0',
        top: -20,
      },
      '20%': {
        opacity: '1',
      },
      '80%': {
        opacity: '1',
      },
      '100%': {
        opacity: '0',
        top: -35,
      },
    },
    animationDuration: '3s',
  },

  otherPaymentButton: {
    height: 50,
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    lineHeight: 1.21,
    textAlign: 'center',
    color: '#777',
    backgroundColor: '#fff',
    cursor: 'pointer',
    border: 'solid 1px #ddd',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  firstButton: {
    borderRadius: '8px 0 0 8px',
  },
  secondButton: {
    borderRightStyle: 'solid',
    borderRightColor: '#ddd',
    borderRightWidth: '1px',
    borderLeftStyle: 'none',
  },
  thirdButton: {
    borderRadius: '0 8px 8px 0',
    borderLeftStyle: 'none',
  },
  activeButton: {
    color: '#fff',
    backgroundColor: '#4B79BC',
  },
});
