import Http from 'services/Http/Http';

class Backend extends Http {
  public getDonationTemplateSchema({
    topDomain,
    templateSlug,
    giftArrayParam,
    locale,
  }: {
    topDomain: string;
    templateSlug: string;
    giftArrayParam: any;
    locale?: string;
  }) {
    return this._get({
      endpoint: `/donation-template`,
      params: { giftArrayParam, topDomain, templateSlug, locale },
    });
  }

  public saveDonorMerchantAccount({
    firstName,
    lastName,
    currency,
    donationTemplateId,
    domainUrl,
    merchantAccountId,
  }: {
    firstName: string;
    lastName: string;
    currency: string;
    donationTemplateId: string;
    domainUrl: string;
    merchantAccountId: string;
  }) {
    return this._post({
      endpoint: 'braintree/merchant-account',
      body: {
        firstName,
        lastName,
        currency,
        donationTemplateId,
        domainUrl,
        merchantAccountId,
      },
    });
  }

  public save3dsErrorLog({
    isUk,
    failureStatus,
    verificationResult,
    cardinalSdkActionCode,
    errorMessage,
  }: {
    isUk: boolean;
    failureStatus: string | undefined;
    verificationResult: Record<string, any> | undefined;
    cardinalSdkActionCode: string | undefined;
    errorMessage: string | undefined;
  }) {
    return this._post({
      endpoint: 'braintree/3ds-error',
      body: {
        isUk,
        failureStatus,
        verificationResult,
        cardinalSdkActionCode,
        errorMessage,
      },
    });
  }
}

export default new Backend(process.env.REACT_APP_API_BASE_URL);
