import React from 'react';
import PropTypes from 'prop-types';

const Spinner = props => {
  const {
    containerHeight,
    backgroundColor,
    size,
    color,
    strokeWidth,
    duration,
    repeatCount,
    title,
  } = props;

  return (
    <div
      style={{
        height: containerHeight,
        backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      {title && <h1>{title}</h1>}
      <svg
        className="Spinner"
        width={size}
        height={size}
        viewBox="0 0 38 38"
        stroke={color}
        style={{ padding: '1px' }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth={strokeWidth}>
            <circle strokeOpacity=".25" cx="18" cy="18" r="18" />
            <path
              d="M36 18c0-9.94-8.06-18-18-18"
              transform="rotate(211.717 18 18)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur={`${duration}s`}
                repeatCount={repeatCount}
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  );
};

Spinner.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  strokeWidth: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  duration: PropTypes.string,
  repeatCount: PropTypes.string,
  containerHeight: PropTypes.string,
};

Spinner.defaultProps = {
  size: '80',
  strokeWidth: '1',
  color: '#4c79bc',
  backgroundColor: '#fff',
  duration: '.4',
  repeatCount: 'indefinite',
  containerHeight: '100vh',
};

export default Spinner;
