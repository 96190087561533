import React from 'react';
import logo from 'images/logo.svg';
import { StyleSheet, css } from 'aphrodite';

import { APP_THEME } from 'constants/constants';
import { DonationTemplateSchema } from 'types/shared';
import { useTypedSelector, interpretNewlineCharacters } from 'shared/utils';
import './HomePageContent.css';

interface Props {
  logoHref: string;
  text: string;
}

const BannerAndTopText = ({ logoHref, text }: Props) => {
  const donationTemplateSchema: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  const {
    donationTemplate: {
      donationTemplateImage,
      donationTemplateImageAltText,
      templateTheme,
    },
  } = donationTemplateSchema;
  const interpretedText = interpretNewlineCharacters(text);

  const isFiftyFifty = templateTheme === APP_THEME.FIFTYFIFTY;
  const isCentered = templateTheme === APP_THEME.CENTERED;
  return (
    // Offset image is set in App.tsx in the backgroundImage component
    <div
      className={css(
        isFiftyFifty
          ? styles.bannerContainerFiftyFifty
          : styles.bannerContainerCentered
      )}
    >
      <div
        className={css(
          isFiftyFifty
            ? styles.contentContainerFiftyFifty
            : styles.contentContainerCentered
        )}
      >
        {isFiftyFifty && (
          <div className={css(styles.logoContainer)}>
            <a href={logoHref}>
              <img
                className={css(styles.logoImage)}
                src={logo}
                alt={'Smile Train logo'}
              />
            </a>
          </div>
        )}

        <div
          className={css(
            isFiftyFifty
              ? styles.imgTextContainerFiftyFifty
              : styles.imgTextContainerCentered
          )}
        >
          <div
            className={css(
              isFiftyFifty
                ? styles.headlineContainerFiftyFifty
                : isCentered
                ? styles.headlineContainerCentered
                : styles.headlineContainerOffset
            )}
          >
            {text && (
              <h1
                id="home-page-title"
                className={css(
                  isFiftyFifty
                    ? styles.headlineTextFiftyFifty
                    : isCentered
                    ? styles.headlineTextCentered
                    : styles.headlineTextOffset
                )}
              >
                {interpretedText}
              </h1>
            )}
          </div>
          {(isCentered || isFiftyFifty) && donationTemplateImage && (
            <img
              id="banner-image"
              className={css(
                isFiftyFifty
                  ? styles.bannerImageFiftyFifty
                  : styles.bannerImageCentered
              )}
              src={donationTemplateImage}
              alt={donationTemplateImageAltText}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerAndTopText;

const styles = StyleSheet.create({
  bannerContainerFiftyFifty: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    background: '#EFF5F9',
  },
  bannerContainerCentered: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainerFiftyFifty: {
    position: 'relative',
    width: '100%',
    maxWidth: '1440px',
  },
  contentContainerCentered: {
    width: '100%',
  },
  logoContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 20,
    left: 20,
    '@media (max-width: 550px)': {
      position: 'relative',
      height: '60px',
      top: 0,
      left: 0,
    },
  },
  logoImage: {
    width: 122,
    height: 53,
    '@media (max-width: 1200px)': {
      width: 87,
      height: 38,
    },
    '@media (max-width: 950px)': {
      width: 70,
      height: 30,
    },
    '@media (max-width: 550px)': {
      width: 87,
      height: 38,
    },
  },
  imgTextContainerFiftyFifty: {
    display: 'flex',
    width: '100%',
    '@media (max-width: 550px)': {
      flexDirection: 'column-reverse',
    },
  },
  imgTextContainerCentered: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column-reverse',
  },
  headlineContainerFiftyFifty: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    padding: '75px 20px 30px 40px',

    '@media (max-width: 950px)': {
      padding: '60px 20px 30px',
    },
    '@media (max-width: 550px)': {
      padding: '20px',
      width: '100%',
    },
  },
  headlineContainerCentered: {
    display: 'flex',
    justifyContent: 'center',
  },
  headlineContainerOffset: {
    display: 'flex',
    alignItems: 'center',
  },
  headlineTextFiftyFifty: {
    textAlign: 'start',
    fontSize: '50px',
    lineHeight: '58px',
    color: '#2F2F2F',
    fontWeight: 900,
    '@media (max-width: 1200px)': {
      lineHeight: '53px',
      fontSize: '46px',
    },
    '@media (max-width: 1100px)': {
      lineHeight: '46px',
      fontSize: '40px',
    },
    '@media (max-width: 975px)': {
      lineHeight: '38px',
      fontSize: '33px',
    },
    '@media (max-width: 850px)': {
      lineHeight: '34px',
      fontSize: '28px',
    },
    '@media (max-width: 760px)': {
      lineHeight: '28px',
      fontSize: '24px',
    },
    '@media (max-width: 660px)': {
      lineHeight: '25px',
      fontSize: '21px',
    },
    '@media (max-width: 550px)': {
      textAlign: 'center',
    },
  },
  headlineTextCentered: {
    maxWidth: '660px',
    textAlign: 'center',
    padding: '36px 30px 12px',
  },

  headlineTextOffset: {
    textAlign: 'center',
    padding: '10px 30px',
  },
  bannerImageFiftyFifty: {
    width: '50%',
    height: 'auto',
    '@media (max-width: 550px)': {
      width: 'auto',
    },
    objectFit: 'cover',
  },
  bannerImageCentered: {
    objectFit: 'cover',
    width: '100%',
    height: '293px',
    '@media (max-width: 1100px)': {
      height: 'auto',
    },
  },
});
