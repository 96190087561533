import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import scriptLoader from 'react-async-script-loader';
import PaymentForm from 'forms/PaymentForm/PaymentForm';

const PaymentFormContainer = compose(
  scriptLoader([
    // 360match
    'https://doublethedonation.com/api/js/ddplugin.js',
  ]),
  withRouter,
  connect()
)(PaymentForm);

export default PaymentFormContainer;
