import { logAxiosEvent } from './utils';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

const { REACT_APP_API_USERNAME, REACT_APP_API_PASSWORD } = process.env;

export default class Http {
  private axios: AxiosInstance;

  public constructor(baseURL: string | undefined) {
    const authString = btoa(
      `${REACT_APP_API_USERNAME}:${REACT_APP_API_PASSWORD}`
    );

    this.axios = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${authString}`,
      },
    });

    this.axios.interceptors.response.use(
      response => {
        this.logEvent({ response });
        return response.data;
      },
      error => {
        this.logEvent({ error });
        return error;
      }
    );
  }

  protected _get({
    endpoint,
    params,
  }: {
    endpoint: string;
    params?: object | URLSearchParams;
  }): Promise<any> {
    return this.axios({
      method: 'get',
      url: endpoint,
      params,
    });
  }

  protected _post({
    endpoint,
    body,
  }: {
    endpoint: string;
    body: Record<string, any>;
  }) {
    return this.axios.post(endpoint, body);
  }

  private logEvent({
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError;
  }) {
    if (process.env.NODE_ENV !== 'production') {
      logAxiosEvent({ error, response });
    }
  }
}
