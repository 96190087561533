import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Field } from 'formik';

import {
  useTypedSelector,
  gtmEventSetUserEnteredGiftAmount,
} from 'shared/utils';
import { DonationTemplate, replaceTemplateStrings } from '@smile-train/common';
import './OtherAmountBox.css';
import { Label } from 'components/FormControls';

interface Props {
  currencyIcon: string;
  errors: any;
  donationAmount: string;
  otherAmount: string | number;
}

const OtherAmountBox = ({
  currencyIcon,
  errors,
  donationAmount,
  otherAmount,
}: Props) => {
  const {
    giftArray: {
      customDonationIncrementAmount,
      customDonationMinimumAmount,
      customDonationMaximumAmount,
    },
    donationFormValidationContent: { otherAmountDonationInputValidation },
    donationFormMainContent: { otherAmountDonationBoxName },
  }: DonationTemplate = useTypedSelector(
    state =>
      state.donationTemplateSchema.donationTemplateSchema.donationTemplate
  );

  // Object used to replace the template values from CMS content
  const cmsTemplateValues = {
    donationAmount: `${currencyIcon}${
      customDonationMinimumAmount ? customDonationIncrementAmount : 5
    }`,
  };

  const handleBlur = () => {
    gtmEventSetUserEnteredGiftAmount(donationAmount, otherAmount);
  };

  return (
    <>
      <div className={css(styles.container)}>
        <Label className={css(styles.label)} id="other-amount-label">
          {otherAmountDonationBoxName}
        </Label>
        <Field
          type="number"
          className={css(styles.input)}
          id="other-amount-input"
          min={customDonationMinimumAmount || 5}
          max={customDonationMaximumAmount || 100000}
          step={customDonationIncrementAmount || 0.01}
          name="otherAmount"
          label={replaceTemplateStrings(
            otherAmountDonationInputValidation,
            cmsTemplateValues
          )}
          placeholder={`${currencyIcon} 0.00`}
          autoFocus
          onBlur={handleBlur}
        />
      </div>
      {errors['otherAmount'] && donationAmount === 'OtherAmount' && (
        <div className={css(styles.error)}>{errors['otherAmount']}</div>
      )}
    </>
  );
};

export default OtherAmountBox;

const styles = StyleSheet.create({
  container: {
    '@media (max-width: 500px)': {
      width: 250,
    },
    display: 'flex',
    margin: '0 auto',
    height: 50,
    width: 354,
    borderRadius: 8,
    backgroundColor: '#efefef',
    alignItems: 'center',
    marginTop: -16,
  },
  input: {
    '@media (max-width: 500px)': {
      marginRight: 14,
    },
    width: 145,
    height: 31,
    marginLeft: 'auto',
    marginRight: 28,
    padding: '10px 1px 8px 10px',
    border: 'solid 1px #ddd',
    borderRadius: 8,
  },
  label: {
    '@media (max-width: 500px)': {
      fontSize: 14,
      marginLeft: 0,
      textAlign: 'center',
    },
    fontSize: 18,
    fontWeight: 500,
    color: '#bfbcba',
    margin: '0 0 0 24px',
  },
  error: {
    '@media (max-width: 500px)': {
      width: 250,
    },
    width: 354,
    textAlign: 'center',
    margin: '5px auto 0',
    fontSize: 14,
    color: '#ee3124',
  },
});
