import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import { StyleSheet, css } from 'aphrodite';

import {
  FormSection,
  ToggleInputFormik,
} from 'components/FormControls/FormControls';
import { useTypedSelector } from 'shared/utils';
import { DonationTemplateSchema } from 'types/shared';
import { DonationTheme } from 'themes';

interface Props {
  theme: DonationTheme;
  values: any;
  setFieldValue: (
    field: string,
    newValue: any,
    shouldValidate?: boolean
  ) => void;
}

const CommunicationPreferencesStatements = ({
  theme,
  values,
  setFieldValue,
}: Props) => {
  const topTextRef = useRef<HTMLParagraphElement>(null);
  const bottomTextRef = useRef<HTMLParagraphElement>(null);
  const donationTemplateSchema: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  const {
    donationTemplate: {
      subscriptionHeader,
      topTextAboveDonationButton,
      bottomTextAboveDonationButton,
      isUk,
      personalInformation: {
        emailSubscribe,
        emailSubscribeDefault,
        smsSubscribe,
        smsSubscribeDefault,
        phoneSubscribe,
        phoneSubscribeDefault,
        mailSubscribe,
        mailSubscribeDefault,
      },
    },
  } = donationTemplateSchema;

  useEffect(() => {
    if (emailSubscribeDefault === 'yes') {
      setFieldValue('emailSubscribe', true);
    } else if (emailSubscribeDefault === 'no') {
      setFieldValue('emailSubscribe', false);
    } else {
      setFieldValue('emailSubscribe', '');
    }

    if (smsSubscribeDefault === 'yes') {
      setFieldValue('smsSubscribe', true);
    } else if (smsSubscribeDefault === 'no') {
      setFieldValue('smsSubscribe', false);
    } else {
      setFieldValue('smsSubscribe', '');
    }

    if (phoneSubscribeDefault === 'yes') {
      setFieldValue('phoneSubscribe', true);
    } else if (phoneSubscribeDefault === 'no') {
      setFieldValue('phoneSubscribe', false);
    } else {
      setFieldValue('phoneSubscribe', '');
    }

    if (mailSubscribeDefault === 'yes') {
      setFieldValue('mailSubscribe', true);
    } else if (mailSubscribeDefault === 'no') {
      setFieldValue('mailSubscribe', false);
    } else {
      setFieldValue('mailSubscribe', '');
    }
  }, [
    emailSubscribeDefault,
    smsSubscribeDefault,
    phoneSubscribeDefault,
    mailSubscribeDefault,
    setFieldValue,
  ]);

  // Set an id on the text above and below the subscription options. Because
  // we're using the GraphCMS rich text fields for this, we receive html
  // elements from our query. We need to add ids so that VWO can translate this
  // text for Spanish users.
  useEffect(() => {
    const topTextChildren = topTextRef.current?.children;
    const bottomTextChildren = bottomTextRef.current?.children;

    if (topTextChildren) {
      Array.from(topTextChildren).forEach((child, index) => {
        child.setAttribute('id', `top-text-${index}`);
      });
    }

    if (bottomTextChildren) {
      Array.from(bottomTextChildren).forEach((child, index) => {
        child.setAttribute('id', `bottom-text-${index}`);
      });
    }
  }, []);

  return (
    <>
      <FormSection>
        <h3 style={theme.sectionHeader}>{subscriptionHeader}</h3>
        {topTextAboveDonationButton && (
          <p
            dangerouslySetInnerHTML={{ __html: topTextAboveDonationButton }}
            className={css(styles.text)}
            ref={topTextRef}
          />
        )}
      </FormSection>
      {(emailSubscribe || smsSubscribe || phoneSubscribe || mailSubscribe) && (
        <FormSection>
          {emailSubscribe && (
            <Field
              name="emailSubscribe"
              label="Yes please, I'd like to hear from you by email"
              checkboxStyle={theme.checkBox}
              component={ToggleInputFormik}
            />
          )}
          {smsSubscribe && (
            <Field
              name="smsSubscribe"
              label="Yes please, I'd like to hear from you by SMS"
              checkboxStyle={theme.checkBox}
              component={ToggleInputFormik}
            />
          )}
          {phoneSubscribe && (
            <Field
              name="phoneSubscribe"
              label="Yes please, I'd like to hear from you by phone"
              checkboxStyle={theme.checkBox}
              component={ToggleInputFormik}
            />
          )}
          {!isUk && mailSubscribe && (
            <Field
              name="mailSubscribe"
              label="Yes please, I'd like to hear from you by mail"
              checkboxStyle={theme.checkBox}
              component={ToggleInputFormik}
            />
          )}
        </FormSection>
      )}
      {bottomTextAboveDonationButton && (
        <p
          dangerouslySetInnerHTML={{ __html: bottomTextAboveDonationButton }}
          className={css(styles.text, styles.bottomText)}
          ref={bottomTextRef}
        />
      )}
    </>
  );
};

export default CommunicationPreferencesStatements;

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    color: '#777',
  },
  bottomText: {
    marginBottom: 52,
  },
});
