import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ActionButton.css';

const Title = props => {
  return (
    <h3 id={props.id} className="ActionButton__title">
      {props.children}
    </h3>
  );
};

const Icon = props => {
  return <div className="ActionButton__icon">{props.children}</div>;
};

const Text = props => {
  return <p className="ActionButton__text">{props.children}</p>;
};

class ActionButton extends Component {
  static propTypes = {
    alt: PropTypes.bool,
    isActive: PropTypes.bool,
    children: PropTypes.node,
  };

  static Title = Title;
  static Text = Text;
  static Icon = Icon;

  render() {
    const { alt, children, isActive } = this.props;

    return (
      <Fragment>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            className: classnames(
              'ActionButton module',
              isActive && 'ActionButton--active',
              alt && 'ActionButton--alt',
              child.props.className
            ),
          });
        })}
      </Fragment>
    );
  }
}

export default ActionButton;
