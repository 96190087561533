import React, { useEffect } from 'react';
import { useTypedSelector } from 'shared/utils';
import { Helmet } from 'react-helmet';

import { APP_NAME } from 'constants/constants';
import { DonationTemplateSchema } from 'types/shared';

const Meta = ({ title = undefined }: { title?: string }) => {
  const isUkUrl = window.location.href.includes('.uk');

  useEffect(() => {
    let noScript: HTMLElement | undefined;
    if (!isUkUrl) {
      noScript = document.createElement('noscript');

      const noScriptIframe = document.createElement('iframe');
      noScriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_CONTAINER_US}`;
      noScript.style.height = '0';
      noScript.style.width = '0';
      noScript.style.display = 'none';
      noScript.style.visibility = 'hidden';

      noScript.append(noScriptIframe);

      document.body.insertBefore(noScript, document.body.firstChild);
    }
    return () => {
      if (noScript) {
        document.body.removeChild(noScript);
      }
    };
  }, [isUkUrl]);

  const donationTemplateSchema: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  const {
    donationTemplate: {
      followNofollow,
      DTdescription,
      metaNoIndex,
      metaImageAltContent,
      metaImage,
      isUk,
    },
  } = donationTemplateSchema;

  const metaTitle = title ? `${title} | ${APP_NAME}` : APP_NAME;

  //@ts-ignore
  window.prerenderReady = true;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <link rel="canonical" href={window.location.href} />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cloud.typography.com/7668472/6586832/css/fonts.css"
      />
      <meta
        name="robots"
        content={`${metaNoIndex ? 'noindex' : 'index'}, ${
          followNofollow === 'No' ? 'nofollow' : 'follow'
        }`}
      />
      <meta name="prerender-status-code" content="200" />
      <meta name="description" content={DTdescription} />
      <meta property="og:site_name" content="Smile Train Donation Form" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:locale" content={isUk ? 'en_GB' : 'en_US'} />
      <meta property="og:description" content={DTdescription} />
      <meta property="og:image" content={metaImage?.url} />
      <meta property="og:image:secure_url" content={metaImage?.url} />
      <meta property="og:image:alt" content={metaImageAltContent} />
      <meta name="twitter:site" content="@smiletrain" />
      <meta name="twitter:creator" content="@smiletrain" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={DTdescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={metaImage?.url} />
      <meta name="twitter:image:alt" content={metaImageAltContent} />
      {/* Scripts for UK URL start */}
      {isUkUrl && (
        // Google Tag Manager
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_CONTAINER_UK}`}
        ></script>
      )}
      {isUkUrl && (
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GTM_CONTAINER_UK}');
            `}
        </script>
      )}
      {isUkUrl && (
        <script>
          {`
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                  'gtm.start': new Date().getTime(),
                  event: 'gtm.js',
                });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
              })(
                window,
                document,
                'script',
                'dataLayer',
                '${process.env.REACT_APP_GTM_CONTAINER_UK}'
              );
          `}
        </script>
      )}
      {/* Scripts for UK URL end */}
      {/* Scripts for non-UK URL start */}
      {!isUkUrl && (
        // Google Tag Manager
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_CONTAINER_US}`}
        ></script>
      )}
      {!isUkUrl && (
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GTM_CONTAINER_US}');
            `}
        </script>
      )}
      {!isUkUrl && (
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_CONTAINER_US}');
          `}
        </script>
        // End Google Tag Manager
      )}

      {!isUkUrl && (
        // Active Campaign
        <script type="text/javascript">
          {`
          (function(e, t, o, n, p, r, i) {
            e.visitorGlobalObjectAlias = n;
            e[e.visitorGlobalObjectAlias] =
              e[e.visitorGlobalObjectAlias] ||
              function() {
                (e[e.visitorGlobalObjectAlias].q =
                  e[e.visitorGlobalObjectAlias].q || []).push(arguments);
              };
            e[e.visitorGlobalObjectAlias].l = new Date().getTime();
            r = t.createElement('script');
            r.src = o;
            r.async = true;
            i = t.getElementsByTagName('script')[0];
            i.parentNode.insertBefore(r, i);
          })(
            window,
            document,
            'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js',
            'vgo'
          );

          vgo('setAccount', '` +
            process.env.REACT_APP_ACT_ID +
            `');
          vgo('setTrackByDefault', true);

          vgo('process');        
        `}
        </script>
      )}
      {!isUkUrl && (
        <script
          data-siteid="k5BZXFpK"
          src="https://api.cartstack.com/js/customer-tracking/www.smiletrain.org_3e8729fb0010b7fe864acaafa112ae27.js"
          type="text/javascript"
        ></script>
      )}
      {/* Scripts for non-UK URL end */}
    </Helmet>
  );
};

export default Meta;
