import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentMethod } from 'constants/constants';
import { FormSection, ToggleInputFormik } from 'components/FormControls';
import CreditCard from 'components/CreditCard';
import BraintreeFields from './BraintreeFields';
import PaymentMethodButtons from './PaymentMethodButtons';
import { initDoubleTheDonation } from 'shared/utils';
import { PaymentActions } from 'redux/dux/payment';
import { useTypedSelector } from 'shared/utils';
import PaymentError from './PaymentError';
import { DonationTheme } from 'themes';
import DoubleTheDonation from './DoubleTheDonation';
import { DonationTemplateSchema } from 'types/shared';
import { Field, FormikValues } from 'formik';
import { css, StyleSheet } from 'aphrodite';

interface Props {
  isScriptLoaded: boolean;
  isScriptLoadSucceed: boolean;
  history: object;
  match: object;
  theme: DonationTheme;
  values: FormikValues;
  validateForm: () => Record<string, string>;
  initializeHostedBraintreeFields: () => Promise<void>;
  handlePaypalButtonClick: () => Promise<void>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const PaymentForm = ({
  isScriptLoaded,
  isScriptLoadSucceed,
  history,
  match,
  theme,
  values,
  validateForm,
  initializeHostedBraintreeFields,
  handlePaypalButtonClick,
  setFieldValue,
}: Props) => {
  const dispatch = useDispatch();
  const paypalButtonRef = useRef<HTMLButtonElement>(null);
  const giftAidRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  const {
    currentPaymentMethod,
    isUk,
    giftAid,
    plaidPaymentData,
    paypalPaymentData,
  } = useTypedSelector(
    ({
      payment: {
        currentPaymentMethod,
        giftAid,
        plaidPaymentData,
        paypalPaymentData,
        cardholderName,
      },
      donationTemplateSchema: {
        donationTemplateSchema: {
          donationTemplate: { isUk },
        },
      },
    }) => ({
      currentPaymentMethod,
      isUk,
      giftAid,
      plaidPaymentData,
      paypalPaymentData,
      cardholderName,
    })
  );

  const {
    donationTemplate: {
      giftArray: { showGiftAid },
      hideMatchingGift,
      donationFormMainContent: { donationPaymentFormContent },
    },
  }: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  // Set an id on the label for gift aid so that VWO can properly translate the
  // text for spanish speaking visitors to UK forms.
  useEffect(() => {
    const parent = giftAidRef.current?.parentElement;

    if (parent) {
      parent.setAttribute('id', 'giftAid-label');
    }
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      if (isScriptLoadSucceed) {
        initDoubleTheDonation();
      } else {
        dispatch(
          PaymentActions.setError('Something went wrong while loading scripts')
        );
      }
    }
  }, [isScriptLoaded, isScriptLoadSucceed, dispatch]);

  useEffect(() => {
    if (
      isScriptLoadSucceed &&
      currentPaymentMethod === PaymentMethod.CREDIT_CARD
    ) {
      initializeHostedBraintreeFields();
    }
    // The rule wants us to include initializeHostedBraintreeFields, but that causes a
    // HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME error at runtime. Ignoring the eslint rule for now.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaymentMethod, isScriptLoadSucceed]);

  useEffect(() => {
    if (paypalButtonRef?.current) {
      paypalButtonRef.current.addEventListener(
        'click',
        handlePaypalButtonClick
      );
    }

    return () => {
      paypalButtonRef?.current?.removeEventListener(
        'click',
        handlePaypalButtonClick
      );
    };
  }, [paypalButtonRef, handlePaypalButtonClick]);

  return (
    <Fragment>
      <div className="container module">
        {!isUk && !hideMatchingGift && (
          <DoubleTheDonation
            checkboxStyle={theme.checkBox}
            label={donationPaymentFormContent.donationMatchCheckbox}
          />
        )}
        <PaymentMethodButtons
          paypalButtonRef={paypalButtonRef}
          isScriptLoaded={isScriptLoaded}
          nameRef={nameRef}
          sectionHeader={theme.sectionHeader}
          values={values}
          setFieldValue={setFieldValue}
          history={history}
          match={match}
          validateForm={validateForm}
        />
        {currentPaymentMethod === PaymentMethod.CREDIT_CARD && (
          <BraintreeFields nameRef={nameRef} theme={theme} />
        )}
        <div>
          {Object.keys(paypalPaymentData).length > 0 &&
            currentPaymentMethod === PaymentMethod.PAY_PAL && (
              <FormSection>
                <CreditCard
                  paymentMethod="Paypal"
                  paypalEmail={paypalPaymentData.payload.details.email}
                />
              </FormSection>
            )}
          {Object.keys(plaidPaymentData).length > 0 &&
            currentPaymentMethod === PaymentMethod.BANK_ACCOUNT && (
              <FormSection>
                <CreditCard
                  paymentMethod="Plaid"
                  institutionName={plaidPaymentData.metadata.institution.name}
                  accountType={plaidPaymentData.metadata.account.subtype}
                  last4={plaidPaymentData.metadata.account.mask}
                />
              </FormSection>
            )}
          {isUk && showGiftAid && !values.isLegacyDonation && (
            <FormSection>
              <p className={css(styles.text)}>
                {donationPaymentFormContent.giftAidDescription}
              </p>
              <p className={css(styles.text)}>
                {donationPaymentFormContent.giftAidFaqDescription}{' '}
                <a
                  href="https://www.smiletrain.org.uk/gift-aid"
                  target="_blank"
                >
                  {donationPaymentFormContent.giftAidFaqUrl}
                </a>
              </p>
              <Field
                id="giftAid"
                name="giftAid"
                checked={giftAid}
                inputRef={giftAidRef}
                onChange={() => dispatch(PaymentActions.setGiftAid(!giftAid))}
                label={donationPaymentFormContent.giftAidCheckboxLabel}
                checkboxStyle={theme.checkBox}
                component={ToggleInputFormik}
              />
            </FormSection>
          )}
        </div>
      </div>
      <PaymentError match={match} history={history} />
    </Fragment>
  );
};

export default PaymentForm;

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    color: '#777',
  },
  bottomText: {
    marginBottom: 52,
  },
});
