import React from 'react';
import { css } from 'aphrodite';

import classnames from 'classnames';
import './Grid.css';

interface Props {
  columns: string;
  children?: any;
  styles?: any;
}

const Grid = ({ columns, children, styles }: Props) => {
  return (
    <div className={`Grid Grid--col-${columns} ${css(styles)}`}>
      {React.Children.map(children, child => {
        if (!child) return;
        return (
          <div className="Grid__item-wrap">
            {React.cloneElement(child, {
              className: classnames('Grid__item', child.props.className),
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
