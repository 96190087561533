import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'shared/utils';
import App from '../../App';
import getJsonFromUrlParams from 'helpers/getJsonFromUrlParams';
import getDonationTemplate from 'redux/thunks/getDonationTemplate';
import setMailcodeCookie from 'helpers/setMailcodeCookie';
import { getTopLevelDomain } from 'helpers/utils';
import { DEFAULT_TEMPLATE } from '@smile-train/common';

interface Props {
  match: {
    params: { template: string };
  };
  location: {
    pathname: string;
    search: string;
  };
}

const AppRedirect = (props: Props) => {
  const dispatch = useDispatch();
  const templateSlug = props.match.params.template;
  let giftArrayParam = props.location.search.includes('gift_array')
    ? getJsonFromUrlParams(props.location.search).gift_array
    : '';
  // TODO: refactor locale into a prop once language select interface is implemented
  const queryParams = props.location.search;

  const { currentLocale } = useTypedSelector(
    ({ currentLanguage: { currentLocale } }) => ({
      currentLocale,
    })
  );
  const { donationTemplateError, isLoading } = useTypedSelector(
    ({ donationTemplateSchema: { donationTemplateError, isLoading } }) => ({
      donationTemplateError,
      isLoading,
    })
  );

  useEffect(() => {
    const topDomain = getTopLevelDomain({
      host: window.location.host,
      isLocal: process.env.REACT_APP_NODE_ENV === 'development',
      isUk: false,
    });
    dispatch(
      getDonationTemplate({
        templateSlug,
        topDomain,
        giftArrayParam,
        locale: currentLocale,
      })
    );
    setMailcodeCookie();
  }, [dispatch, templateSlug, giftArrayParam, currentLocale]);

  if (isLoading) {
    return null;
  }

  return (
    <Switch>
      <Route
        path="/:template"
        render={props => {
          return donationTemplateError ? (
            <Redirect to={`${DEFAULT_TEMPLATE}${queryParams}`} />
          ) : (
            <App {...props} />
          );
        }}
      />
    </Switch>
  );
};

export default AppRedirect;
