import { createSelector } from 'reselect';
import { StoreState } from 'types/state';
import { DonationTemplateSchema } from 'types/shared';

/* SELECTOR FUNCTIONS
 * To be used in mapStateToProps in `connect` calls
 */
// TODO: remove reselect. we don't have any performance boosts from it
export const appSelector = (state: StoreState) =>
  state.donationTemplateSchema.donationTemplateSchema as DonationTemplateSchema;

export const plaidPublicKeySelector = createSelector(
  appSelector,
  ({ plaidPublicKey }) => plaidPublicKey
);

export const plaidEnvSelector = createSelector(
  appSelector,
  ({ plaidEnv }) => plaidEnv
);

export const statesSelector = createSelector(appSelector, ({ states }) => {
  return states.reduce((obj, val) => {
    const result = obj;
    result[val.state_id] = val.state;
    return result;
  }, {});
});

export const defaultCountrySelector = createSelector(
  appSelector,
  ({ defaultCountry }) => defaultCountry
);

export const donationTemplateSelector = createSelector(
  appSelector,
  ({ donationTemplate }) => {
    return donationTemplate;
  }
);

export const domainNameSelector = createSelector(
  donationTemplateSelector,
  ({ DTDomainName }) => DTDomainName
);

export const defaultCurrencySelector = createSelector(
  donationTemplateSelector,
  ({ defaultCurrency }) => defaultCurrency
);
