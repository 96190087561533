import { FREQUENCY } from 'constants/constants';
import { upperFirst } from 'lodash';

import getJsonFromUrlParams from 'helpers/getJsonFromUrlParams';

export interface ParamsValues {
  mailCodeName?: string;
  giftFrequency?: string;
  isHonoreeInfo?: boolean;
  donationAmount?: string;
  donationLevel?: number;
  otherAmount?: string;
  giftArray?: string;
}

export const getParamsValues = urlParams => {
  // param values to be merged into form values
  const paramsValues: ParamsValues = {};
  if (urlParams) {
    let params = getJsonFromUrlParams(urlParams);

    // &st_source OR &s_src, st_source takes precedence
    if (params.st_source) {
      paramsValues.mailCodeName = params.st_source;
    } else if (params.s_src) {
      paramsValues.mailCodeName = params.s_src;
    }

    // &donation_frequency=monthly
    if (params.donation_frequency?.toLowerCase() === 'monthly') {
      paramsValues.giftFrequency = FREQUENCY.MONTHLY;
    }

    // &donation_frequency=annual
    if (params.donation_frequency?.toLowerCase() === 'annual') {
      paramsValues.giftFrequency = FREQUENCY.ANNUAL;
    }

    // &honor=yes
    if (params.honor === 'yes') paramsValues.isHonoreeInfo = true;

    // &donation_level_id=[1,2,3,4]
    if (params.donation_level_id) {
      paramsValues.donationLevel = Number(params.donation_level_id);
    }

    // &other_amount=
    if (params.other_amount) {
      paramsValues.otherAmount = params.other_amount;
      paramsValues.donationAmount = 'OtherAmount';
    }

    // &gift_array=
    if (params.gift_array) {
      paramsValues.giftArray = params.gift_array;
    }
  }

  return paramsValues;
};

/**
 * Method used to generate the message to display below
 * the 'Select Amount' section in the donation form
 */
export const generateDonationMessage = (values, displayedMessages) => {
  if (values.donationAmount === 'OtherAmount') {
    return ``;
  } else {
    let message = upperFirst(
      `${values.donationAmount &&
        displayedMessages.find(
          message => message.amount === Number(values.donationAmount)
        )?.message}`
    );

    if (message === 'Null') {
      message = '';
    }

    return message;
  }
};

export const getTopLevelDomain = ({
  host,
  isLocal, // for dev purposes only
  isUk, // for dev purposes only
}: {
  host: string;
  isLocal?: boolean;
  isUk?: boolean;
}) => {
  if (isLocal) {
    host = isUk ? 'donate.smiletrain.org.uk' : 'donate.smiletrain.org';
  }
  const hostArr = host.split('.');
  return hostArr[hostArr.length - 1];
};
