import React from 'react';
import PropTypes from 'prop-types';

const FooterLegal = props => {
  const isUk = props.isUk || false;
  const currentYear = new Date().getFullYear();

  return (
    <aside className="module">
      {props.children}
      {!isUk ? (
        <p id="legal-footer-us">
          &copy;{currentYear} Smile Train, Inc.
          <br />
          Smile Train is a 501 (c)(3) | EIN: 13-3661416
        </p>
      ) : (
        <p id="legal-footer-uk">
          &copy;{currentYear} Smile Train UK. Registered Charity No. 1114748
          <br />
          Registered Company No. 05738962
        </p>
      )}
    </aside>
  );
};

FooterLegal.propTypes = {
  isUk: PropTypes.bool,
  children: PropTypes.node,
};

FooterLegal.defaultProps = {
  isUk: false,
};

export default FooterLegal;
