import * as Yup from 'yup';
import {
  US_COUNTRY_CODE,
  UK_COUNTRY_CODE,
  UK_POSTAL_CODE_REGEX,
  CANADA_COUNTRY_CODE,
  US_POSTAL_CODE_REGEX,
} from 'constants/constants';
import { store } from '../../index';
import { CMS_TEMPLATE_REGEX } from '@smile-train/common';

/*
 * HONORARY FORM
 */
const validationSchema = () => {
  const {
    donationTemplateSchema: {
      donationTemplateSchema: {
        donationTemplate: {
          donationFormValidationContent,
          donationFormMainContent: {
            personalEmailFormLabel,
            firstNameFormLabel,
            lastNameFormLabel,
            donationAddressFormContent,
            honoraryGiftFormContent,
          },
        },
      },
    },
  } = store.getState();

  return {
    isHonoreeInfo: Yup.boolean().required(),
    honoraryType: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.giftTypeValidate
      ),
    }),
    honoraryTitle: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.nameTitleValidation
      ),
    }),
    honoraryFirstName: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string()
        .matches(
          /^[a-zA-Z]([\w -]*[a-zA-Z])?$/,
          donationFormValidationContent.invalidCharacterText
        )
        .required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            firstNameFormLabel
          )
        ),
    }),
    honoraryLastName: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string()
        .matches(
          /^[a-zA-Z]([\w -]*[a-zA-Z])?$/,
          donationFormValidationContent.invalidCharacterText
        )
        .required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            lastNameFormLabel
          )
        ),
    }),
    mailedOrEcard: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().required(),
    }),

    eCardEmail: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'eCard',
        then: Yup.string()
          .email(donationFormValidationContent.invalidEmailValidation)
          .required(
            donationFormValidationContent.requiredValidationLabel.replace(
              CMS_TEMPLATE_REGEX,
              personalEmailFormLabel
            )
          ),
      }),
    }),
    ecardImage: Yup.string().when('mailedOrEcard', {
      is: 'eCard',
      then: Yup.string().required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          donationFormValidationContent.eCardLabelText
        )
      ),
    }),
    eCardSubjectLine: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'eCard',
        then: Yup.string().required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            honoraryGiftFormContent.emailSubjectLabel
          )
        ),
      }),
    }),
    eCardEmailBody: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'eCard',
        then: Yup.string().required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            honoraryGiftFormContent.emailBodyLabel
          )
        ),
      }),
    }),
    honoraryAddress1: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'mailed',
        then: Yup.string().required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            donationAddressFormContent.addressOneInput
          )
        ),
      }),
    }),
    honoraryAddress2: Yup.string(),
    honoraryCity: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'mailed',
        then: Yup.string().required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            donationAddressFormContent.cityInputLabel
          )
        ),
      }),
    }),
    honoraryState: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'mailed',
        then: Yup.string().when('honoraryCountry', {
          is: US_COUNTRY_CODE || CANADA_COUNTRY_CODE,
          then: Yup.string().required(
            donationFormValidationContent.requiredValidationLabel.replace(
              CMS_TEMPLATE_REGEX,
              donationFormValidationContent.stateProvinceLabel
            )
          ),
        }),
      }),
    }),
    honoraryZipCode: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string()
        .when('mailedOrEcard', {
          is: 'mailed',
          then: Yup.string().required(
            donationFormValidationContent.requiredValidationLabel.replace(
              CMS_TEMPLATE_REGEX,
              donationFormValidationContent.zipPostalCodeLabel
            )
          ),
        })
        .when('honoraryCountry', {
          is: US_COUNTRY_CODE,
          then: Yup.string().matches(
            US_POSTAL_CODE_REGEX,
            donationFormValidationContent.invalidZipCodeValidation
          ),
        })
        .when('honoraryCountry', {
          is: UK_COUNTRY_CODE,
          then: Yup.string().matches(
            //see: https://stackoverflow.com/a/51885364/4552841
            UK_POSTAL_CODE_REGEX,
            donationFormValidationContent.invalidZipCodeValidation.replace(
              CMS_TEMPLATE_REGEX,
              donationAddressFormContent.postalCodeLabel
            )
          ),
        }),
    }),
    honoraryCountry: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'mailed',
        then: Yup.string().required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            donationAddressFormContent.countryInputLabel
          )
        ),
      }),
    }),
    mailedFromName: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'mailed',
        then: Yup.string().required(
          donationFormValidationContent.mailedFromLabel
        ),
      }),
    }),
    mailedToName: Yup.string().when('isHonoreeInfo', {
      is: true,
      then: Yup.string().when('mailedOrEcard', {
        is: 'mailed',
        then: Yup.string().required(
          donationFormValidationContent.mailedToLabel
        ),
      }),
    }),
  };
};

export default validationSchema;
