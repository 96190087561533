import initialFormState from './initialState';
import { FormState } from 'types/state';
import {
  ImmerReducer,
  createActionCreators,
  createReducerFunction,
} from 'immer-reducer';

class FormReducer extends ImmerReducer<FormState> {
  public setConfirmation(confirmation: boolean) {
    this.draftState.confirmation = confirmation;
  }
}

export const FormActions = createActionCreators(FormReducer);
export default createReducerFunction(FormReducer, initialFormState);
