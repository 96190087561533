import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import logo from 'images/logo.svg';
import './DonorReceipt.css';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';

const DonorReceipt = props => {
  const {
    isUk,
    handleClose,
    donorName,
    billingAddress,
    billingCity,
    billingPostalCode,
    giftAmount,
    currencySymbol,
    giftDate,
    transactionId,
    paymentPlan,
    paymentMethod,
    processorPayload,
    ipAddress,
    billingState,
  } = props;

  // Scroll to the top of the page when viewing the receipt to prevent any errors
  // while capturing the receipt and converting it to a canvas before downloading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const exportAsPdf = async transactionId => {
    const $downloadPdfButton = document.getElementById('downloadPdfButton');
    $downloadPdfButton.setAttribute('disabled', true);
    html2canvas(document.querySelector('#DonorReceipt'), {
      allowTaint: true,
      useCORS: true,
    }).then(canvas => {
      const $downloadPdfButton = document.getElementById('downloadPdfButton');
      let JsPDF = jspdf;
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new JsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width / 4, canvas.height / 5);
      pdf.save(`${transactionId}.pdf`);
      $downloadPdfButton.removeAttribute('disabled');
    });
  };

  return (
    <div className="DonorReceipt module" id="DonorReceipt">
      <header className="DonorReceipt__section">
        <h3 id="receipt-header-1">Donation Receipt</h3>
        <p id="receipt-subheader" data-html2canvas-ignore>
          Please print or save a copy of this receipt for your records.
        </p>
        <img
          src={logo}
          width="150"
          height="65"
          alt="Smile Train logo"
          className="DonorReceipt__item DonorReceipt__logo"
          data-html2canvas-ignore
        />
      </header>
      <main className="DonorReceipt__section">
        <div className="DonorReceipt__item">
          <h4 id="receipt-header-2">Smile Train</h4>
          {!isUk ? (
            <div>
              <div id="receipt-po-box">PO Box 96231</div>
              <div id="receipt-city">Washington, D.C.</div>
              <div id="receipt-zip">20090-6231</div>
              <div id="receipt-tax-id">Tax ID Number: 13-3661416</div>
            </div>
          ) : (
            <div>
              <div id="receipt-post-name">York House</div>
              <div id="receipt-street">Wetherby Road</div>
              <div id="receipt-postal-code">York YO26 7NH UK</div>
            </div>
          )}
        </div>
        <div className="DonorReceipt__item">
          <h4 id="receipt-header-3">Donor Info</h4>
          <div>
            <div id="receipt-donor-name">
              <strong>{donorName}</strong>
            </div>
            {billingAddress && (
              <>
                <div id="receipt-donor-address">{billingAddress}</div>
                <div id="receipt-donor-city-state">
                  <span>
                    {billingCity}
                    {', '}
                  </span>
                  <span>{billingState} </span>
                  <span>{billingPostalCode}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="DonorReceipt__item">
          <h4 id="receipt-header-4">Donation Info</h4>
          <table>
            <tbody>
              <tr>
                <td id="receipt-gift-amount">Gift Amount</td>
                <td>
                  {currencySymbol}
                  {giftAmount}
                </td>
              </tr>
              <tr>
                <td id="receipt-gift-date">Gift Date</td>
                <td>{giftDate}</td>
              </tr>
              <tr>
                <td id="receipt-transaction-id">Transaction ID</td>
                <td>{transactionId}</td>
              </tr>
              <tr>
                <td id="receipt-ip-address">IP Address</td>
                <td>{ipAddress}</td>
              </tr>
              <tr>
                <td id="receipt-payment-plan-header">Payment Plan</td>
                <td id="receipt-payment-plan">{paymentPlan}</td>
              </tr>
              <tr>
                <td id="receipt-payment-method-header">Payment Method</td>
                <td id="receipt-payment-method">{paymentMethod}</td>
              </tr>
              {paymentMethod === 'PayPal' && (
                <tr>
                  <td id="receipt-paypal-account">PayPal Account</td>
                  <td>{processorPayload.details.email}</td>
                </tr>
              )}
              {paymentMethod === 'Credit Card' && [
                <tr key="1">
                  <td id="receipt-credit-card-name">Name on Card</td>
                  <td>{processorPayload.details.cardholderName}</td>
                </tr>,
                <tr key="2">
                  <td id="receipt-credit-card-type">Card Type</td>
                  <td>{processorPayload.details.cardType}</td>
                </tr>,
                <tr key="3">
                  <td id="receipt-credit-card-number">Card Number</td>
                  <td>************{processorPayload.details.lastFour}</td>
                </tr>,
              ]}
              {(paymentMethod === 'Google Pay' ||
                paymentMethod === 'Apple Pay') && [
                <tr key="1">
                  <td id="receipt-credit-card-type">Card Type</td>
                  <td>{processorPayload.details.cardType}</td>
                </tr>,
                <tr key="2">
                  <td id="receipt-credit-card-number">Card Number</td>
                  <td>************{processorPayload.details.lastFour}</td>
                </tr>,
              ]}
              {paymentMethod === 'Bank Account' && [
                <tr key="4">
                  <td id="receipt-ach-name-header">Bank Name</td>
                  <td id="receipt-ach-name">
                    {processorPayload.institution.name}
                  </td>
                </tr>,
                <tr key="5">
                  <td id="receipt-ach-account-header">Account</td>
                  <td id="receipt-ach-account">
                    {processorPayload.account.name} (
                    {processorPayload.account.mask})
                  </td>
                </tr>,
              ]}
            </tbody>
          </table>
        </div>
      </main>
      <footer className="DonorReceipt__section">
        <h5 id="receipt-footer-text">
          Thank you for
          <br />
          creating smiles
        </h5>
        <div className="DonorReceipt__actions" data-html2canvas-ignore>
          <button
            type="button"
            onClick={handleClose}
            id="receipt-close-button"
            className="btn btn--secondary"
          >
            Close
          </button>
          <button
            type="button"
            onClick={() => exportAsPdf(transactionId)}
            className="btn btn--secondary"
            id="downloadPdfButton"
          >
            Download
          </button>
        </div>
      </footer>
    </div>
  );
};

DonorReceipt.propTypes = {
  isUk: PropTypes.bool,
  handleClose: PropTypes.func,
  donorName: PropTypes.string,
  billingAddress: PropTypes.string,
  billingCity: PropTypes.string,
  billingState: PropTypes.string,
  billingPostalCode: PropTypes.string,
  giftAmount: PropTypes.string,
  currencySymbol: PropTypes.string,
  giftDate: PropTypes.string,
  transactionId: PropTypes.string,
  paymentPlan: PropTypes.string,
  paymentMethod: PropTypes.string,
  processorPayload: PropTypes.object,
  ipAddress: PropTypes.string,
  cardholderName: PropTypes.string,
};

export default DonorReceipt;
