import React from 'react';
import PropTypes from 'prop-types';
import icons from './icons';

interface Props {
  name: string;
  color?: string | boolean;
}

const Icon = (props: Props) => {
  const { name } = props;
  const Icon = icons[name] ? icons[name] : icons.Close;
  return <Icon {...props} />;
};

Icon.propTypes = {
  name: PropTypes.string,
};

export default Icon;
