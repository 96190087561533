import React from 'react';
import { useTypedSelector } from 'shared/utils';
import { Dialog, Classes } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { closeErrorMessage } from 'redux/thunks/closeErrorMessage';

interface Props {
  match: object;
  history: object;
}

const PaymentError = ({ match, history }: Props) => {
  const dispatch = useDispatch();

  const { hasError, errorMessage } = useTypedSelector(
    ({ payment: { errorMessage, hasError } }) => ({
      hasError,
      errorMessage,
    })
  );

  return (
    <Dialog
      title="Error"
      isOpen={hasError}
      onClose={() => dispatch(closeErrorMessage(match, history))}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{errorMessage}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div
          className={Classes.DIALOG_FOOTER_ACTIONS}
          style={{ justifyContent: 'center' }}
        >
          <button
            type="button"
            className="btn btn--solo"
            onClick={() => dispatch(closeErrorMessage(match, history))}
          >
            Ok
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentError;
