import { FormState } from 'types/state';

const initialFormState: FormState = {
  confirmation: false,
  mailedOrEcard: 'eCard',
  mailedFromName: '',
  mailedToName: '',
  billingAddress1: '',
  billingAddress2: '',
  billingCountry: '',
  billingCity: '',
  billingState: '',
  billingPhone: '',
  billingPhoneType: '',
  billingZipCode: '',
  honoraryFirstName: '',
  honoraryLastName: '',
  isLegacyDonation: false,
  legacyFirstName: '',
  legacyLastName: '',
  legacyTitle: '',
  legacyCountry: '',
  legacyAddress1: '',
  legacyAddress2: '',
  legacyCity: '',
  legacyPostalCode: '',
  legacyState: '',
  personalFirstName: '',
  personalLastName: '',
  personalEmail: '',
  donationAmount: '',
  giftFrequency: '',
  eCardEmail: '',
  eCardEmailBody: '',
  ecardImage: '0',
  eCardSubjectLine: '',
  honoraryType: '',
  honoraryTitle: '',
  sendCopyOfEmail: false,
  honoraryAddress1: '',
  honoraryAddress2: '',
  honoraryCity: '',
  honoraryCountry: '',
  honoraryState: '',
  honoraryZipCode: '',
  otherAmount: '',
  isHonoreeInfo: false,
  mailCodeName: '',
  emailSubscribe: '',
  smsSubscribe: '',
  phoneSubscribe: '',
  mailSubscribe: '',
};

export default initialFormState;
