import React from 'react';

import ScrollToTopOnMount from 'components/ScrollToTopOnMount';
import Meta from '../Meta/Meta';
import './Page.css';

const Page = ({
  title = undefined,
  isLeftAligned = false,
  children,
}: {
  title?: string;
  isLeftAligned?: boolean;
  children?: React.ReactNode;
}) => {
  //TODO: make this a part of the theme
  const style = isLeftAligned ? 'LeftAlign' : 'Page';
  return (
    <div className={style}>
      <Meta title={title} />
      <ScrollToTopOnMount />
      {children}
    </div>
  );
};

export default Page;
