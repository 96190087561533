import { CMS_TEMPLATE_REGEX, EMAIL_REGEX } from '@smile-train/common';
import * as Yup from 'yup';
import { store } from '../../index';

/*
 * CONTACT INFO FORM
 * donationTemplate.personalInformationJson
 */

const SPECIAL_CHARS_REGEX = /^[^0-9!@#$%^&*()]*$/;

const validationSchema = () => {
  const {
    donationTemplateSchema: {
      donationTemplateSchema: {
        donationTemplate: {
          donationFormValidationContent,
          donationFormMainContent: {
            firstNameFormLabel,
            lastNameFormLabel,
            personalEmailFormLabel,
          },
        },
      },
    },
  } = store.getState();

  return {
    personalFirstName: Yup.string()
      .matches(
        SPECIAL_CHARS_REGEX,
        donationFormValidationContent.specialCharValidation.replace(
          CMS_TEMPLATE_REGEX,
          firstNameFormLabel
        )
      )
      .max(
        25,
        donationFormValidationContent.maxNameValidation
          .replace(/\{name\}/, firstNameFormLabel)
          .replace(/\{characterLimit\}/, '25')
      )
      .required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          firstNameFormLabel
        )
      ),
    personalLastName: Yup.string()
      .matches(
        SPECIAL_CHARS_REGEX,
        donationFormValidationContent.specialCharValidation.replace(
          CMS_TEMPLATE_REGEX,
          lastNameFormLabel
        )
      )
      .max(
        25,
        donationFormValidationContent.maxNameValidation
          .replace(/\{name\}/, lastNameFormLabel)
          .replace(/\{characterLimit\}/, '25')
      )
      .required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          lastNameFormLabel
        )
      ),
    personalEmail: Yup.string()
      .max(
        80,
        donationFormValidationContent.maxNameValidation
          .replace(/\{name\}/, personalEmailFormLabel)
          .replace(/\{characterLimit\}/, '80')
      )
      .matches(
        EMAIL_REGEX,
        donationFormValidationContent.invalidEmailValidation
      )
      .required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          personalEmailFormLabel
        )
      ),
  };
};

export default validationSchema;
