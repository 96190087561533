import React from 'react';
import { useTypedSelector } from 'shared/utils';
import { Switch, Route, match } from 'react-router-dom';
import { History, Location } from 'history';
import { StaticContext } from 'react-router';
import { datadogLogs } from '@datadog/browser-logs';

import Meta from './components/Meta/Meta';
import Spinner from 'components/Spinner/Spinner';
import BackgroundImage from 'components/BackgroundImage';
import { ROUTES } from 'constants/constants';
// Pages
import HomeSinglePage from 'pages/HomeSinglePage/HomeSinglePage';
import FourOhFourPage from 'pages/FourOhFourPage';
// Stylesheets
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import './App.css';
import ConfirmationPageContentContainer from 'containers/ConfirmationPageContentContainer';

interface Props {
  history: History<unknown>;
  location: Location<unknown>;
  match: match<{ template: string }>;
  staticContext?: StaticContext | undefined;
}

const App: React.FC<Props> = () => {
  const donationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
    site: process.env.REACT_APP_DATADOG_SITE ?? '',
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
  });

  if (!donationTemplateSchema) return <Spinner />;

  const {
    donationTemplate: {
      donationTemplateMobileImage,
      donationTemplateImage,
      templateTheme,
    },
  } = donationTemplateSchema;

  const isOffset = templateTheme === 'offset';

  return (
    <BackgroundImage
      src={isOffset ? donationTemplateImage : ''}
      mobileSrc={isOffset ? donationTemplateMobileImage : ''}
    >
      <Meta />
      <Switch>
        <Route exact path={ROUTES.TEMPLATED_HOME} component={HomeSinglePage} />
        <Route
          path={`${ROUTES.TEMPLATED_HOME}${ROUTES.DONATE}`}
          component={HomeSinglePage}
        />
        <Route
          path={`${ROUTES.TEMPLATED_HOME}${ROUTES.CONFIRMATION}`}
          component={ConfirmationPageContentContainer}
        />
        <Route component={FourOhFourPage} />
      </Switch>
    </BackgroundImage>
  );
};

export default App;
