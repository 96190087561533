import * as Yup from 'yup';
import { store } from '../../index';
import { CMS_TEMPLATE_REGEX } from '@smile-train/common';

// Method that validates the minumum custom donation amount using the value set
// in GraphCMS, which we pull from the redux store here
function minimumValidator(this: Yup.NumberSchema, num: number) {
  return this.test('minimumValidator', 'Invalid minimum amount', function(num) {
    const {
      donationTemplateSchema: {
        donationTemplateSchema: {
          donationTemplate: {
            giftArray: { customDonationMinimumAmount },
            donationFormValidationContent,
          },
        },
      },
    } = store.getState();

    const minimumDonation = customDonationMinimumAmount
      ? customDonationMinimumAmount
      : 5;

    if (num && num < minimumDonation) {
      return this.createError({
        path: this.path,
        message: donationFormValidationContent.minValidationError.replace(
          CMS_TEMPLATE_REGEX,
          minimumDonation.toString()
        ),
      });
    } else {
      return true;
    }
  });
}

function maximumValidator(this: Yup.NumberSchema, num: number) {
  return this.test('maximumValidator', 'Invalid maximum amount', function(num) {
    const {
      donationTemplateSchema: {
        donationTemplateSchema: {
          donationTemplate: {
            giftArray: { customDonationMaximumAmount },
            donationFormValidationContent,
          },
        },
      },
    } = store.getState();

    const maximumDonation = customDonationMaximumAmount
      ? customDonationMaximumAmount
      : 100000;

    if (num && num > maximumDonation) {
      return this.createError({
        path: this.path,
        message: donationFormValidationContent.minValidationError.replace(
          CMS_TEMPLATE_REGEX,
          maximumDonation.toString()
        ),
      });
    } else {
      return true;
    }
  });
}

Yup.addMethod(Yup.number, 'minimumValidator', minimumValidator);
Yup.addMethod(Yup.number, 'maximumValidator', maximumValidator);

/*
 * DONATION FORM
 * donationTemplate.donationAmountJson
 */
const validationSchema = () => {
  const {
    donationTemplateSchema: {
      donationTemplateSchema: {
        donationTemplate: {
          donationFormValidationContent,
          donationFormMainContent: { giftFrequencyHeader },
        },
      },
    },
  } = store.getState();
  return {
    giftFrequency: Yup.string().required(
      donationFormValidationContent.requiredValidationLabel.replace(
        CMS_TEMPLATE_REGEX,
        giftFrequencyHeader
      )
    ),
    donationAmount: Yup.string().required(
      donationFormValidationContent.requiredValidationLabel.replace(
        CMS_TEMPLATE_REGEX,
        donationFormValidationContent.donationAmountLabel
      )
    ),
    otherAmount: Yup.mixed().when('donationAmount', {
      is: 'OtherAmount',
      then: Yup.number()
        .positive()
        .test(
          'two-decimals',
          donationFormValidationContent.otherDonationAmountValidation,
          value => {
            if (!value) return true;
            const [, cents] = value.toString().split('.');
            return !cents || cents.length <= 2;
          }
        )
        // @ts-ignore
        .minimumValidator()
        .maximumValidator(),
    }),
    isHonoreeInfo: Yup.boolean(), // this is for the ?honor= query param
  };
};

export default validationSchema;
