import React from 'react';

export default function Bank(props) {
  const color = props.color || '#4c79bc';
  return (
    <svg viewBox="0 0 32 32" width="24" height="24">
      <g fill={color}>
        <rect x="3" y="16" width="2" height="10" />
        <rect data-color="color-2" x="11" y="16" width="2" height="10" />
        <rect data-color="color-2" x="19" y="16" width="2" height="10" />
        <rect data-color="color-2" x="27" y="16" width="2" height="10" />
        <path
          fill={color}
          d="M31,28H1c-0.552,0-1,0.447-1,1v2c0,0.553,0.448,1,1,1h30c0.552,0,1-0.447,1-1v-2C32,28.447,31.552,28,31,28z "
        />
        <path
          fill={color}
          d="M31.471,8.117l-15-8c-0.294-0.156-0.647-0.156-0.941,0l-15,8C0.204,8.291,0,8.631,0,9v4c0,0.553,0.448,1,1,1 h30c0.552,0,1-0.447,1-1V9C32,8.631,31.796,8.291,31.471,8.117z M16,10c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2 c1.105,0,2,0.895,2,2C18,9.105,17.105,10,16,10z"
        />
      </g>
    </svg>
  );
}
