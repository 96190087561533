import * as Yup from 'yup';
import {
  UK_POSTAL_CODE_REGEX,
  US_COUNTRY_CODE,
  CANADA_COUNTRY_CODE,
  UK_COUNTRY_CODE,
  US_POSTAL_CODE_REGEX,
} from 'constants/constants';
import { store } from '../../index';
import { CMS_TEMPLATE_REGEX } from '@smile-train/common';

const validationSchema = () => {
  const {
    donationTemplateSchema: {
      donationTemplateSchema: {
        donationTemplate: {
          donationFormValidationContent,
          donationFormMainContent: {
            firstNameFormLabel,
            lastNameFormLabel,
            donationAddressFormContent,
          },
        },
      },
    },
  } = store.getState();

  return {
    isLegacyDonation: Yup.boolean().required(),
    legacyFirstName: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string()
        .matches(
          /^[a-zA-Z]([\w -]*[a-zA-Z])?$/,
          donationFormValidationContent.invalidCharacterText
        )
        .required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            firstNameFormLabel
          )
        ),
    }),
    legacyLastName: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string()
        .matches(
          /^[a-zA-Z]([\w -]*[a-zA-Z])?$/,
          donationFormValidationContent.invalidCharacterText
        )
        .required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            lastNameFormLabel
          )
        ),
    }),
    legacyTitle: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.nameTitleValidation
      ),
    }),
    legacyCountry: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          donationAddressFormContent.countryInputLabel
        )
      ),
    }),
    legacyAddress1: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          donationAddressFormContent.addressOneInput
        )
      ),
    }),
    legacyAddress2: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string(),
    }),
    legacyCity: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          donationAddressFormContent.cityInputLabel
        )
      ),
    }),
    legacyPostalCode: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string()
        .when('legacyCountry', {
          is: UK_COUNTRY_CODE,
          then: Yup.string()
            .matches(
              UK_POSTAL_CODE_REGEX,
              donationFormValidationContent.invalidZipCodeValidation.replace(
                CMS_TEMPLATE_REGEX,
                donationAddressFormContent.postalCodeLabel
              )
            )
            .required(
              donationFormValidationContent.requiredValidationLabel.replace(
                CMS_TEMPLATE_REGEX,
                donationAddressFormContent.postalCodeLabel
              )
            ),
        })
        .when('legacyCountry', {
          is: US_COUNTRY_CODE,
          then: Yup.string()
            .matches(
              US_POSTAL_CODE_REGEX,
              donationFormValidationContent.invalidZipCodeValidation.replace(
                CMS_TEMPLATE_REGEX,
                donationAddressFormContent.zipCodeLabel
              )
            )
            .required(
              donationFormValidationContent.requiredValidationLabel.replace(
                CMS_TEMPLATE_REGEX,
                donationAddressFormContent.zipCodeLabel
              )
            ),
        }),
    }),
    legacyState: Yup.string().when('isLegacyDonation', {
      is: true,
      then: Yup.string().when('legacyCountry', {
        is: US_COUNTRY_CODE || CANADA_COUNTRY_CODE,
        then: Yup.string().required(
          donationFormValidationContent.requiredValidationLabel.replace(
            CMS_TEMPLATE_REGEX,
            donationFormValidationContent.stateProvinceLabel
          )
        ),
      }),
    }),
  };
};

export default validationSchema;
