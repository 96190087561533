import * as Yup from 'yup';

import addressValidation from './address';
import contactValidation from './contactInfo';
import donateValidation from './donation';
import honoraryValidation from './honorary';
import legacyValidation from './legacy';

export const getCombinedValidationSchema = () => {
  return Yup.object().shape({
    ...donateValidation(), // TODO: scroll not working
    ...honoraryValidation(),
    ...contactValidation(),
    ...addressValidation(),
    ...legacyValidation(),
  });
};

export type CombinedValidationSchema = typeof getCombinedValidationSchema;
