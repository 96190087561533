import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { includes } from 'lodash';
import { StyleSheet, css } from 'aphrodite';

import { FormSection } from 'components/FormControls';
import { useTypedSelector } from 'shared/utils';
import { PaymentActions } from 'redux/dux/payment';
import { DonationTheme } from 'themes';

interface Props {
  nameRef: React.RefObject<HTMLInputElement>;
  theme: DonationTheme;
}

const BraintreeFields = ({ nameRef, theme }: Props) => {
  const dispatch = useDispatch();
  const {
    cardholderName,
    tokenizeError,
    donationPaymentFormContent,
  } = useTypedSelector(
    ({
      payment: { cardholderName, tokenizeError },
      donationTemplateSchema: {
        donationTemplateSchema: {
          donationTemplate: {
            donationFormMainContent: { donationPaymentFormContent },
          },
        },
      },
    }) => ({
      cardholderName,
      tokenizeError,
      donationPaymentFormContent,
    })
  );

  const error = tokenizeError?.code === 'HOSTED_FIELDS_FIELDS_EMPTY';
  const invalidFieldKeys = tokenizeError?.details?.invalidFieldKeys || [];

  return (
    <FormSection>
      <input type="hidden" name="payment-method-nonce" />
      <div className="same-line-input">
        <div className={`input-group ${css(styles.singleField)}`}>
          <label
            id="card-number-label"
            htmlFor="card-number"
            style={theme.textInput.label}
          >
            {donationPaymentFormContent.cardNumberLabel}
          </label>
          <div
            id="card-number"
            className={classNames(
              'hosted-field',
              (error || includes(invalidFieldKeys, 'number')) &&
                'text-input--error'
            )}
            style={theme.textInput.input}
          />
        </div>
        <div className={`input-group ${css(styles.cvv)}`}>
          <label
            id="cardholder-name-label"
            htmlFor="cardholder-name"
            style={theme.textInput.label}
          >
            {donationPaymentFormContent.cardNameLabel}
          </label>
          <input
            type="text"
            id="cardholder-name"
            className="text-input"
            placeholder={donationPaymentFormContent.cardNameLabel}
            value={cardholderName}
            onChange={e =>
              dispatch(PaymentActions.setCardholderName(e.target.value))
            }
            ref={nameRef}
            required
            style={theme.textInput.input}
          />
        </div>
      </div>

      <div className="same-line-input">
        <div className="input-group">
          <label id="cvv-label" htmlFor="cvv" style={theme.textInput.label}>
            {donationPaymentFormContent.cardCvvLabel}
          </label>
          <div
            id="cvv"
            className={classNames(
              'hosted-field',
              (error || includes(invalidFieldKeys, 'cvv')) &&
                'text-input--error'
            )}
            style={theme.textInput.input}
          />
        </div>{' '}
        <div>
          <label
            id="expiration-date-label"
            htmlFor="expiration-date"
            style={theme.textInput.label}
          >
            {donationPaymentFormContent.cardExpirationLabel}
          </label>
          <div
            id="expiration-date"
            className={classNames(
              'hosted-field',
              (error || includes(invalidFieldKeys, 'expirationDate')) &&
                'text-input--error'
            )}
            style={theme.textInput.input}
          />
        </div>
      </div>
      {tokenizeError?.message && (
        <div className="input-feedback">{tokenizeError.message}</div>
      )}
    </FormSection>
  );
};

export default BraintreeFields;

const styles = StyleSheet.create({
  singleField: { width: 'calc(50% - 10px)' },
  cvv: { width: 'calc(50% - 20px)' },
});
