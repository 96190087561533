import braintree from 'braintree-web';
import defaultBraintreeOptions from './defaultOptions.BraintreeService';
import { store } from 'index';
let instance: BraintreeService | undefined;

export default class BraintreeService {
  client?: braintree.Client;
  paypalClient?: braintree.PayPal;
  dataCollector?: braintree.DataCollector;
  applePayClient?: braintree.ApplePay;
  googlePayClient?: braintree.GooglePayment;
  threeDSecureClient?: braintree.ThreeDSecure;

  public static init() {
    return (async function() {
      if (!instance) {
        const braintreeService = new BraintreeService();
        const {
          donationTemplateSchema: {
            donationTemplateSchema: { braintreeClientToken },
          },
        } = store.getState();
        await braintreeService.createClient(braintreeClientToken);
        await braintreeService.createPaypalClient();
        instance = braintreeService;
      }
      return instance;
    })();
  }

  public createHostedFields() {
    if (!this.client) {
      throw new Error('No Braintree client initialized');
    }
    return braintree.hostedFields.create({
      client: this.client,
      ...defaultBraintreeOptions,
    });
  }

  /******** PAYPAL PUBLIC METHODS ********/

  public paypalTokenize = () => {
    if (!this.paypalClient) {
      throw new Error('No Paypal client initialized');
    }
    return this.paypalClient.tokenize({ flow: 'vault' });
  };

  public closePaypalWindow = () => {
    if (!this.paypalClient) {
      throw new Error('No Paypal client initialized');
    }
    // @ts-ignore
    this.paypalClient.closeWindow();
  };

  public focusPaypalWindow = () => {
    if (!this.paypalClient) {
      throw new Error('No Paypal client initialized');
    }
    // @ts-ignore
    this.paypalClient.focusWindow();
  };

  public async getDeviceData() {
    if (!this.client) {
      throw new Error('No Braintree client initialized');
    }

    this.dataCollector = await braintree.dataCollector.create({
      client: this.client,
      kount: false,
      paypal: true,
    });

    return this.dataCollector.deviceData;
  }

  /******** APPLE PAY PUBLIC METHODS ********/

  public async createApplePayPaymentRequest(amount: string) {
    await this.createApplePayClient();
    if (!this.applePayClient) {
      throw new Error('No Apple Pay client initialized');
    }
    //@ts-ignore
    return await this.applePayClient.createPaymentRequest({
      total: { label: 'Apple Pay donation', amount },
      requiredBillingContactFields: ['postalAddress'],
    });
  }

  public getApplePayClient() {
    return this.applePayClient;
  }

  /******** GOOGLE PAY PUBLIC METHODS ********/

  public async getGooglePaymentData(
    paymentRequest: google.payments.api.PaymentData
  ) {
    if (!this.googlePayClient) {
      await this.getGooglePaymentInstance();
      if (!this.googlePayClient) {
        throw new Error(
          'Google Pay failed to load. Try another payment option, try again in a few minutes, or contact and administrator.'
        );
      }
    }

    const paymentData = await this.googlePayClient.parseResponse(
      paymentRequest
    );
    return paymentData;
  }

  public async getGooglePayAllowedMethods() {
    await this.getGooglePaymentInstance();
    if (!this.googlePayClient) {
      throw new Error(
        'Google Pay failed to load. Try another payment option, try again in a few minutes, or contact and administrator.'
      );
    }

    const dataRequest = await this.googlePayClient.createPaymentDataRequest();
    dataRequest.allowedPaymentMethods.forEach(method => {
      method.parameters.billingAddressRequired = true;
      method.parameters.billingAddressParameters = {
        format: 'FULL',
        phoneNumberRequired: true,
      };
    });
    return dataRequest.allowedPaymentMethods;
  }

  public async getGooglePaymentInstance() {
    if (this.googlePayClient) {
      return this.googlePayClient;
    }
    await this.createGooglePayClient();
    if (!this.googlePayClient) {
      throw new Error(
        'Failed to create Google Pay Client in Braintree Service.'
      );
    }
    return this.googlePayClient;
  }

  /******** THREE D SECURE PUBLIC METHODS ********/

  public async threeDSecureVerifyCard(threeDSecureParameters: {
    amount: string;
    nonce: string;
    bin: string;
    email: string;
    billingAddress: {
      givenName: string;
      surName: string;
      phoneNumber: string;
      streetAddress: string;
      extendedAddress: string;
      locality: string; // city
      region: string; // state
      postalCode: string;
      countryCodeAlpha2: string;
    };
    onLookupComplete: (data: any, next: any) => void;
  }) {
    await this.createThreeDSecureClient();

    if (!this.threeDSecureClient) {
      throw new Error("No 3DS client, can't verify card.");
    }

    const verificationResult = await this.threeDSecureClient.verifyCard(
      threeDSecureParameters
    );

    return verificationResult;
  }

  /******** PRIVATE METHODS ********/

  private async createClient(authToken: string) {
    this.client = await braintree.client.create({ authorization: authToken });
  }

  private async createPaypalClient() {
    if (!this.client) {
      throw new Error('No Braintree client initialized');
    }
    this.paypalClient = await braintree.paypal.create({ client: this.client });
  }

  private async createApplePayClient() {
    if (!this.client) {
      throw new Error('No Braintree client initialized');
    }
    // Ignoring this because the BT typings are only for callback
    // syntax, which we don't want to use here
    //@ts-ignore
    this.applePayClient = await braintree.applePay.create({
      client: this.client,
    });
  }

  private async createGooglePayClient() {
    if (!this.client) {
      throw new Error('No Braintree client initialized');
    }

    this.googlePayClient = await braintree.googlePayment.create({
      client: this.client,
      googlePayVersion: 2,
    });
  }

  private async createThreeDSecureClient() {
    if (!this.client) {
      throw new Error(
        "No Braintree client initialized, can't create 3DS instance."
      );
    }

    this.threeDSecureClient = await braintree.threeDSecure.create({
      version: 2, // Will use 3DS2 if possible, otherwise falls back to 3DS1
      client: this.client,
    });

    return this.threeDSecureClient;
  }
}
