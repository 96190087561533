const dataLayer = window.dataLayer;

const gtmMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    dataLayer.push({
      event: 'ngRouteChange',
      route: action.payload.location.pathname,
      referrer: document.referrer,
    });
  }
  next(action);
};

export default gtmMiddleware;
