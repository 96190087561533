import React from 'react';
import { kebabCase } from 'lodash';
import './SectionHeader.css';
interface Props {
  name: string;
  sectionHeaderStyle?: React.CSSProperties;
  marginBottom?: number;
}

const SectionHeader = ({
  name,
  sectionHeaderStyle = {},
  marginBottom = 17.3,
}: Props) => {
  return (
    <h3
      className="Section__header__text"
      style={{ ...sectionHeaderStyle, marginBottom }}
      id={`${kebabCase(name)}-header`}
    >
      {name}
    </h3>
  );
};

export default SectionHeader;
