import {
  createActionCreators,
  createReducerFunction,
  ImmerReducer,
} from 'immer-reducer';
import { PaymentInfo, PaymentInfoPartial } from 'types/shared';
import { PaymentMethod } from 'constants/constants';
import { GooglePaymentTokenizePayload } from 'braintree-web';

const initialState: PaymentInfo = {
  isAch: false,
  isBraintree: false,
  cardholderName: '',
  giftAid: false,
  doubleTheDonation: false,
  savePaymentDetails: false,
  employerNameDonationMatch: '',
  processorPayload: {},
  isPaymentProcessing: false,
  currentPaymentMethod: PaymentMethod.CREDIT_CARD,
  hasError: false,
  errorMessage: '',
  failedPaypal: false,
  paypalPaymentData: {},
  plaidPaymentData: {},
  googlePaymentData: {},
  errorReturnRoute: null,
  tokenizeError: null,
  isThreeDSecureProcessing: false,
  upsell: null,
};

class PaymentReducer extends ImmerReducer<PaymentInfo> {
  setPayment(payment: PaymentInfoPartial) {
    this.draftState = { ...this.draftState, ...payment };
  }

  setCardholderName(name: string) {
    this.draftState.cardholderName = name;
  }

  setGiftAid(giftAid: boolean) {
    this.draftState.giftAid = giftAid;
  }

  setDoubleTheDonation(doubleTheDonation: boolean) {
    this.draftState.doubleTheDonation = doubleTheDonation;
  }

  setIsPaymentProcessing(isPaymentProcessing: boolean) {
    this.draftState.isPaymentProcessing = isPaymentProcessing;
  }

  setError(errorMessage: string) {
    this.draftState.hasError = true;
    this.draftState.errorMessage = errorMessage;
  }

  failedPaypal() {
    this.draftState.failedPaypal = true;
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.draftState.currentPaymentMethod = paymentMethod;
  }

  closeErrorMessage() {
    this.draftState.errorMessage = '';
    this.draftState.hasError = false;
  }

  setErrorReturnRoute(route: string | null) {
    this.draftState.errorReturnRoute = route;
  }

  setPaypalPaymentData(data: object) {
    this.draftState.paypalPaymentData = data;
  }

  setPlaidPaymentData(data: object) {
    this.draftState.plaidPaymentData = data;
  }

  setGooglePaymentData(data: GooglePaymentTokenizePayload) {
    this.draftState.googlePaymentData = data;
  }

  setTokenizeError(error: object | null) {
    this.draftState.tokenizeError = error;
  }

  setIsThreeDSecureProcessing(isProcessing: boolean) {
    this.draftState.isThreeDSecureProcessing = isProcessing;
  }

  setUpsell(upsell: 'Monthly' | null) {
    this.draftState.upsell = upsell;
  }
}

export const PaymentActions = createActionCreators(PaymentReducer);
const reducerFunction = createReducerFunction(PaymentReducer, initialState);
export default reducerFunction;
