import React from 'react';

export default function Mail(props) {
  const color = props.color || '#4c79bc';
  return (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <g
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeWidth="2"
        fill={color}
        stroke={color}
      >
        <polyline
          data-cap="butt"
          data-color="color-2"
          points="1.614 3.558 12 13 22.385 3.559"
          fill="none"
          strokeMiterlimit="10"
          strokeLinecap="butt"
        />
        <rect
          x="1"
          y="3"
          width="22"
          height="18"
          rx="2"
          ry="2"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}
