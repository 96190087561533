import React from 'react';
import PropTypes from 'prop-types';
import './BackgroundImage.css';

class BackgroundImage extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    children: PropTypes.node,
  };

  componentDidMount() {
    if (window.innerWidth >= 600) {
      const $body = document.getElementsByTagName('body')[0];
      $body.style.backgroundImage = `url(${this.props.src})`;
    }
  }

  render() {
    return this.props.children;
  }
}

export default BackgroundImage;
