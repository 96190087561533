import React, { ReactNode } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useSelector } from 'react-redux';
import { plaidPublicKeySelector, plaidEnvSelector } from 'selectors/schema';

interface Props {
  onSuccess: (token: string, metadata: any) => void;
  onExit: (err: any) => void;
  render({ open, ready }: { open: Function; ready: boolean }): ReactNode;
}

const PlaidLink = ({ onSuccess, onExit, render }: Props) => {
  const plaidPublicKey = useSelector(plaidPublicKeySelector);
  const plaidEnv = useSelector(plaidEnvSelector);

  const config = {
    clientName: 'SmileTrain',
    env: plaidEnv,
    product: ['auth', 'transactions'],
    publicKey: plaidPublicKey,
    onSuccess,
    onExit,
    // ...
    apiVersion: 'v2',
    selectAccount: true,
  };

  const { open, ready } = usePlaidLink(config);

  return <div>{render({ open, ready })}</div>;
};
export default PlaidLink;
