import React, { useState, useEffect } from 'react';
import { Field } from 'formik';

import {
  TextInputFormik,
  FormSection,
} from 'components/FormControls/FormControls';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { DonationTheme } from 'themes';
import {
  useTypedSelector,
  gtmEventSetEeGeneralDonationView,
} from 'shared/utils';
import { DonationTemplateSchema } from 'types/shared';

interface Props {
  theme: DonationTheme;
  values: any;
  errors: any;
}

const ContactInfoForm = ({ theme, values }: Props) => {
  const [isDonationViewed, setIsDonationViewed] = useState(false);
  const {
    donationTemplate: {
      personalInformation: { fields },
      domainUrl,
      donationFormMainContent,
    },
  } = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  ) as DonationTemplateSchema;

  useEffect(() => {
    if (values.personalEmail !== '' && !isDonationViewed) {
      gtmEventSetEeGeneralDonationView(domainUrl);

      setIsDonationViewed(true);
    }
  }, [values.personalEmail, isDonationViewed, domainUrl]);

  return (
    <div className="container module">
      <SectionHeader
        name={donationFormMainContent.personalInfoHeader}
        sectionHeaderStyle={theme.sectionHeader}
      />
      <FormSection>
        <div className="same-line-input">
          {fields.includes('First_Name') && (
            <Field
              name="personalFirstName"
              label={donationFormMainContent.firstNameFormLabel}
              required
              maxLength="25"
              textInputStyle={theme.textInput}
              component={TextInputFormik}
            />
          )}
          {fields.includes('Last_Name') && (
            <Field
              name="personalLastName"
              label={donationFormMainContent.lastNameFormLabel}
              required
              maxLength="25"
              textInputStyle={theme.textInput}
              component={TextInputFormik}
            />
          )}
        </div>
        {fields.includes('Email') && (
          <Field
            name="personalEmail"
            label={donationFormMainContent.personalEmailFormLabel}
            type="email"
            required
            maxLength="80"
            textInputStyle={theme.textInput}
            component={TextInputFormik}
          />
        )}
      </FormSection>
    </div>
  );
};

export default ContactInfoForm;
