import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useTheme } from 'theming';
import { useDispatch } from 'react-redux';

import {
  PaymentMethod,
  FREQUENCY,
  DefaultMailCodes,
  APP_THEME,
} from 'constants/constants';
import {
  CombinedValidationSchema,
  getCombinedValidationSchema,
} from 'forms/validationSchemas';
import { useTypedSelector, checkWindowForUkUrl } from 'shared/utils';
import {
  AddressForm,
  ContactInfoForm,
  DonateForm,
  Header,
  HomePageContent,
  HonoraryForm,
} from 'forms';
import { FormState } from 'types/state';
import { DonationTemplateSchema } from 'types/shared';
import { DonationTheme } from 'themes';
import './HomeSinglePage.css';
import Meta from 'components/Meta/Meta';
import PaymentFormContainer from 'containers/PaymentFormContainer';
import scrollToError from 'helpers/scrollToError';
import { PaymentActions } from 'redux/dux/payment';
import { OptionsPartial, submitPayment } from 'redux/thunks/submitPayment';
import BraintreeService from 'services/Braintree/Braintree.service';
import PaymentButton from 'forms/PaymentForm/PaymentButton';
import { getParamsValues } from 'helpers/utils';
import getCookieValue from 'helpers/getCookieValue';
import { BottomFormImageGroup } from 'components/BottomFormImageGroup';
import PaymentProcessingModal from 'components/PaymentProcessingModal/PaymentProcessingModal';
import { HostedFields } from 'braintree-web';
import BannerAndTopText from 'content/BannerAndTopText';
import LegacyDonationForm from 'forms/LegacyDonationForm/LegacyDonationForm';

interface Props {
  history: object;
  match: object;
  location: {
    search: string;
  };
}

const HomeSinglePage = ({ history, match, location }: Props) => {
  const paramsValues = getParamsValues(location.search);
  const [hostedFields, setHostedFields] = useState<HostedFields>();
  const [showUpsell, setShowUpsell] = useState(false);
  const [donationSubmitButton, setDonationSubmitButton] = useState<
    PaymentMethod
  >(PaymentMethod.CREDIT_CARD);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState('');
  const [combinedValidationSchema, setCombinedValidationSchema] = useState<
    CombinedValidationSchema
  >();
  const braintreeButtonRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const form: FormState = useTypedSelector(state => state.form);
  const {
    googlePaymentData,
    paypalPaymentData,
    plaidPaymentData,
    isPaymentProcessing,
    hasError,
    isThreeDSecureProcessing,
  } = useTypedSelector(
    ({
      payment: {
        googlePaymentData,
        paypalPaymentData,
        plaidPaymentData,
        isPaymentProcessing,
        hasError,
        isThreeDSecureProcessing,
      },
    }) => ({
      googlePaymentData,
      paypalPaymentData,
      plaidPaymentData,
      isPaymentProcessing,
      hasError,
      isThreeDSecureProcessing,
    })
  );
  const donationTemplateSchema: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );
  const isUkUrl = checkWindowForUkUrl();

  let {
    donationTemplate: {
      templateTheme,
      primaryMailCode: { mailCodeName: primaryMailCodeName },
      giftArray,
      donationName,
      DTdescription,
      hideLegacyDonationCheckbox,
      donationFormMainContent,
    },
  } = donationTemplateSchema;

  // Determines whether we should use the STAdSrc cookie or the
  // primaryMailCodeName as the form's mail code value
  const STAdSrc = getCookieValue('STAdSrc');
  if (!paramsValues.mailCodeName && STAdSrc) {
    paramsValues.mailCodeName = STAdSrc;
  }

  // Determines which donation frequency should be selected on page load
  const defaultGiftFrequency =
    giftArray.oneTimeDonationAmounts.length > 0
      ? FREQUENCY.ONE_TIME
      : giftArray.monthlyDonationAmounts.length > 0
      ? FREQUENCY.MONTHLY
      : FREQUENCY.ANNUAL;

  // If there's a query param mail code, send through as the form's mail code
  if (paramsValues.mailCodeName) {
    primaryMailCodeName = paramsValues.mailCodeName;
  }

  const initialValues = {
    ...form,
    ...paramsValues,
    donationName,
    giftFrequency: defaultGiftFrequency,
    // If a default mail code is set we don't want the mail code drop down to have an initial value.
    // This is why it's initialized to an empty string in those cases.
    mailCodeName:
      primaryMailCodeName === DefaultMailCodes.UsDefault ||
      primaryMailCodeName === DefaultMailCodes.UkDefault
        ? ''
        : primaryMailCodeName,
    // We initialize this to false so that we don't get an undefined error in
    // our Yup validation logic
    hideExtraBillingFields: false,
  };

  const appTheme: DonationTheme = theme ? theme[templateTheme] : '';

  const handleSubmitPayment = (options: OptionsPartial, formikValues?) => {
    submitPayment({
      history,
      match,
      options,
      googleRecaptchaToken,
      formikValues,
    });
  };

  const initializeHostedBraintreeFields = async () => {
    const braintreeSubmitButton = braintreeButtonRef.current;
    if (!braintreeSubmitButton) {
      return;
    }

    const braintreeService = await BraintreeService.init();
    const hostedFieldsInstance: HostedFields = await braintreeService.createHostedFields();
    setHostedFields(hostedFieldsInstance);
  };

  const handleBraintreeButtonClick = async values => {
    const braintreeSubmitButton = braintreeButtonRef.current;
    if (!braintreeSubmitButton) {
      return;
    }

    hostedFields?.tokenize(
      { cardholderName: 'example name' },
      (tokenizeError, payload) => {
        if (tokenizeError) {
          dispatch(PaymentActions.setTokenizeError(tokenizeError));
          return;
        }

        // nonce acquired
        // @ts-ignore
        document.querySelector(
          'input[name="payment-method-nonce"]'
          // @ts-ignore
        ).value = payload.nonce;
        handleSubmitPayment({ isBraintree: true, payload }, values);
      }
    );
  };

  const handlePaypalButtonClick = async () => {
    setIsButtonActive(false);
    // Because tokenization opens a popup, this has to be called as a result of
    // customer action, like clicking a button—you cannot call this at any time.
    const braintreeService = await BraintreeService.init();
    try {
      const payload = await braintreeService.paypalTokenize();
      // Got a nonce! Submit this to your server.
      dispatch(
        PaymentActions.setPaypalPaymentData({ isBraintree: true, payload })
      );
      setIsButtonActive(true);
    } catch (error) {
      if (error.code === 'PAYPAL_POPUP_CLOSED') {
        // User has cancelled paypal login, switch back to credit card
        dispatch(PaymentActions.setPaymentMethod(PaymentMethod.CREDIT_CARD));
        setIsButtonActive(true);
      } else if (error.code === 'PAYPAL_TOKENIZATION_REQUEST_ACTIVE') {
        // User has clicked paypal again before closing tokenization popup
        braintreeService.focusPaypalWindow();
      } else {
        dispatch(PaymentActions.setError(error.message));
        setIsButtonActive(true);
      }
    }
  };

  useEffect(() => {
    setCombinedValidationSchema(getCombinedValidationSchema);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={combinedValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        switch (donationSubmitButton) {
          case PaymentMethod.CREDIT_CARD:
            handleBraintreeButtonClick(values);

            break;
          case PaymentMethod.BANK_ACCOUNT: {
            handleSubmitPayment(
              {
                isAch: true,
                metadata: plaidPaymentData.metadata,
                public_token: plaidPaymentData.public_token,
              },
              values
            );

            break;
          }
          case PaymentMethod.PAY_PAL: {
            handleSubmitPayment(
              {
                isBraintree: true,
                payload: paypalPaymentData.payload,
              },
              values
            );
            break;
          }
          case PaymentMethod.GOOGLE_PAY: {
            handleSubmitPayment(
              {
                isBraintree: true,
                payload: googlePaymentData,
              },
              values
            );
            break;
          }
          default:
            break;
        }
        setSubmitting(false);
      }}
    >
      {scrollToError(
        ({
          values,
          errors,
          handleSubmit,
          resetForm,
          setFieldValue,
          validateForm,
        }) => {
          return (
            <div
              style={{
                filter: showUpsell ? 'blur(3px)' : 'blur(0)',
                ...appTheme.page,
              }}
            >
              <Meta title="Donation" />
              <form onSubmit={handleSubmit}>
                {templateTheme !== APP_THEME.FIFTYFIFTY && (
                  <Header
                    headerStyle={appTheme.header}
                    logoStyle={appTheme.logoImage}
                    logoHref={
                      isUkUrl
                        ? 'https://www.smiletrain.org.uk'
                        : 'https://www.smileTrain.org'
                    }
                  />
                )}
                <BannerAndTopText
                  logoHref={
                    isUkUrl
                      ? 'https://www.smiletrain.org.uk'
                      : 'https://www.smileTrain.org'
                  }
                  text={DTdescription}
                />
                <div style={appTheme.mainContent}>
                  <HomePageContent homePageStyle={appTheme.homePage} />
                  <DonateForm
                    theme={appTheme}
                    values={values}
                    errors={errors}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    showUpsell={showUpsell}
                    setShowUpsell={setShowUpsell}
                    donationLevelParam={paramsValues?.donationLevel}
                    donationAmountParam={paramsValues?.donationAmount}
                    mailCodeNameParam={paramsValues?.mailCodeName}
                    giftFrequencyParam={paramsValues?.giftFrequency}
                  />
                  <ContactInfoForm
                    theme={appTheme}
                    values={values}
                    errors={errors}
                  />
                  <HonoraryForm
                    theme={appTheme}
                    values={values}
                    setFieldValue={setFieldValue}
                    paramsValues={paramsValues}
                  />
                  {!hideLegacyDonationCheckbox && (
                    <LegacyDonationForm
                      theme={appTheme}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  <PaymentFormContainer
                    theme={appTheme}
                    values={values}
                    initializeHostedBraintreeFields={
                      initializeHostedBraintreeFields
                    }
                    handlePaypalButtonClick={handlePaypalButtonClick}
                    setDonationSubmitButton={setDonationSubmitButton}
                    setFieldValue={setFieldValue}
                    validateForm={validateForm}
                  />
                  <AddressForm
                    theme={appTheme}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <PaymentButton
                  values={values}
                  errors={errors}
                  theme={appTheme}
                  braintreeButtonRef={braintreeButtonRef}
                  setDonationSubmitButton={setDonationSubmitButton}
                  isButtonActive={isButtonActive}
                  setGoogleRecaptchaToken={setGoogleRecaptchaToken}
                />
                {isPaymentProcessing && !hasError && (
                  <PaymentProcessingModal
                    heading={donationFormMainContent.paymentProcessingHeader}
                    subheading={
                      donationFormMainContent.paymentProcessingSubheader
                    }
                  />
                )}
                {!hasError && isThreeDSecureProcessing && (
                  <PaymentProcessingModal
                    heading={
                      donationFormMainContent.threeDSPaymentProcessingHeader
                    }
                    subheading={
                      donationFormMainContent.threeDSPaymentProcessingSubheader
                    }
                  />
                )}
              </form>
              <BottomFormImageGroup />
            </div>
          );
        }
      )}
    </Formik>
  );
};

export default HomeSinglePage;
