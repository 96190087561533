import React from 'react';
import { Field } from 'formik';

import SectionHeader from 'components/SectionHeader/SectionHeader';
import {
  FormSection,
  TextInputFormik,
  SelectInputFormik,
} from 'components/FormControls';
import { useTypedSelector } from 'shared/utils';
import { US_COUNTRY_CODE, CANADA_COUNTRY_CODE } from 'constants/constants';
import { DonationTheme } from 'themes';

interface Props {
  theme: DonationTheme;
  values: any;
}

export default function BillingInformation({ theme, values }: Props) {
  const {
    states,
    canadianProvinces,
    countries,
    billingAddress,
    fields,
    donationAddressFormContent,
    donationPaymentFormContent,
  } = useTypedSelector(
    ({
      donationTemplateSchema: {
        donationTemplateSchema: {
          states,
          canadianProvinces,
          countries,
          donationTemplate: {
            billingAddress,
            personalInformation: { fields },
            donationFormMainContent: {
              donationAddressFormContent,
              donationPaymentFormContent,
            },
          },
        },
      },
    }) => ({
      states,
      canadianProvinces,
      countries,
      billingAddress,
      fields,
      donationAddressFormContent,
      donationPaymentFormContent,
    })
  );
  return (
    <>
      <SectionHeader
        name="Billing Information"
        sectionHeaderStyle={theme.sectionHeader}
      />
      <FormSection>
        <div className="same-line-input">
          {fields.includes('Phone_Number') && (
            <Field
              name="billingPhone"
              label={donationPaymentFormContent.phoneNumberLabel}
              placeholder="XXX-XXX-XXXX"
              type="tel"
              textInputStyle={theme.textInput}
              component={TextInputFormik}
            />
          )}
          {fields.includes('Phone_Type') && (
            <Field
              name="billingPhoneType"
              label={donationPaymentFormContent.phoneTypeLabel}
              options={[
                {
                  phone_type_id: 'Cell',
                  phone_type: donationPaymentFormContent.cellPhoneType,
                },
                {
                  phone_type_id: 'Home',
                  phone_type: donationPaymentFormContent.homePhoneType,
                },
                {
                  phone_type_id: 'Work',
                  phone_type: donationPaymentFormContent.workPhoneType,
                },
              ]}
              component={SelectInputFormik}
              selectInputStyle={theme.textInput}
            />
          )}
        </div>
        <div className="same-line-input">
          {billingAddress.includes('Street Address') && (
            <Field
              name="billingAddress1"
              label={donationAddressFormContent.addressOneInput}
              required
              textInputStyle={theme.textInput}
              component={TextInputFormik}
            />
          )}
          {billingAddress.includes('Address 2') && (
            <Field
              name="billingAddress2"
              label={donationAddressFormContent.addressTwoInput}
              textInputStyle={theme.textInput}
              component={TextInputFormik}
            />
          )}
        </div>
        <div className="same-line-input">
          {billingAddress.includes('City') && (
            <Field
              name="billingCity"
              label={donationAddressFormContent.cityInputLabel}
              required
              textInputStyle={theme.textInput}
              component={TextInputFormik}
            />
          )}
          {billingAddress.includes('Country') && (
            <Field
              name="billingCountry"
              label={donationAddressFormContent.countryInputLabel}
              options={countries}
              component={SelectInputFormik}
              required
              selectInputStyle={theme.textInput}
            />
          )}
        </div>
        <div className="same-line-input">
          {values.billingCountry === US_COUNTRY_CODE &&
            billingAddress.includes('State') && (
              <Field
                name="billingState"
                label={donationAddressFormContent.stateInputLabel}
                options={states}
                selectInputStyle={theme.textInput}
                component={SelectInputFormik}
                required={true}
              />
            )}
          {values.billingCountry === CANADA_COUNTRY_CODE &&
            billingAddress.includes('State') && (
              <Field
                name="billingState"
                label={donationAddressFormContent.provinceInputLabel}
                options={canadianProvinces}
                component={SelectInputFormik}
                required={true}
              />
            )}
          {billingAddress.includes('Zip Code') && (
            <Field
              name="billingZipCode"
              label={donationAddressFormContent.postalCodeLabel}
              required
              textInputStyle={theme.textInput}
              component={TextInputFormik}
            />
          )}
        </div>
      </FormSection>
    </>
  );
}
