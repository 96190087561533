import * as Yup from 'yup';
import { US_COUNTRY_CODE, CANADA_COUNTRY_CODE } from 'constants/constants';
import { store } from '../../index';
import { CMS_TEMPLATE_REGEX } from '@smile-train/common';

const phoneNumberRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

/*
 * ADDRESS FORM
 * donationTemplate.billingAddressJson
 */
const validationSchema = () => {
  const {
    donationTemplateSchema: {
      donationTemplateSchema: {
        donationTemplate: {
          donationFormValidationContent,
          donationFormMainContent: { donationAddressFormContent },
        },
      },
    },
  } = store.getState();

  return {
    billingPhone: Yup.string().matches(
      phoneNumberRegex,
      donationFormValidationContent.invalidPhoneNumberText
    ),
    billingPhoneType: Yup.string().when('billingPhone', {
      is: value => Boolean(value),
      then: Yup.string().required(
        donationFormValidationContent.phoneTypeValidation
      ),
    }),
    billingCountry: Yup.string().when('hideExtraBillingFields', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          donationAddressFormContent.countryInputLabel
        )
      ),
    }),
    billingZipCode: Yup.string().when('hideExtraBillingFields', {
      is: true,
      then: Yup.string().when('billingCountry', {
        is: US_COUNTRY_CODE,
        then: Yup.string()
          .matches(
            /^\d{5}(?:[-\s]\d{4})?$/,
            donationFormValidationContent.invalidZipCodeValidation.replace(
              CMS_TEMPLATE_REGEX,
              donationFormValidationContent.zipPostalCodeLabel
            )
          )
          .required(
            donationFormValidationContent.requiredValidationLabel.replace(
              CMS_TEMPLATE_REGEX,
              donationAddressFormContent.zipCodeLabel
            )
          ),
      }),
    }),
    billingAddress1: Yup.string().when('hideExtraBillingFields', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          donationAddressFormContent.addressOneInput
        )
      ),
    }),
    billingAddress2: Yup.string(),
    billingCity: Yup.string().when('hideExtraBillingFields', {
      is: true,
      then: Yup.string().required(
        donationFormValidationContent.requiredValidationLabel.replace(
          CMS_TEMPLATE_REGEX,
          donationAddressFormContent.cityInputLabel
        )
      ),
    }),
    billingState: Yup.string().when('hideExtraBillingFields', {
      is: true,
      then: Yup.string()
        .when('billingCountry', {
          is: US_COUNTRY_CODE,
          then: Yup.string().required(
            donationFormValidationContent.requiredValidationLabel.replace(
              CMS_TEMPLATE_REGEX,
              donationAddressFormContent.stateInputLabel
            )
          ),
        })
        .when('billingCountry', {
          is: CANADA_COUNTRY_CODE,
          then: Yup.string().required(
            donationFormValidationContent.requiredValidationLabel.replace(
              CMS_TEMPLATE_REGEX,
              donationAddressFormContent.provinceInputLabel
            )
          ),
        }),
    }),
    sendmeSmileTrainRelatedMail: Yup.boolean(),
  };
};

export default validationSchema;
