import React from 'react';
import logo from 'images/logo.svg';
import './Header.css';
import LanguageSelect from 'components/LanguageSelect/LanguageSelect';

interface Props {
  logoHref: string;
  headerStyle: React.CSSProperties;
  logoStyle: React.CSSProperties;
}

const Header = ({ logoHref, headerStyle, logoStyle }: Props) => {
  return (
    <header className="Header" style={headerStyle}>
      <div>
        <a href={logoHref}>
          <img
            src={logo}
            width={logoStyle.width}
            height={logoStyle.height}
            alt={'Smile Train logo'}
          />
        </a>
      </div>
      <LanguageSelect />
    </header>
  );
};

export default Header;
