import React from 'react';

export default function CreditCard(props) {
  const color = props.color || '#4c79bc';
  return (
    <svg viewBox="0 0 32 32" width="24" height="24">
      <g fill={color}>
        <path d="M32,11V6c0-1.105-0.895-2-2-2H2C0.895,4,0,4.895,0,6v5H32z" />
        <path
          fill={color}
          d="M0,15v11c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V15H0z M13,23H4v-2h9V23z M28,23h-4v-2h4V23z"
        />
      </g>
    </svg>
  );
}
