import getJsonFromUrlParams from 'helpers/getJsonFromUrlParams';

export default function setMailcodeCookie() {
  const query = window.location.search;
  if (query) {
    const params = getJsonFromUrlParams(query);
    let param = '';
    if (params.st_source) {
      param = params.st_source;
    } else if (params.s_src) {
      param = params.s_src;
    }
    if (param) {
      const cookieName = 'STAdSrc';
      const cookieExipirationDays = 7;
      const d = new Date();
      d.setTime(d.getTime() + cookieExipirationDays * 24 * 60 * 60 * 1000);
      const cookieExpiration = 'expires=' + d.toUTCString();
      document.cookie = `${cookieName}=${param};${cookieExpiration};path=/;domain=smiletrain.org;`;
    }
  }
}
