import React from 'react';

export default function Email(props) {
  const color = props.color || '#4c79bc';
  return (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <g
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeWidth="2"
        fill={color}
        stroke={color}
      >
        <path
          d="M17.694,21.965c-2.513.961-10.181,2.53-14.34-2.255C-.676,15.073,1.073,8.056,4.09,4.548c2.726-3.17,8.362-4.636,13.3-2.631a9.071,9.071,0,0,1,5.589,9.314c-.173,2.673-1,5.555-3.683,5.764S16.177,14.7,16,13.486a51.1,51.1,0,0,1,.477-6.39c-5.892-1.8-7.972,1.337-8.621,3.258s-.866,4.929,1.56,6.223S15.051,16.2,16,13.486"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          data-cap="butt"
          strokeLinecap="butt"
        />
      </g>
    </svg>
  );
}
