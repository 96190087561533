import React from 'react';
import { useTypedSelector } from 'shared/utils';
import { StyleSheet, css } from 'aphrodite';

const BottomFormImageGroup = () => {
  const {
    donationTemplateSchema: { bottomFormImageGroup },
  } = useTypedSelector(state => state.donationTemplateSchema);
  return (
    <div className={css(styles.container)}>
      {bottomFormImageGroup.bottomFormImages.map(
        ({ image, altText, imageLink, id }) => (
          <a
            key={id}
            href={imageLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={image.url} alt={altText} className={css(styles.image)} />
          </a>
        )
      )}
    </div>
  );
};

export default BottomFormImageGroup;

const styles = StyleSheet.create({
  container: {
    '@media (max-width: 600px)': {
      maxWidth: 300,
    },
    display: 'flex',
    flexFlow: 'row wrap',
    maxWidth: 600,
    margin: '48px auto 0',
    paddingBottom: 62,
  },
  image: {
    width: 145,
    height: 125,
    objectFit: 'contain',
  },
});
