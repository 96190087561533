import { AppThunk } from 'redux/store';
import { PaymentActions } from 'redux/dux/payment';
import { PaymentMethod } from 'constants/constants';
import { push } from 'connected-react-router';
import { GenericObject } from 'types/utils';
import { initDoubleTheDonation } from 'shared/utils';

export const closeErrorMessage = (
  match: GenericObject,
  history: GenericObject
): AppThunk => async (dispatch, getState) => {
  const {
    payment: { currentPaymentMethod, errorReturnRoute },
  } = getState();

  // TODO
  // this.paypalInstancePromise = null;

  dispatch(PaymentActions.closeErrorMessage());

  initDoubleTheDonation();

  if (currentPaymentMethod === PaymentMethod.CREDIT_CARD) {
    // TODO: why are we doing this?
    // await initBraintree();
  }

  if (errorReturnRoute) {
    dispatch(PaymentActions.setErrorReturnRoute(null));
    push(
      `/${match.params.template}${errorReturnRoute}` + history.location.search
    );
  }

  // Sets payment method back to credit card, ensuring the PayPal option will work after Plaid errors
  dispatch(PaymentActions.setPaymentMethod(PaymentMethod.CREDIT_CARD));

  // enable donate button
  const braintreeSubmitButton = document.querySelector('#braintreeSubmitBtn');
  if (braintreeSubmitButton) {
    braintreeSubmitButton.removeAttribute('disabled');
  }
};
