import React, { useRef, useEffect } from 'react';
import YouTube from 'react-youtube-embed';
import { StyleSheet, css } from 'aphrodite';

import { DonationTemplateSchema } from 'types/shared';
import { useTypedSelector } from 'shared/utils';
import './HomePageContent.css';

interface Props {
  homePageStyle: {
    descriptionText: React.CSSProperties;
    description2Text: React.CSSProperties;
    youtubeVideoContainer: React.CSSProperties;
    videoStyle: React.CSSProperties;
  };
}

const HomePageContent = ({ homePageStyle }: Props) => {
  const subheaderRef = useRef<HTMLDivElement | null>(null);
  const donationTemplateSchema: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  // Set an id on the elements in the subheader. Because we're using the
  // GraphCMS rich text fields for this, we receive html elements from our
  // query. We need to add ids so that VWO can translate this text for Spanish
  // users.
  useEffect(() => {
    const nodes = subheaderRef.current?.children;

    if (nodes) {
      Array.from(nodes).forEach((node, index) => {
        node.setAttribute('id', `home-page-subheader-${index}`);
      });
    }
  }, []);

  const {
    donationTemplate: {
      donationTemplateMobileImage,
      donationTemplateMobileImageAltText,
      DTdescription2,
      videoId,
      videoOptions,
    },
  } = donationTemplateSchema;

  return (
    <>
      <div style={homePageStyle.descriptionText}>
        {donationTemplateMobileImage && (
          <div className="container-pad mobile-image">
            <img
              src={donationTemplateMobileImage}
              alt={donationTemplateMobileImageAltText}
            />
          </div>
        )}
        {videoId && (
          <div
            className="container home-page-video"
            style={homePageStyle.youtubeVideoContainer}
          >
            <YouTube id={videoId} appendSrc={videoOptions} />
          </div>
        )}
        {DTdescription2 && (
          <div
            className={`container-pad ${css(styles.text)}`}
            dangerouslySetInnerHTML={{ __html: DTdescription2 }}
            style={homePageStyle.descriptionText}
            ref={subheaderRef}
          />
        )}
      </div>
    </>
  );
};

export default HomePageContent;

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    whiteSpace: 'pre-wrap',
  },
});
