const defaultBraintreeOptions = {
  styles: {
    input: {
      'font-size': '12px',
    },
    '::placeholder': {
      color: '#999',
    },
    'input.invalid': {
      color: 'red',
    },
    'input.valid': {
      color: 'green',
    },
  },
  fields: {
    number: {
      selector: '#card-number',
      placeholder: '•••• •••• •••• ••••',
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123',
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: 'MM / YYYY',
    },
  },
};

export default defaultBraintreeOptions;
