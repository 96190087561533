import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { StyleSheet, css } from 'aphrodite';
import GridLoader from 'react-spinners/GridLoader';

interface Props {
  heading: string;
  subheading: string;
}

export default function PaymentProcessingModal({ heading, subheading }: Props) {
  return (
    <Dialog isOpen={true} className={css(styles.dialog)}>
      <div className={css(styles.container)}>
        <h2 className={css(styles.heading)} id="processing-header">
          {heading}
        </h2>
        <p className={css(styles.subHeading)} id="processing-text">
          {subheading}
        </p>
        <div className={css(styles.loaderContainer)}>
          <GridLoader loading={true} color="#4b79bc" />
        </div>
      </div>
    </Dialog>
  );
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: '#efefef',
    maxWidth: 600,
    paddingBottom: 25,
    minWidth: 300,
    fontSize: 14,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#444',
  },
  heading: {
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '26px auto 14px',
  },
  subHeading: {
    '@media (max-width: 500px)': {
      padding: '0 10px',
    },
    padding: '0 75px 10px',
    textAlign: 'center',
  },
  loaderContainer: {
    display: 'grid',
    placeItems: 'center',
  },
});
