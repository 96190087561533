import React from 'react';

export default function Lock() {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <g fill="#fff">
        <path
          d="M19,8H18V6.01A5.951,5.951,0,0,0,12.113,0h-.164A5.957,5.957,0,0,0,6,5.892V8H5a3,3,0,0,0-3,3V21a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V11A3,3,0,0,0,19,8Zm-6,9.816V20H11V17.816a3,3,0,1,1,2,0ZM16,8H8V5.911A3.955,3.955,0,0,1,11.95,2h.139A3.951,3.951,0,0,1,16,6Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
