import React from 'react';
import { Link } from 'react-router-dom';
import Page from 'components/Page';
import { ROUTES } from 'constants/constants';
import Header from 'components/Header';

const FourOhFourPage = () => {
  return (
    <Page title="Page not found">
      <Header minimal />
      <div
        className="container-pad module tac"
        style={{ minHeight: 'calc(100vh - 90px)' }}
      >
        <h1>404</h1>
        <Link to={ROUTES.HOME} className="btn btn--solo">
          Take me Home
        </Link>
      </div>
    </Page>
  );
};

export default FourOhFourPage;
