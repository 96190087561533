import React, { useState, useRef, RefObject, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ReCAPTCHA from 'react-google-recaptcha';

import { PaymentMethod, IS_STAGING_OR_PRODUCTION } from 'constants/constants';
import { useTypedSelector } from 'shared/utils';
import { DonationTheme } from 'themes';
import { datadogLogs } from '@datadog/browser-logs';

interface Props {
  values: any;
  errors: any;
  theme: DonationTheme;
  braintreeButtonRef: React.RefObject<HTMLInputElement>;
  setDonationSubmitButton: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  isButtonActive: boolean;
  setGoogleRecaptchaToken: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentButton = ({
  values,
  errors,
  theme,
  braintreeButtonRef,
  setDonationSubmitButton,
  isButtonActive,
  setGoogleRecaptchaToken,
}: Props) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const recaptchaRef: RefObject<ReCAPTCHA> = useRef(null);
  const {
    currentPaymentMethod,
    isPaymentProcessing,
    hasError,
    donationFrequencyNames,
    donateButtonLabel,
  } = useTypedSelector(
    ({
      payment: { currentPaymentMethod, isPaymentProcessing, hasError },
      donationTemplateSchema: {
        donationTemplateSchema: {
          donationFrequencyNames,
          donationTemplate: {
            donationFormMainContent: { donateButtonLabel },
          },
        },
      },
    }) => ({
      currentPaymentMethod,
      isPaymentProcessing,
      hasError,
      donationFrequencyNames,
      donateButtonLabel,
    })
  );

  function handleGoogleRecaptcha() {
    if (!recaptchaRef) {
      throw new Error('No recaptcha element found');
    }

    const token = recaptchaRef.current?.getValue();

    if (!token) {
      throw new Error('No token returned by Google.');
    }

    setGoogleRecaptchaToken(token);
    setIsSubmitDisabled(false);
  }

  useEffect(() => {
    if (currentPaymentMethod === PaymentMethod.GOOGLE_PAY) {
      setIsSubmitDisabled(false);
    } else {
      recaptchaRef.current?.reset();
      setIsSubmitDisabled(true);
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    if (!hasError) {
      return;
    }

    recaptchaRef.current?.reset();
  }, [hasError]);

  const handleButtonClick = (paymentMethod: PaymentMethod) => {
    setDonationSubmitButton(paymentMethod);

    if (Object.keys(errors).length !== 0 && IS_STAGING_OR_PRODUCTION) {
      datadogLogs.setGlobalContextProperty('email', values.personalEmail);

      datadogLogs.logger.info('Form validation error', {
        isUk: values.isUk,
        paymentMethod: paymentMethod,
        message: 'Validation error.',
        error: JSON.stringify(errors, null, 2),
        environment: process.env.REACT_APP_NODE_ENV,
      });
    }
  };

  return (
    <div className={css(styles.container)}>
      {currentPaymentMethod !== PaymentMethod.GOOGLE_PAY && (
        <div className={css(styles.recaptchaContainer)}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GRECAPTCHA_SITEKEY_OVERRIDE ?? ''}
            onChange={handleGoogleRecaptcha}
            size="normal"
          />
        </div>
      )}
      {currentPaymentMethod === PaymentMethod.CREDIT_CARD && (
        <input
          type="submit"
          value={`${donateButtonLabel} ${
            donationFrequencyNames[values.giftFrequency]
          }`}
          className="btn"
          style={theme.footerButton}
          ref={braintreeButtonRef}
          onClick={() => handleButtonClick(PaymentMethod.CREDIT_CARD)}
          disabled={isPaymentProcessing || isSubmitDisabled || !isButtonActive}
          id="donate-button-credit-card"
        />
      )}
      {currentPaymentMethod === PaymentMethod.PAY_PAL && (
        <input
          type="submit"
          value={`${donateButtonLabel} ${
            donationFrequencyNames[values.giftFrequency]
          }`}
          className="btn"
          style={theme.footerButton}
          onClick={() => handleButtonClick(PaymentMethod.PAY_PAL)}
          disabled={isPaymentProcessing || isSubmitDisabled || !isButtonActive}
          id="donate-button-paypal"
        />
      )}
      {currentPaymentMethod === PaymentMethod.GOOGLE_PAY && (
        <input
          type="submit"
          value={`${donateButtonLabel} ${
            donationFrequencyNames[values.giftFrequency]
          }`}
          className="btn"
          style={theme.footerButton}
          onClick={() => handleButtonClick(PaymentMethod.GOOGLE_PAY)}
          disabled={isPaymentProcessing || isSubmitDisabled || !isButtonActive}
        />
      )}
      {currentPaymentMethod === PaymentMethod.BANK_ACCOUNT && (
        <input
          type="submit"
          value={`${donateButtonLabel} ${
            donationFrequencyNames[values.giftFrequency]
          }`}
          className="btn"
          style={theme.footerButton}
          onClick={() => handleButtonClick(PaymentMethod.BANK_ACCOUNT)}
          disabled={isPaymentProcessing || isSubmitDisabled || !isButtonActive}
          id="donate-button-ach"
        />
      )}
    </div>
  );
};

export default PaymentButton;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: 600,
    '@media (max-width: 600px)': {
      width: 300,
    },
  },
  recaptchaContainer: {
    display: 'grid',
    placeItems: 'center',
  },
});
