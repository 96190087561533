/* eslint-disable import/first */
// map icon name to component
const icons = {};

// import all icon components
import Bank from './Bank';
icons.Bank = Bank;
import Check from './Check';
icons.Check = Check;
import ChevronLeft from './ChevronLeft';
icons.ChevronLeft = ChevronLeft;
import Circle from './Circle';
icons.Circle = Circle;
import Close from './Close';
icons.Close = Close;
import CreditCard from './CreditCard';
icons.CreditCard = CreditCard;
import Email from './Email';
icons.Email = Email;
import Facebook from './Facebook';
icons.Facebook = Facebook;
import Lock from './Lock';
icons.Lock = Lock;
import Mail from './Mail';
icons.Mail = Mail;
import PayPal from './PayPal';
icons.PayPal = PayPal;
import Smile from './Smile';
icons.Smile = Smile;
import Twitter from './Twitter';
icons.Twitter = Twitter;
import LangIcon from './LangIcon';
icons.LangIcon = LangIcon;

export default icons;
