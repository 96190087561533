import { getIn } from 'formik';

const submitCounts = new WeakMap();

function queryErrorElement(errors) {
  // Find first element which id or name matches error
  // Use Array.filter instead of Array.find for IE support
  const elements = Array.from(
    document.querySelectorAll('form [id], form [name]')
  ).filter(
    el =>
      (el.id &&
        (getIn(errors, el.id) || getIn(errors, el.id.replace('_', '.')))) ||
      (el['name'] && getIn(errors, el['name']))
  );
  return elements.length ? elements[0] : null;
}

function scrollToElement(el) {
  el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  if (el['focus']) {
    setTimeout(() => {
      el.focus();
    }, 250); // Focus only after 250ms when scroll animation is finished
  }
}

// Higher-order wrapper for component inside Formik
// Adapted from https://gist.github.com/osv/691efa00784def6f6004d31cd6108f56
const scrollToError = formComponent => {
  return props => {
    const { errors, isSubmitting, isValidating, submitCount } = props;

    let prevSubmitCount = submitCounts.get(formComponent) || 0;
    if (isSubmitting && !isValidating) {
      if (Object.keys(errors).length && submitCount > prevSubmitCount) {
        setTimeout(() => {
          const errorElement = queryErrorElement(errors);
          if (errorElement) {
            scrollToElement(errorElement);
          }
        }, 100); // Ensure everything is rendered to make sure animation will be smooth
      }
      submitCounts.set(formComponent, submitCount);
    }
    return formComponent(props);
  };
};

export default scrollToError;
