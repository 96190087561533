export const APP_NAME = 'Smile Train';
export const DD_360MATCH_PUBLIC_KEY = 'YzcwZTQwNDItNzcx';
export const US_COUNTRY_CODE = '225';
export const UK_COUNTRY_CODE = '224';
export const CANADA_COUNTRY_CODE = '39';
export const IS_STAGING_OR_PRODUCTION =
  process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test';

// see: https://stackoverflow.com/a/51885364/4552841
// slight modification made to allow for lower-case letters
export const UK_POSTAL_CODE_REGEX = /^([A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}|GIR ?0A{2})$/;
export const US_POSTAL_CODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;

export const APP_THEME = {
  OFFSET: 'offset',
  CENTERED: 'centered',
  FIFTYFIFTY: 'fiftyfifty',
};

export const ROUTES = {
  TEMPLATED_HOME: '/:template',
  HOME: '/donation/donate',
  DONATE: '/donate',
  CONTACT_INFO: '/contact-info',
  HONORARY: '/honorary',
  ADDRESS: '/address',
  PAYMENT: '/payment',
  CONFIRMATION: '/confirmation',
};

export const getCheckoutPages = showHonorary => {
  return [
    {
      show: showHonorary,
      label: 'Honorary',
      pathname: ROUTES.HONORARY,
    },
    {
      show: true,
      label: 'Contact Info',
      pathname: ROUTES.CONTACT_INFO,
    },
    {
      show: true,
      label: 'Address',
      pathname: ROUTES.ADDRESS,
    },
    {
      show: true,
      label: 'Payment',
      pathname: ROUTES.PAYMENT,
    },
    {
      show: true,
      label: 'Thank You!',
      pathname: ROUTES.CONFIRMATION,
    },
  ];
};

export const FREQUENCY = {
  MONTHLY: 'isMonthly',
  ANNUAL: 'isAnnual',
  ONE_TIME: 'isOneTime',
};

export enum DefaultMailCodes {
  UkDefault = 'UKBANNERS_AUTO',
  UsDefault = 'BANNERS_AUTO',
}

export enum PaymentMethod {
  CREDIT_CARD = 'creditCard',
  BANK_ACCOUNT = 'bankAccount',
  PAY_PAL = 'payPal',
  GOOGLE_PAY = 'AndroidPayCard',
  APPLE_PAY = 'ApplePayCard',
}

export const forwardQueryStringParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_referrer',
  { qs: 'st_source', output: 'mailcodeSrc' }, // st_source first so it takes precedence over s_src
  { qs: 's_src', output: 'mailcodeSrc' },
];

export enum ThreeDSecureVerificationFailureStatus {
  AuthenticateFrictionlessFailed = 'authenticate_frictionless_failed',
  AuthenticateRejected = 'authenticate_rejected',
  LookupError = 'lookup_error',
  LookupFailedAcsError = 'lookup_failed_acs_error',
}

export const LANGUAGE_OPTIONS = {
  English: 'en',
  Spanish: 'es',
  French: 'fr_CA',
  Portuguese: 'pt',
};

export enum StoredCookies {
  Locale = 'currentLocale',
}
