import React, { useEffect } from 'react';
import { Field } from 'formik';
import {
  FormSection,
  ToggleInputFormik,
  TextInputFormik,
  SelectInputFormik,
} from 'components/FormControls';
import { DonationTheme } from 'themes';
import { StyleSheet, css } from 'aphrodite';
import { useTypedSelector, getTitleInputOptions } from 'shared/utils';
import { US_COUNTRY_CODE, CANADA_COUNTRY_CODE } from 'constants/constants';

interface Props {
  theme: DonationTheme;
  values: any;
  setFieldValue: (
    field: string,
    newValue: any,
    shouldValidate?: boolean
  ) => void;
}

const LegacyDonationForm = ({ theme, values, setFieldValue }: Props) => {
  const {
    countries,
    states,
    canadianProvinces,
    defaultCountry,
    giftAid,
    giftRecipientTitles,
    donationAddressFormContent,
    recipientTitleLabel,
    legacyDonationCheckboxLabel,
    legacyDonationCopy,
    legacyDonationDescription,
    firstNameFormLabel,
    lastNameFormLabel,
  } = useTypedSelector(
    ({
      payment: { giftAid },
      donationTemplateSchema: {
        donationTemplateSchema: {
          states,
          canadianProvinces,
          countries,
          defaultCountry,
          donationTemplate: {
            honoreeInformation: { giftRecipientTitles },
            donationFormMainContent: {
              donationAddressFormContent,
              honoraryGiftFormContent: { recipientTitleLabel },
              legacyDonationCheckboxLabel,
              legacyDonationCopy,
              legacyDonationDescription,
              firstNameFormLabel,
              lastNameFormLabel,
            },
          },
        },
      },
    }) => ({
      countries,
      states,
      canadianProvinces,
      defaultCountry,
      giftAid,
      giftRecipientTitles,
      donationAddressFormContent,
      recipientTitleLabel,
      legacyDonationCheckboxLabel,
      legacyDonationCopy,
      legacyDonationDescription,
      firstNameFormLabel,
      lastNameFormLabel,
    })
  );

  useEffect(() => {
    setFieldValue('legacyCountry', defaultCountry.toString());
  }, [setFieldValue, defaultCountry]);

  const titleInputOptions = getTitleInputOptions(giftRecipientTitles);

  return (
    <>
      {!giftAid && (
        <div className="container module">
          <FormSection>
            <Field
              name="isLegacyDonation"
              label={legacyDonationCheckboxLabel}
              checkboxStyle={theme.checkBox}
              component={ToggleInputFormik}
            />
          </FormSection>
          {values.isLegacyDonation && (
            <FormSection>
              <p className={css(styles.text)}>{legacyDonationDescription}</p>
              <div className="same-line-input">
                <Field
                  name="legacyFirstName"
                  label={firstNameFormLabel}
                  component={TextInputFormik}
                  placeholder=""
                  textInputStyle={theme.textInput}
                  required
                />
                <Field
                  name="legacyLastName"
                  label={lastNameFormLabel}
                  component={TextInputFormik}
                  placeholder=""
                  textInputStyle={theme.textInput}
                  required
                />
              </div>
              <Field
                name="legacyTitle"
                label={recipientTitleLabel}
                options={titleInputOptions}
                component={SelectInputFormik}
                required
                selectInputStyle={theme.textInput}
              />
              <Field
                name="legacyCountry"
                label={donationAddressFormContent.countryInputLabel}
                required
                options={countries}
                selectInputStyle={theme.textInput}
                component={SelectInputFormik}
              />
              <Field
                name="legacyAddress1"
                label={donationAddressFormContent.addressOneInput}
                required
                textInputStyle={theme.textInput}
                component={TextInputFormik}
              />
              <Field
                name="legacyAddress2"
                label={donationAddressFormContent.addressTwoInput}
                textInputStyle={theme.textInput}
                component={TextInputFormik}
              />
              <Field
                name="legacyCity"
                label={donationAddressFormContent.cityInputLabel}
                required
                textInputStyle={theme.textInput}
                component={TextInputFormik}
              />
              <Field
                name="legacyPostalCode"
                label={donationAddressFormContent.postalCodeLabel}
                required
                textInputStyle={theme.textInput}
                component={TextInputFormik}
              />
              {values.legacyCountry === US_COUNTRY_CODE && (
                <Field
                  name="legacyState"
                  label={donationAddressFormContent.stateInputLabel}
                  options={states}
                  selectInputStyle={theme.textInput}
                  component={SelectInputFormik}
                  required
                />
              )}
              {values.legacyCountry === CANADA_COUNTRY_CODE && (
                <Field
                  name="legacyState"
                  label={donationAddressFormContent.provinceInputLabel}
                  options={canadianProvinces}
                  selectInputStyle={theme.textInput}
                  component={SelectInputFormik}
                  required
                />
              )}
              <p className={css(styles.text)}>{legacyDonationCopy}</p>
            </FormSection>
          )}
        </div>
      )}
    </>
  );
};

export default LegacyDonationForm;

const styles = StyleSheet.create({
  text: {
    fontSize: 13,
    color: '#777',
  },
});
