import Icon from 'components/Icon/Icon';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { StyleSheet, css } from 'aphrodite';
import { StoredCookies } from 'constants/constants';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { LanguageStateActions } from 'redux/dux/currentLanguage';
import { getLocaleLabel, useTypedSelector } from 'shared/utils';

export default function LanguageSelect() {
  const dispatch = useDispatch();
  const {
    currentLocale,
    templateLanguage,
    supportedLanguages,
  } = useTypedSelector(
    ({
      currentLanguage: { currentLocale },
      donationTemplateSchema: {
        donationTemplateSchema: { templateLanguage, supportedLanguages },
      },
    }) => ({
      currentLocale,
      templateLanguage,
      supportedLanguages,
    })
  );
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    Cookies.set(StoredCookies.Locale, templateLanguage);
    dispatch(LanguageStateActions.setCurrentLocale(templateLanguage));
  }, [templateLanguage]);

  const LanguageOptionButton = () => {
    return (
      <button type="button" className={css(styles.iconButton)}>
        <Icon name="LangIcon" />
      </button>
    );
  };
  const onClickLanguageOption = (newLocale: string) => {
    Cookies.set(StoredCookies.Locale, newLocale);
    dispatch(LanguageStateActions.setCurrentLocale(newLocale));
    setOpen(!open);
  };

  return (
    <Popup
      trigger={LanguageOptionButton}
      onOpen={() => setOpen(true)}
      open={open}
      position="bottom right"
      arrow
      {...{ contentStyle, arrowStyle }}
    >
      <div>
        <p className={css(styles.popUpHeader)}>Select Site Language:</p>
        {supportedLanguages.map((locale, index) => {
          return (
            <button
              className={css(styles.popUpButton)}
              key={index}
              disabled={locale === currentLocale}
              onClick={() => {
                onClickLanguageOption(locale);
              }}
            >
              <p className={css(styles.buttonText)}>{getLocaleLabel(locale)}</p>
            </button>
          );
        })}
      </div>
    </Popup>
  );
}

//component style for language options
const contentStyle = {
  background: 'white',
  border: `solid 1px #b0b0b0`,
  borderRadius: '2px',
  width: '160px',
};

const arrowStyle = {
  stroke: '#b0b0b0',
  strokeWidth: '2px',
  strokeDasharray: '30px',
  strokeDashoffset: '-54px',
};

const styles = StyleSheet.create({
  iconButton: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
  },
  popUpHeader: {
    fontSize: 10,
    color: '#484848',
    margin: '10px 0px 10px 13px',
  },
  popUpButton: {
    cursor: 'pointer',
    background: 'white',
    textDecoration: 'none',
    width: '100%',
    height: '32px',
    justifyContent: 'flex-start',
    borderRadius: 0,
    borderTop: `solid 1px #b0b0b0`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    ':disabled': {
      background: '#daedff',
    },
    ':hover': {
      background: '#daedff',
      opacity: 1,
    },
  },
  buttonText: {
    fontSize: 12,
    color: '#292929',
    textAlign: 'start',
    margin: '5px 0 0 8px',
  },
});
