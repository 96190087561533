import React, { Fragment, useEffect, useState } from 'react';
import { Field } from 'formik';
import Media from 'react-media';

import {
  FormSection,
  InputGroup,
  TextInputFormik,
  SmallRadioButtonInputFormik,
  RadioImageInputFormik,
  ToggleInputFormik,
  SelectInputFormik,
  RadioButtonGroup,
} from 'components/FormControls/FormControls';
import { US_COUNTRY_CODE, CANADA_COUNTRY_CODE } from 'constants/constants';
import {
  useTypedSelector,
  gtmEventSetHonoraryDeliveryMethod,
  gtmEventSetHonoraryCheckboxSelected,
  getTitleInputOptions,
} from 'shared/utils';
import Grid from 'components/Grid';
import { DonationTemplateSchema } from 'types/shared';
import { DonationTheme } from 'themes';
import { ParamsValues } from 'helpers/utils';
import { StyleSheet, css } from 'aphrodite';

interface Props {
  theme: DonationTheme;
  values: any;
  paramsValues: ParamsValues;
  setFieldValue: (
    field: string,
    newValue: any,
    shouldValidate?: boolean
  ) => void;
}

const renderECardImages = images => {
  return images.map(({ image, imageAltText }, index) => {
    const imageUrl = image.url;
    return (
      <div
        key={imageUrl}
        className="module"
        style={{ maxWidth: '190px', margin: '0 auto' }}
      >
        <Field
          name="ecardImage"
          id={index.toString()}
          label={
            <img
              src={imageUrl}
              alt={imageAltText}
              style={{ pointerEvents: 'none' }}
            />
          }
          component={RadioImageInputFormik}
        />
      </div>
    );
  });
};

const HonoraryForm = ({
  theme,
  values,
  paramsValues,
  setFieldValue,
}: Props) => {
  const donationTemplateSchema: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  const [showHonorarySection, setShowHonorarySection] = useState(
    !!paramsValues.isHonoreeInfo
  );

  const {
    countries,
    states,
    canadianProvinces,
    defaultCountry,
    donationTemplate: {
      honoreeInformation: {
        honoreeInformation,
        giftTypeList,
        giftRecipientTitles,
        card: { showNoCard, showEmailedCard, showMailedCard },
        emailFields: { images },
        mailedCardFields: { toFields, showMailedCardFromField },
        showHonoraryGiftToggle,
      },
      donationFormMainContent: {
        honoraryGiftFormContent,
        donationAddressFormContent,
      },
    },
  } = donationTemplateSchema;

  useEffect(() => {
    setFieldValue('honoraryCountry', defaultCountry.toString());
  }, [setFieldValue, defaultCountry]);

  useEffect(() => {
    if (showHonorarySection) {
      gtmEventSetHonoraryDeliveryMethod(values.mailedOrEcard);
    }
  }, [showHonorarySection, values.mailedOrEcard]);

  useEffect(() => {
    if (showHonorarySection) {
      gtmEventSetHonoraryCheckboxSelected();
    }
  }, [showHonorarySection]);

  // Generate options for gift type select from CMS values
  const giftTypeListOptions = giftTypeList.map(giftType => {
    return { title_id: giftType, title_name: giftType };
  });

  const titleInputOptions = getTitleInputOptions(giftRecipientTitles);

  return (
    <div className="container module">
      {showHonoraryGiftToggle && (
        <FormSection>
          <Field
            name="isHonoreeInfo"
            label={honoraryGiftFormContent.honoraryGiftCheckbox}
            checkboxStyle={theme.checkBox}
            component={ToggleInputFormik}
            onClick={() => setShowHonorarySection(!showHonorarySection)}
          />
        </FormSection>
      )}
      {showHonorarySection && (
        <Fragment>
          <FormSection>
            <div className="same-line-input" style={{ height: 80 }}>
              {honoreeInformation.includes('Gift_Type') && (
                <Field
                  name="honoraryType"
                  label={honoraryGiftFormContent.giftTypeLabel}
                  options={giftTypeListOptions}
                  component={SelectInputFormik}
                  required
                  selectInputStyle={theme.textInput}
                />
              )}
              {honoreeInformation.includes('Title') && (
                <Field
                  name="honoraryTitle"
                  label={honoraryGiftFormContent.recipientTitleLabel}
                  options={titleInputOptions}
                  component={SelectInputFormik}
                  required
                  selectInputStyle={theme.textInput}
                />
              )}
            </div>
            <div className="same-line-input" style={{ height: 80 }}>
              {honoreeInformation.includes('First_Name') && (
                <Field
                  name="honoraryFirstName"
                  label={honoraryGiftFormContent.recipientFirstNameLabel}
                  placeholder=""
                  textInputStyle={theme.textInput}
                  required
                  component={TextInputFormik}
                />
              )}
              {honoreeInformation.includes('Last_Name') && (
                <Field
                  name="honoraryLastName"
                  label={honoraryGiftFormContent.recipientLastNameLabel}
                  placeholder=""
                  textInputStyle={theme.textInput}
                  required
                  component={TextInputFormik}
                />
              )}
            </div>
            <RadioButtonGroup name="mailedOrEcard">
              {showNoCard && (
                <Field
                  name="mailedOrEcard"
                  id="none"
                  label={honoraryGiftFormContent.noCardTextButton}
                  smallButtonStyle={theme.smallButtonGroup.firstButton}
                  component={SmallRadioButtonInputFormik}
                />
              )}
              {showEmailedCard && (
                <Field
                  name="mailedOrEcard"
                  id="eCard"
                  label={honoraryGiftFormContent.eCardEmailLabel}
                  smallButtonStyle={theme.smallButtonGroup.secondButton}
                  component={SmallRadioButtonInputFormik}
                />
              )}
              {showMailedCard && (
                <Field
                  name="mailedOrEcard"
                  id="mailed"
                  label={honoraryGiftFormContent.mailedCardTextButton}
                  smallButtonStyle={theme.smallButtonGroup.thirdButton}
                  component={SmallRadioButtonInputFormik}
                />
              )}
            </RadioButtonGroup>
          </FormSection>
          <FormSection>
            {values.mailedOrEcard === 'eCard' && (
              <Field
                name="eCardEmail"
                label={honoraryGiftFormContent.eCardEmailLabel}
                type="email"
                textInputStyle={theme.textInput}
                required
                component={TextInputFormik}
              />
            )}
          </FormSection>
          {values.mailedOrEcard === 'eCard' && (
            <Fragment>
              <FormSection>
                <InputGroup
                  label={honoraryGiftFormContent.eCardSelectLabel}
                  inputGroupStyle={theme.textInput.label}
                  name="Select an eCard"
                >
                  <Media query="(min-width: 600px)">
                    {matches =>
                      matches ? (
                        <Grid columns="3">{renderECardImages(images)}</Grid>
                      ) : (
                        <Grid columns="2">{renderECardImages(images)}</Grid>
                      )
                    }
                  </Media>
                </InputGroup>
              </FormSection>
              <FormSection>
                <Field
                  name="eCardSubjectLine"
                  label={honoraryGiftFormContent.emailSubjectLabel}
                  textInputStyle={theme.textInput}
                  required
                  component={TextInputFormik}
                />
                <Field
                  name="eCardEmailBody"
                  label={honoraryGiftFormContent.emailBodyLabel}
                  type="textarea"
                  textInputStyle={theme.textInput}
                  required
                  component={TextInputFormik}
                />
                <Field
                  name="sendCopyOfEmail"
                  label={honoraryGiftFormContent.sendEmailCopyCheckbox}
                  checkboxStyle={theme.checkBox}
                  component={ToggleInputFormik}
                />
              </FormSection>
            </Fragment>
          )}
          {values.mailedOrEcard === 'mailed' && (
            <Fragment>
              <FormSection>
                {toFields.includes('Country') && (
                  <Field
                    name="honoraryCountry"
                    label={donationAddressFormContent.countryInputLabel}
                    required
                    options={countries}
                    selectInputStyle={theme.textInput}
                    component={SelectInputFormik}
                  />
                )}
                {toFields.includes('Street_Address') && (
                  <Field
                    name="honoraryAddress1"
                    label={donationAddressFormContent.addressOneInput}
                    required
                    textInputStyle={theme.textInput}
                    component={TextInputFormik}
                  />
                )}
                {toFields.includes('Address_2') && (
                  <Field
                    name="honoraryAddress2"
                    label={donationAddressFormContent.addressTwoInput}
                    textInputStyle={theme.textInput}
                    component={TextInputFormik}
                  />
                )}
                {toFields.includes('City') && (
                  <Field
                    name="honoraryCity"
                    label={donationAddressFormContent.cityInputLabel}
                    required
                    textInputStyle={theme.textInput}
                    component={TextInputFormik}
                  />
                )}
                {toFields.includes('Zip_Code') && (
                  <Field
                    name="honoraryZipCode"
                    label={donationAddressFormContent.postalCodeLabel}
                    required
                    textInputStyle={theme.textInput}
                    component={TextInputFormik}
                  />
                )}
                {+values.honoraryCountry === +US_COUNTRY_CODE &&
                  toFields.includes('State_Province') && (
                    <Field
                      name="honoraryState"
                      label={donationAddressFormContent.stateInputLabel}
                      options={states}
                      selectInputStyle={theme.textInput}
                      component={SelectInputFormik}
                      required={true}
                    />
                  )}
                {+values.honoraryCountry === +CANADA_COUNTRY_CODE &&
                  toFields.includes('State_Province') && (
                    <Field
                      name="honoraryState"
                      label={donationAddressFormContent.provinceInputLabel}
                      options={canadianProvinces}
                      selectInputStyle={theme.textInput}
                      component={SelectInputFormik}
                      required={true}
                    />
                  )}
              </FormSection>
              {showMailedCardFromField && (
                <div className={css(styles.bottomSectionContainer)}>
                  <Field
                    name="mailedFromName"
                    label={honoraryGiftFormContent.mailCardSender}
                    textInputStyle={theme.textInput}
                    component={TextInputFormik}
                    required={true}
                  />
                  <Field
                    name="mailedToName"
                    label={honoraryGiftFormContent.mailCardRecipient}
                    textInputStyle={theme.textInput}
                    component={TextInputFormik}
                    required={true}
                  />
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default HonoraryForm;

const styles = StyleSheet.create({
  bottomSectionContainer: {
    marginBottom: 6,
  },
});
