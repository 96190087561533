import React, { Fragment } from 'react';
import { StoreState } from 'types/state';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { countries } from 'countries-list';
import {
  returnStateOrProvinceAbbreviation,
  countriesList,
  CMS_TEMPLATE_REGEX,
} from '@smile-train/common';
import { OptionsPartial } from 'redux/thunks/submitPayment';
import { CompletedPaymentTypes, PaymentTypes } from '@smile-train/common';
import { LANGUAGE_OPTIONS } from 'constants/constants';

export const useTypedSelector: TypedUseSelectorHook<StoreState> = useSelector;

export const initDoubleTheDonation = () => {
  // Make sure double the donation exists
  if (window.doublethedonation) {
    // Loads the search into a DOM element with id="dd-company-name-input"
    window.doublethedonation.plugin.load_streamlined_input();
  }
};

/**
 * Converts `\\n` characters into `<br/>`, allowing for line break control from
 * GraphCMS' rich text editor
 **/
export const interpretNewlineCharacters = (richText: string): JSX.Element[] => {
  const newString = richText.split('\\n').map((chunk, index) => {
    return (
      <Fragment key={index}>
        {chunk}
        <br />
      </Fragment>
    );
  });
  return newString;
};

/**
 * Checks if current url contains .uk
 *
 * @returns boolean
 */
export const checkWindowForUkUrl = () => {
  return window.location.href.includes('.uk');
};

/**
 * Pushes selected gift frequency into GTM data layer
 * @param giftFrequency string value for currently selected frequency, pulled from Formik's state
 */
export const gtmEventSetGiftFrequency = (
  giftFrequency: 'isOneTime' | 'isMonthly' | 'isAnnual'
): void => {
  //TODO refactor this and other events into GoogleTagManagerService (or some such)
  let frequency = '';
  if (giftFrequency === 'isOneTime') {
    frequency = 'One-Time';
  } else if (giftFrequency === 'isMonthly') {
    frequency = 'Monthly';
  } else if (giftFrequency === 'isAnnual') {
    frequency = 'Annual';
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'donationSelectedFrequency',
    eventCategory: 'Donation Form',
    eventAction: 'Selected Gift Frequency',
    eventLabel: `Frequency: ${frequency}`,
    eventValue: undefined,
    eventOrigin: 'front-end',
    eventVars: {
      donationFrequency: frequency,
    },
  });
};

/**
 * Pushes selected 'pre-selected' gift amount into GTM data layer.
 * A 'pre-selected' gift amount is one of the previously set amounts sent from GraphCMS.
 * @param donationAmount value of selected pre-set donation amount, pulled from Formik's state
 */
export const gtmEventSetPreselectedGiftAmount = (
  donationAmount: string
): void => {
  window.dataLayer = window.dataLayer || [];

  if (donationAmount !== '' && donationAmount !== 'OtherAmount') {
    window.dataLayer.push({
      event: 'donationSelectedAmount',
      eventCategory: 'Donation Form',
      eventAction: 'Selected Amount',
      eventLabel: `Preselected: ${donationAmount}`,
      eventValue: undefined,
      eventOrigin: 'front-end',
      eventVars: { donationPreselectedAmount: donationAmount },
    });
  }
};

/**
 * Pushes user entered gift amount into GTM data layer.
 * This represents the 'Other' amount option in the donation form.
 * @param donationAmount value of selected pre-set donation amount, pulled from Formik's state
 * @param otherAmount value of user entered 'Other' amount
 */
export const gtmEventSetUserEnteredGiftAmount = (
  donationAmount: string,
  otherAmount: string | number
) => {
  window.dataLayer = window.dataLayer || [];

  if (donationAmount === 'OtherAmount' && otherAmount !== '') {
    window.dataLayer.push({
      event: 'donationSelectedAmount',
      eventCategory: 'Donation Form',
      eventAction: 'Selected Amount',
      eventLabel: `Other: ${otherAmount}`,
      eventValue: undefined,
      eventOrigin: 'front-end',
      eventVars: { donationUserEnteredAmount: otherAmount.toString() },
    });
  }
};

/**
 * Pushes selected payment method into GTM data layer
 * @param payMethod string value of currently selected payment method
 */
export const gtmEventSetPaymentMethod = (
  currentPaymentMethod:
    | 'creditCard'
    | 'bankAccount'
    | 'payPal'
    | 'AndroidPayCard'
    | 'ApplePayCard'
): void => {
  let payMethod: string;
  if (currentPaymentMethod === 'creditCard') {
    payMethod = 'Credit/Debit Card';
  } else if (currentPaymentMethod === 'bankAccount') {
    payMethod = 'Bank Account';
  } else if (currentPaymentMethod === 'AndroidPayCard') {
    payMethod = 'Google Pay';
  } else if (currentPaymentMethod === 'ApplePayCard') {
    payMethod = 'Apple Pay';
  } else {
    payMethod = 'PayPal';
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'donationSelectedPaymentMethod',
    eventCategory: 'Donation Form',
    eventAction: 'Selected Payment Method',
    eventLabel: `Method Selected: ${payMethod}`,
    eventValue: undefined,
    eventOrigin: 'front-end',
    eventVars: {
      paymentMethodSelected: payMethod,
    },
  });
};

/**
 * Pushes 'eeGeneralDonationView' event into GTM data layer
 * @param domainUrl string value of current donation form's url
 */
export const gtmEventSetEeGeneralDonationView = (domainUrl: string): void => {
  window.dataLayer = window.dataLayer || [];

  // UA datalayer
  window.dataLayer.push({
    event: 'eeGeneralDonationView',
    ecommerce: {
      checkout: {
        products: [
          {
            name: domainUrl,
            brand: 'General',
            category: 'General',
          },
        ],
      },
    },
  });

  // GA4 datalayer
  window.dataLayer.push({
    event: 'eeGeneralDonationViewGA4',
    ecommerce: {
      checkout: {
        items: [
          {
            item_name: domainUrl,
            item_brand: 'General',
            item_category: 'General',
          },
        ],
      },
    },
  });

  // GTAG datalayer
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'begin_checkout', {
      items: [
        {
          item_name: domainUrl,
          item_brand: 'General',
          item_category: 'General',
        },
      ],
    });
  }
};

/**
 * Pushes 'True' into GTM data layer when the checkbox that controls rendering of the honorary section is selected
 */
export const gtmEventSetHonoraryCheckboxSelected = (): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'giftcheckboxSelected',
    eventCategory: 'Donation Form',
    eventAction: 'Selected Gift Checkbox',
    eventLabel: 'Not Set',
    eventValue: undefined,
    eventOrigin: 'front-end',
    eventVars: {
      donationIsGift: 'True',
    },
  });
};

/**
 * Pushes the selected gift delivery method into GTM data layer
 * @param deliveryMethod - The selected honorary gift card delivery method ('none', 'eCard', or 'mailed')
 */
export const gtmEventSetHonoraryDeliveryMethod = (
  deliveryMethod: 'none' | 'eCard' | 'mailed'
): void => {
  let method: string;
  if (deliveryMethod === 'none') {
    method = 'No Card';
  } else if (deliveryMethod === 'eCard') {
    method = 'Email';
  } else {
    method = 'Mailed Card';
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'giftDeliveryMethodSelected',
    eventCategory: 'Donation Form',
    eventAction: 'Selected Gift Delivery Method',
    eventLabel: `Gift Delivery: ${method}`,
    eventValue: undefined,
    eventOrigin: 'front-end',
    eventVars: {
      donationGiftDelivery: method,
    },
  });
};

/**
 * Pushes the donationCompleted event into the GTM data layer
 * @param donationFrequency string value of the donation's frequency.
 * @param formikDonationAmount string value of the donation amount. Holds the value 'OtherAmount' if the user selects a custom donation amount.
 * @param formikOtherAmount string value of the current custom donation amount. Empty string if the user selects a pre-defined donation amount.
 * @param paymentMethodCompleted string value of the successful payment method.
 */
export const gtmEventDonationCompleted = (
  donationFrequency: 'One-Time' | 'Monthly Recurring' | 'Annually Recurring',
  formikDonationAmount: string,
  formikOtherAmount: string,
  paymentMethodCompleted: CompletedPaymentTypes,

  transactionId: string,
  donationFormName: string,
  mailcode: string
) => {
  let frequency: 'One-Time' | 'Monthly' | 'Annual';
  let donationAmount: string;
  let otherAmount: string;
  let paymentMethod: PaymentTypes;

  if (donationFrequency === 'One-Time') {
    frequency = donationFrequency;
  } else if (donationFrequency === 'Monthly Recurring') {
    frequency = 'Monthly';
  } else {
    frequency = 'Annual';
  }

  if (formikOtherAmount === '' && formikDonationAmount !== 'OtherAmount') {
    // We're using pre selected donation amounts
    donationAmount = formikDonationAmount;
    otherAmount = '';
  } else {
    donationAmount = '';
    otherAmount = formikOtherAmount;
  }

  if (paymentMethodCompleted === 'Credit Card') {
    paymentMethod = 'Credit/Debit Card';
  } else if (paymentMethodCompleted === 'Bank Account') {
    paymentMethod = paymentMethodCompleted;
  } else {
    paymentMethod = paymentMethodCompleted;
  }

  window.dataLayer = window.dataLayer || [];

  const preselectedAmount = donationAmount !== '' ? donationAmount : '';
  const userEnteredAmount = otherAmount !== '' ? otherAmount : '';
  const isUserEnteredAmount = formikDonationAmount === 'OtherAmount';
  const eventVars = {
    donationFrequency: frequency,
    donationUserEnteredAmount: isUserEnteredAmount
      ? userEnteredAmount
      : preselectedAmount,
    paymentMethodCompleted: paymentMethod,
    transactionId,
    donationFormName,
    mailcode,
  };

  window.dataLayer.push({
    event: 'donationCompleted',
    eventCategory: 'Donation Form',
    eventAction: 'Completed Donation',
    eventLabel: isUserEnteredAmount
      ? `Frequency: ${frequency} + Other: ${userEnteredAmount} + Payment Method Completed: ${paymentMethod}`
      : `Frequency: ${frequency} + Preselected: ${preselectedAmount} + Payment Method Completed: ${paymentMethod}`,

    eventValue: undefined,
    eventOrigin: 'front-end',
    eventVars: eventVars,
  });
};

/**
 * Pushes the eeGeneralDonationComplete event into the GTM data layer
 * @param transactionId string value of Stripe or Braintree transaction id`
 * @param donationAmount number value of donation
 * @param isPreDefinedAmount boolean value indicating whether a pre-defined or custom donation amount was used
 * @param mailCode string value of the form's SF mail code
 * @param domainUrl string value of the form's url slug
 * @param giftType string value of the donation's gift type
 * @param giftFrequency string value of the donation's frequency
 */
export const gtmEventSetEeGeneralDonationComplete = (
  transactionId: string,
  donationAmount: number,
  isPreDefinedAmount: boolean,
  mailCode: string,
  domainUrl: string,
  giftType: string,
  giftFrequency: 'One-Time' | 'Monthly Recurring' | 'Annually Recurring'
): void => {
  window.dataLayer = window.dataLayer || [];

  let category: string;
  if (giftType === 'In Honor Of') {
    category = 'General/In Honor Of';
  } else if (giftType === 'In Memory Of') {
    category = 'General/In Memory Of';
  } else if (giftType === 'Holiday') {
    category = 'General/Holiday';
  } else {
    category = 'General';
  }

  let variant: string;
  if (giftFrequency === 'One-Time') {
    variant = 'One-time';
  } else if (giftFrequency === 'Monthly Recurring') {
    variant = 'Monthly';
  } else {
    variant = 'Annually';
  }

  let id: string;
  if (isPreDefinedAmount) {
    id = donationAmount.toString();
  } else {
    id = 'Other';
  }

  // UA datalayer
  window.dataLayer.push({
    event: 'eeGeneralDonationComplete',
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          revenue: donationAmount,
          coupon: mailCode,
          action: 'purchase',
        },
        products: [
          {
            name: domainUrl,
            id,
            price: donationAmount,
            brand: 'General',
            category,
            variant,
            coupon: mailCode,
            quantity: 1,
          },
        ],
      },
    },
  });

  // GA4 datalayer
  window.dataLayer.push({
    event: 'eeGeneralDonationCompleteGA4',
    ecommerce: {
      purchase: {
        actionField: {
          transaction_id: transactionId,
          value: donationAmount,
          coupon: mailCode,
          action: 'purchase',
        },
        items: [
          {
            item_name: domainUrl,
            item_id: id,
            price: donationAmount,
            item_brand: 'General',
            item_category: category,
            item_variant: variant,
            coupon: mailCode,
            quantity: 1,
          },
        ],
      },
    },
  });

  // GTAG datalayer
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'purchase', {
      transaction_id: transactionId,
      value: donationAmount,
      coupon: mailCode,
      action: 'purchase',
      items: [
        {
          item_name: domainUrl,
          item_id: id,
          price: donationAmount,
          item_brand: 'General',
          item_category: category,
          item_variant: variant,
          coupon: mailCode,
          quantity: 1,
        },
      ],
    });
  }
};

export const gtmEventSetConfirmationPageView = (domainUrl: string) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'confirmationPageView',
    eventCategory: 'Donation Form',
    eventAction: 'Confirmation Page Viewed',
    eventLabel: 'Confirmation Page Viewed',
    eventValue: undefined,
    eventOrigin: 'front-end',
    eventVars: {
      confirmationForForm: domainUrl,
    },
  });
};

/**
 * This method returns the data object for the ThreeDSecure verification
 * request.
 *
 * @param options options object from the `submitPayment` thunk
 * @param formikValues form values from formik context
 * @param hideExtraBillingFields boolean value from GraphCMS indicating whether
 * we are on a "streamline" form
 */
export function returnThreeDSecureVerificationData({
  options,
  formikValues,
  ipAddress,
  hideExtraBillingFields,
}: {
  options: OptionsPartial;
  formikValues: any;
  ipAddress: string;
  hideExtraBillingFields: boolean;
}) {
  const nonce: string = options.payload?.nonce;
  const bin: string = options.payload?.details?.bin;
  const amount: string =
    formikValues.donationAmount === 'OtherAmount'
      ? formikValues.otherAmount
      : formikValues.donationAmount;
  const countryCode = Object.keys(countries).find(
    key =>
      countries[key].name ===
      countriesList[Number(formikValues.billingCountry - 1)].country
  );

  let threeDSecureVerificationData: Record<string, any> = {
    nonce,
    amount,
    bin,
    email: formikValues.personalEmail,
    billingAddress: {
      givenName: formikValues.personalFirstName,
      surName: formikValues.personalLastName,
    },
    additionalInformation: {
      ipAddress,
    },
    // According to Braintree support, adding this flag will automatically send
    // browserColorDepth, browserJavaEnabled , browserJavascriptEnabled,
    // browserLanguage, browserScreenHeight, browserScreenWidth,
    // browserTimeZone, deviceChannel in 3DS request
    collectDeviceData: true,
    onLookupComplete: (_data, next) => {
      next();
    },
  };

  if (!hideExtraBillingFields) {
    threeDSecureVerificationData = {
      ...threeDSecureVerificationData,
      billingAddress: {
        ...threeDSecureVerificationData.billingAddress,
        phoneNumber: formikValues.billingPhone,
        streetAddress: formikValues.billingAddress1,
        extendedAddress: formikValues.billingAddress2,
        locality: formikValues.billingCity,
        region: returnStateOrProvinceAbbreviation(
          formikValues.billingState,
          formikValues.billingCountry
        ),
        postalCode: formikValues.billingZipCode,
        countryCodeAlpha2: countryCode,
      },
    };
  }

  return threeDSecureVerificationData;
}

/** Generate options for title select inputs, using CMS values */
export function getTitleInputOptions(titleOptions: string[]) {
  return titleOptions.map(title => ({ title_id: title, title_name: title }));
}

/** Filters out CMS content with template tags
 * and return an array of strings separated by the tag
 */
export function getSeparatedTemplateContent(content: string) {
  return content.split(CMS_TEMPLATE_REGEX);
}

export const getLocaleLabel = (localeCode: string) => {
  return Object.keys(LANGUAGE_OPTIONS).find(
    key => LANGUAGE_OPTIONS[key] === localeCode
  );
};

/** Returns the current IP address of the user using the ipify API
 */
export const getIpAddress = async () => {
  const response = await fetch('https://api.ipify.org?format=json');
  const data: { ip: string } = await response.json();

  if (!data.ip) {
    return '';
  }

  return data.ip;
};
