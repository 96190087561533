"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethod = {
    CREDIT_CARD: 'creditCard',
    PAYPAL: 'paypal',
    BANK_ACCOUNT: 'bankAccount',
    SAVED_PAYMENT: 'savedPayment',
    GOOGLE_PAY: 'AndroidPayCard',
    APPLE_PAY: 'ApplePayCard',
};
// TODO: refactor all constants into one file
exports.DEFAULT_TEMPLATE = 'givenow';
exports.EMAIL_REGEX = /^[^@]+@[^@]+$/;
exports.CMS_TEMPLATE_REGEX = /\{.*?\}/;
exports.USStateList = [
    { state_id: 3789, state: 'AE - Armed Forces Americas' },
    {
        state_id: 3790,
        state: 'AA - Armed Forces Europe, Middle East, & Canada',
    },
    { state_id: 3791, state: 'AK - Alaska' },
    { state_id: 3792, state: 'AL - Alabama' },
    { state_id: 3793, state: 'AP - Armed Forces Pacific' },
    { state_id: 3794, state: 'AR - Arkansas' },
    { state_id: 3795, state: 'AS - American Samoa' },
    { state_id: 3796, state: 'AZ - Arizona' },
    { state_id: 3797, state: 'CA - California' },
    { state_id: 3798, state: 'CO - Colorado' },
    { state_id: 3799, state: 'CT - Connecticut' },
    { state_id: 3800, state: 'DC - District of Columbia' },
    { state_id: 3801, state: 'DE - Delaware' },
    { state_id: 3802, state: 'FL - Florida' },
    { state_id: 3803, state: 'FM - Federated States of Micronesia' },
    { state_id: 3804, state: 'GA - Georgia' },
    { state_id: 3805, state: 'GU - Guam' },
    { state_id: 3806, state: 'HI - Hawaii' },
    { state_id: 3807, state: 'IA - Iowa' },
    { state_id: 3808, state: 'ID - Idaho' },
    { state_id: 3809, state: 'IL - Illinois' },
    { state_id: 3810, state: 'IN - Indiana' },
    { state_id: 3811, state: 'KS - Kansas' },
    { state_id: 3812, state: 'KY - Kentucky' },
    { state_id: 3813, state: 'LA - Louisiana' },
    { state_id: 3814, state: 'MA - Massachusetts' },
    { state_id: 3815, state: 'MD - Maryland' },
    { state_id: 3816, state: 'ME - Maine' },
    { state_id: 3817, state: 'MH - Marshall Islands' },
    { state_id: 3818, state: 'MI - Michigan' },
    { state_id: 3819, state: 'MN - Minnesota' },
    { state_id: 3820, state: 'MO - Missouri' },
    { state_id: 3821, state: 'MP - Northern Mariana Islands' },
    { state_id: 3822, state: 'MS - Mississippi' },
    { state_id: 3823, state: 'MT - Montana' },
    { state_id: 3824, state: 'NC - North Carolina' },
    { state_id: 3825, state: 'ND - North Dakota' },
    { state_id: 3826, state: 'NE - Nebraska' },
    { state_id: 3827, state: 'NH - New Hampshire' },
    { state_id: 3828, state: 'NJ - New Jersey' },
    { state_id: 3829, state: 'NM - New Mexico' },
    { state_id: 3830, state: 'NV - Nevada' },
    { state_id: 3831, state: 'NY - New York' },
    { state_id: 3832, state: 'OH - Ohio' },
    { state_id: 3833, state: 'OK - Oklahoma' },
    { state_id: 3834, state: 'OR - Oregon' },
    { state_id: 3835, state: 'PA - Pennsylvania' },
    { state_id: 3836, state: 'PW - Palau' },
    { state_id: 3837, state: 'RI - Rhode Island' },
    { state_id: 3838, state: 'SC - South Carolina' },
    { state_id: 3839, state: 'SD - South Dakota' },
    { state_id: 3840, state: 'TN - Tennessee' },
    { state_id: 3841, state: 'TX - Texas' },
    { state_id: 3842, state: 'UT - Utah' },
    { state_id: 3843, state: 'VA - Virginia' },
    { state_id: 3844, state: 'VI - Virgin Islands' },
    { state_id: 3845, state: 'VT - Vermont' },
    { state_id: 3846, state: 'WA - Washington' },
    { state_id: 3847, state: 'WI - Wisconsin' },
    { state_id: 3848, state: 'WV - West Virginia' },
    { state_id: 3849, state: 'WY - Wyoming' },
    { state_id: 3850, state: 'None' },
];
exports.CAProvincesList = [
    { province_id: 0, province: 'AB - Alberta' },
    { province_id: 1, province: 'BC - British Columbia' },
    { province_id: 2, province: 'LB - Labrador' },
    { province_id: 3, province: 'MB - Manitoba' },
    { province_id: 4, province: 'NB - New Brunswick' },
    { province_id: 5, province: 'NF - Newfoundland' },
    { province_id: 6, province: 'NS - Nova Scotia' },
    { province_id: 7, province: 'NU - Nunavut' },
    { province_id: 8, province: 'NW - North West Terr.' },
    { province_id: 9, province: 'ON - Ontario' },
    { province_id: 10, province: 'PE - Prince Edward Is.' },
    { province_id: 11, province: 'QC - Quebec' },
    { province_id: 12, province: 'SK - Saskatchewen' },
    { province_id: 13, province: 'YU - Yukon' },
];
exports.countriesList = [
    { country_id: 1, country: 'Afghanistan', country_code: 'AF' },
    { country_id: 2, country: 'Albania', country_code: 'AL' },
    { country_id: 3, country: 'Algeria', country_code: 'DZ' },
    { country_id: 4, country: 'American Samoa', country_code: 'AS' },
    { country_id: 5, country: 'Andorra', country_code: 'AD' },
    { country_id: 6, country: 'Angola', country_code: 'AO' },
    { country_id: 7, country: 'Anguilla', country_code: 'AI' },
    { country_id: 8, country: 'Antarctica', country_code: 'AQ' },
    { country_id: 9, country: 'Antigua & Barbuda', country_code: 'AG' },
    { country_id: 10, country: 'Argentina', country_code: 'AR' },
    { country_id: 11, country: 'Armenia', country_code: 'AM' },
    { country_id: 12, country: 'Arub', country_code: '' },
    { country_id: 13, country: 'Australia', country_code: 'AU' },
    { country_id: 14, country: 'Austria', country_code: 'AT' },
    { country_id: 15, country: 'Azerbaijan', country_code: 'AZ' },
    { country_id: 16, country: 'Bahamas', country_code: 'BS' },
    { country_id: 17, country: 'Bahrain', country_code: 'BH' },
    { country_id: 18, country: 'Bangladesh', country_code: 'BD' },
    { country_id: 19, country: 'Barbados', country_code: 'BB' },
    { country_id: 20, country: 'Belarus', country_code: 'BY' },
    { country_id: 21, country: 'Belgium', country_code: 'BE' },
    { country_id: 22, country: 'Belize', country_code: 'BZ' },
    { country_id: 23, country: 'Benin', country_code: 'BJ' },
    { country_id: 24, country: 'Bermuda', country_code: 'BM' },
    { country_id: 25, country: 'Bhutan', country_code: 'BT' },
    { country_id: 26, country: 'Bolivia', country_code: 'BO' },
    // Possible Typo in Herzgowina, should be Herzgovina?
    { country_id: 27, country: 'Bosnia & Herzegowina', country_code: 'BA' },
    { country_id: 28, country: 'Botswana', country_code: 'BW' },
    { country_id: 29, country: 'Bouvet Island', country_code: 'BV' },
    { country_id: 30, country: 'Brazil', country_code: 'BR' },
    {
        country_id: 31,
        country: 'British Indian Ocean Territory',
        country_code: 'IO',
    },
    { country_id: 32, country: 'British Virgin Islands', country_code: 'VG' },
    { country_id: 33, country: 'Brunei', country_code: 'BN' },
    { country_id: 34, country: 'Bulgaria', country_code: 'BG' },
    { country_id: 35, country: 'Burkina Faso', country_code: 'BF' },
    { country_id: 36, country: 'Burundi', country_code: 'BI' },
    { country_id: 37, country: 'Cambodia', country_code: 'KH' },
    { country_id: 38, country: 'Cameroon', country_code: 'CM' },
    { country_id: 39, country: 'Canada', country_code: 'CA' },
    { country_id: 40, country: 'Cape Verde', country_code: 'CV' },
    { country_id: 41, country: 'Cayman Islands', country_code: 'KY' },
    {
        country_id: 42,
        country: 'Central African Republic',
        country_code: 'CF',
    },
    { country_id: 43, country: 'Chad', country_code: 'TD' },
    { country_id: 44, country: 'Chile', country_code: 'CL' },
    { country_id: 45, country: 'China', country_code: 'CN' },
    { country_id: 46, country: 'Christmas Island', country_code: 'CX' },
    {
        country_id: 47,
        country: 'Cocos (Keeling) Islands',
        country_code: 'CC',
    },
    { country_id: 48, country: 'Colombia', country_code: 'CO' },
    { country_id: 49, country: 'Comoros', country_code: 'KM' },
    { country_id: 50, country: 'Congo', country_code: 'CG' },
    {
        country_id: 51,
        country: 'Congo, Democratic Republic',
        country_code: 'CD',
    },
    { country_id: 52, country: 'Cook Islands', country_code: 'CK' },
    { country_id: 53, country: 'Costa Rica', country_code: 'CR' },
    { country_id: 54, country: 'Ivory Coast', country_code: 'CI' },
    { country_id: 55, country: 'Croatia', country_code: 'HR' },
    { country_id: 56, country: 'Cuba', country_code: 'CU' },
    { country_id: 57, country: 'Cyprus', country_code: 'CY' },
    { country_id: 58, country: 'Czech Republic', country_code: 'CZ' },
    { country_id: 59, country: 'Denmark', country_code: 'DK' },
    { country_id: 60, country: 'Djibouti', country_code: 'DJ' },
    { country_id: 61, country: 'Dominica', country_code: 'DM' },
    { country_id: 62, country: 'Dominican Republic', country_code: 'DO' },
    { country_id: 63, country: 'East Timor', country_code: 'TL' },
    { country_id: 64, country: 'Ecuador', country_code: 'EC' },
    { country_id: 65, country: 'Egypt', country_code: 'EG' },
    { country_id: 66, country: 'El Salvador', country_code: 'SV' },
    { country_id: 67, country: 'Equatorial Guinea', country_code: 'GQ' },
    { country_id: 68, country: 'Eritrea', country_code: 'ER' },
    { country_id: 69, country: 'Estonia', country_code: 'EE' },
    { country_id: 70, country: 'Ethiopia', country_code: 'ET' },
    { country_id: 71, country: 'Falkland Islands', country_code: 'FK' },
    { country_id: 72, country: 'Faroe Islands', country_code: 'FO' },
    { country_id: 73, country: 'Fiji', country_code: 'FJ' },
    { country_id: 74, country: 'Finland', country_code: 'FI' },
    { country_id: 75, country: 'France', country_code: 'FR' },
    { country_id: 76, country: 'France, Metropolitan', country_code: 'FX' },
    { country_id: 77, country: 'French Guiana', country_code: 'GF' },
    { country_id: 78, country: 'French Polynesia', country_code: 'PF' },
    {
        country_id: 79,
        country: 'French Southern Territories',
        country_code: 'TF',
    },
    { country_id: 80, country: 'Gabon', country_code: 'GA' },
    { country_id: 81, country: 'Gambia', country_code: 'GM' },
    { country_id: 82, country: 'Georgia', country_code: 'GE' },
    { country_id: 83, country: 'Germany', country_code: 'DE' },
    { country_id: 84, country: 'Ghana', country_code: 'GH' },
    { country_id: 85, country: 'Gibraltar', country_code: 'GI' },
    { country_id: 86, country: 'Greece', country_code: 'GR' },
    { country_id: 87, country: 'Greenland', country_code: 'GL' },
    { country_id: 88, country: 'Grenada', country_code: 'GD' },
    { country_id: 89, country: 'Guadeloupe', country_code: 'GP' },
    { country_id: 90, country: 'Guam', country_code: 'GU' },
    { country_id: 91, country: 'Guatemala', country_code: 'GT' },
    { country_id: 92, country: 'Guinea', country_code: 'GN' },
    { country_id: 93, country: 'Guinea-Bissau', country_code: 'GW' },
    { country_id: 94, country: 'Guyana', country_code: 'GY' },
    { country_id: 95, country: 'Haiti', country_code: 'HT' },
    {
        country_id: 96,
        country: 'Heard and McDonald Islands',
        country_code: 'HM',
    },
    { country_id: 97, country: 'Honduras', country_code: 'HN' },
    { country_id: 98, country: 'Hong Kong', country_code: 'HK' },
    { country_id: 99, country: 'Hungary', country_code: 'HU' },
    { country_id: 100, country: 'Iceland', country_code: 'IS' },
    { country_id: 101, country: 'India', country_code: 'IN' },
    { country_id: 102, country: 'Indonesia', country_code: 'ID' },
    { country_id: 103, country: 'Iran', country_code: 'IR' },
    { country_id: 104, country: 'Iraq', country_code: 'IQ' },
    { country_id: 105, country: 'Ireland', country_code: 'IE' },
    { country_id: 106, country: 'Israel', country_code: 'IL' },
    { country_id: 107, country: 'Italy', country_code: 'IT' },
    { country_id: 108, country: 'Jamaica', country_code: 'JM' },
    { country_id: 109, country: 'Japan', country_code: 'JP' },
    { country_id: 110, country: 'Jordan', country_code: 'JO' },
    { country_id: 111, country: 'Kazakhstan', country_code: 'KZ' },
    { country_id: 112, country: 'Kenya', country_code: 'KE' },
    { country_id: 113, country: 'Kiribati', country_code: 'KI' },
    { country_id: 114, country: 'Korea, North', country_code: 'KP' },
    { country_id: 115, country: 'Korea, South', country_code: 'KR' },
    { country_id: 116, country: 'Kuwait', country_code: 'KW' },
    { country_id: 117, country: 'Kyrgyzstan', country_code: 'KG' },
    { country_id: 118, country: 'Laos', country_code: 'LA' },
    { country_id: 119, country: 'Latvia', country_code: 'LV' },
    { country_id: 120, country: 'Lebanon', country_code: 'LB' },
    { country_id: 121, country: 'Lesotho', country_code: 'LS' },
    { country_id: 122, country: 'Liberia', country_code: 'LR' },
    { country_id: 123, country: 'Libya', country_code: 'LY' },
    { country_id: 124, country: 'Liechtenstein', country_code: 'LI' },
    { country_id: 125, country: 'Lithuania', country_code: 'LT' },
    { country_id: 126, country: 'Luxembourg', country_code: 'LU' },
    { country_id: 127, country: 'Macau', country_code: 'MO' },
    { country_id: 128, country: 'Macedonia', country_code: 'MK' },
    { country_id: 129, country: 'Madagascar', country_code: 'MG' },
    { country_id: 130, country: 'Malawi', country_code: 'MW' },
    { country_id: 131, country: 'Malaysia', country_code: 'MY' },
    { country_id: 132, country: 'Maldives', country_code: 'MV' },
    { country_id: 133, country: 'Mali', country_code: 'ML' },
    { country_id: 134, country: 'Malta', country_code: 'MT' },
    { country_id: 135, country: 'Marshall Islands', country_code: 'MH' },
    { country_id: 136, country: 'Martinique', country_code: 'MQ' },
    { country_id: 137, country: 'Mauritania', country_code: 'MR' },
    { country_id: 138, country: 'Mauritius', country_code: 'MU' },
    { country_id: 139, country: 'Mayotte', country_code: 'YT' },
    { country_id: 140, country: 'Mexico', country_code: 'MX' },
    {
        country_id: 141,
        country: 'Micronesia, Federated States of',
        country_code: 'FM',
    },
    { country_id: 142, country: 'Moldova', country_code: 'MD' },
    { country_id: 143, country: 'Monaco', country_code: 'MC' },
    { country_id: 144, country: 'Mongolia', country_code: 'MN' },
    { country_id: 145, country: 'Montserrat', country_code: 'MS' },
    { country_id: 146, country: 'Morocco', country_code: 'MA' },
    { country_id: 147, country: 'Mozambique', country_code: 'MZ' },
    { country_id: 148, country: 'Myanmar', country_code: 'MM' },
    { country_id: 149, country: 'Namibia', country_code: 'NA' },
    { country_id: 150, country: 'Nauru', country_code: 'NR' },
    { country_id: 151, country: 'Nepal', country_code: 'NP' },
    { country_id: 152, country: 'Netherlands', country_code: 'NL' },
    {
        country_id: 153,
        country: 'Netherlands Antilles',
        country_code: 'AN',
    },
    { country_id: 154, country: 'New Caledonia', country_code: 'NC' },
    { country_id: 155, country: 'New Zealand', country_code: 'NZ' },
    { country_id: 156, country: 'Nicaragua', country_code: 'NI' },
    { country_id: 157, country: 'Niger', country_code: 'NE' },
    { country_id: 158, country: 'Nigeria', country_code: 'NG' },
    { country_id: 159, country: 'Niue', country_code: 'NU' },
    { country_id: 160, country: 'Norfolk Island', country_code: 'NF' },
    {
        country_id: 161,
        country: 'Northern Mariana Islands',
        country_code: 'MP',
    },
    { country_id: 162, country: 'Norway', country_code: 'NO' },
    { country_id: 163, country: 'Oman', country_code: 'OM' },
    { country_id: 164, country: 'Pakistan', country_code: 'PK' },
    { country_id: 165, country: 'Palau', country_code: 'PW' },
    { country_id: 166, country: 'Panama', country_code: 'PA' },
    { country_id: 167, country: 'Papau New Guinea', country_code: 'PG' },
    { country_id: 168, country: 'Paraguay', country_code: 'PY' },
    { country_id: 169, country: 'Peru', country_code: 'PE' },
    { country_id: 170, country: 'Philippines', country_code: 'PH' },
    { country_id: 171, country: 'Pitcairn', country_code: 'PN' },
    { country_id: 172, country: 'Poland', country_code: 'PL' },
    { country_id: 173, country: 'Portugal', country_code: 'PT' },
    { country_id: 174, country: 'Puerto Rico', country_code: 'PR' },
    { country_id: 175, country: 'Qatar', country_code: 'QA' },
    { country_id: 176, country: 'Reunion', country_code: 'RE' },
    { country_id: 177, country: 'Romania', country_code: 'RO' },
    { country_id: 178, country: 'Russia', country_code: 'RU' },
    { country_id: 179, country: 'Rwanda', country_code: 'RW' },
    {
        country_id: 180,
        country: 'Saint Kitts and Nevis',
        country_code: 'KN',
    },
    { country_id: 181, country: 'Saint Lucia', country_code: 'LC' },
    {
        country_id: 182,
        country: 'Saint Vincent and the Grenadines',
        country_code: 'VC',
    },
    { country_id: 183, country: 'Samoa', country_code: 'WS' },
    { country_id: 184, country: 'San Marino', country_code: 'SM' },
    {
        country_id: 185,
        country: 'Sao Tome and Principe',
        country_code: 'ST',
    },
    { country_id: 186, country: 'Saudi Arabia', country_code: 'SA' },
    { country_id: 187, country: 'Senegal', country_code: 'SN' },
    { country_id: 188, country: 'Seychelles', country_code: 'SC' },
    { country_id: 189, country: 'Sierra Leone', country_code: 'SL' },
    { country_id: 190, country: 'Singapore', country_code: 'SG' },
    { country_id: 191, country: 'Slovakia', country_code: 'SK' },
    { country_id: 192, country: 'Slovenia', country_code: 'SI' },
    { country_id: 193, country: 'Solomon Islands', country_code: 'SB' },
    { country_id: 194, country: 'Somalia', country_code: 'SO' },
    { country_id: 195, country: 'South Africa', country_code: 'ZA' },
    {
        country_id: 196,
        country: 'South Georgia and the South Sandwich Islands',
        country_code: 'GS',
    },
    { country_id: 197, country: 'Spain', country_code: 'ES' },
    { country_id: 198, country: 'Sri Lanka', country_code: 'LK' },
    { country_id: 199, country: 'St. Helena', country_code: 'SH' },
    {
        country_id: 200,
        country: 'St. Pierre and Miquelon',
        country_code: 'PM',
    },
    { country_id: 201, country: 'Sudan', country_code: 'SD' },
    { country_id: 202, country: 'Suriname', country_code: 'SR' },
    {
        country_id: 203,
        country: 'Svalbard and Jan Mayen Islands',
        country_code: 'SJ',
    },
    { country_id: 204, country: 'Swaziland', country_code: 'SZ' },
    { country_id: 205, country: 'Sweden', country_code: 'SE' },
    { country_id: 206, country: 'Switzerland', country_code: 'CH' },
    { country_id: 207, country: 'Syria', country_code: 'SY' },
    { country_id: 208, country: 'Taiwan', country_code: 'TW' },
    { country_id: 209, country: 'Tajikistan', country_code: 'TJ' },
    { country_id: 210, country: 'Tanzania', country_code: 'TZ' },
    { country_id: 211, country: 'Thailand', country_code: 'TH' },
    { country_id: 212, country: 'Togo', country_code: 'TG' },
    { country_id: 213, country: 'Tokelau', country_code: 'TK' },
    { country_id: 214, country: 'Tonga', country_code: 'TO' },
    { country_id: 215, country: 'Trinidad and Tobago', country_code: 'TT' },
    { country_id: 216, country: 'Tunisia', country_code: 'TN' },
    { country_id: 217, country: 'Turkey', country_code: 'TR' },
    { country_id: 218, country: 'Turkmenistan', country_code: 'TM' },
    {
        country_id: 219,
        country: 'Turks and Caicos Islands',
        country_code: 'TC',
    },
    { country_id: 220, country: 'Tuvalu', country_code: 'TV' },
    { country_id: 221, country: 'Uganda', country_code: 'UG' },
    { country_id: 222, country: 'Ukraine', country_code: 'UA' },
    {
        country_id: 223,
        country: 'United Arab Emirates',
        country_code: 'AE',
    },
    { country_id: 224, country: 'United Kingdom', country_code: 'GB' },
    { country_id: 225, country: 'United States', country_code: 'US' },
    {
        country_id: 226,
        country: 'United States Minor Outlying Islands',
        country_code: 'UM',
    },
    { country_id: 227, country: 'Uruguay', country_code: 'UY' },
    { country_id: 228, country: 'Uzbekistan', country_code: 'UZ' },
    { country_id: 229, country: 'Vanuatu', country_code: 'VU' },
    { country_id: 230, country: 'Vatican City State', country_code: 'VA' },
    { country_id: 231, country: 'Venezuela', country_code: 'VE' },
    { country_id: 232, country: 'Vietnam', country_code: 'VN' },
    {
        country_id: 233,
        country: 'Wallis and Futuna Islands',
        country_code: 'WF',
    },
    { country_id: 234, country: 'Western Sahara', country_code: 'EH' },
    { country_id: 235, country: 'Yemen', country_code: 'YE' },
    { country_id: 236, country: 'Yugoslavia', country_code: 'YU' },
    { country_id: 237, country: 'Zambia', country_code: 'ZM' },
    { country_id: 238, country: 'Zimbabwe', country_code: 'ZW' },
    { country_id: 239, country: 'Virgin Islands (U.S.)', country_code: 'VI' },
    {
        country_id: 240,
        country: 'Serbia and Montenegro',
        country_code: 'CS',
    },
    { country_id: 241, country: 'Montenegro', country_code: 'ME' },
    { country_id: 242, country: 'Palestinian Territory', country_code: 'PS' },
    { country_id: 243, country: 'Serbia', country_code: 'RS' },
    { country_id: 244, country: 'Timor-Leste', country_code: 'TL' },
    // Yemen?
    { country_id: 245, country: 'Jemen', country_code: '' },
];
