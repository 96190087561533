import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from 'shared/utils';
import { PaymentActions } from 'redux/dux/payment';
import { Field } from 'formik';
import { ToggleInputFormik } from 'components/FormControls';

interface Props {
  checkboxStyle: { container: React.CSSProperties; label: React.CSSProperties };
  label: string;
}

const DoubleTheDonation = ({ checkboxStyle, label }: Props) => {
  const dispatch = useDispatch();
  const { doubleTheDonation } = useTypedSelector(
    ({ payment: { doubleTheDonation } }) => ({
      doubleTheDonation,
    })
  );
  return (
    <Fragment>
      <Field
        name="doubleTheDonation"
        label={label}
        checkboxStyle={checkboxStyle}
        component={ToggleInputFormik}
        checked={doubleTheDonation}
        onChange={() =>
          dispatch(PaymentActions.setDoubleTheDonation(!doubleTheDonation))
        }
      />
      <div
        id="dd-company-name-input"
        style={{ display: doubleTheDonation ? 'block' : 'none' }}
      />
    </Fragment>
  );
};

export default DoubleTheDonation;
