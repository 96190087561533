import React from 'react';

export default function CheckCircle() {
  return (
    <svg width="24" height="24">
      <g>
        <path
          fill="#fff"
          d="M12,1A11,11,0,1,0,23,12,11.012,11.012,0,0,0,12,1Z"
        />
        <path
          fill="#90d307"
          d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z"
        />
      </g>
    </svg>
  );
}
