import React from 'react';

export interface DonationTheme {
  header: React.CSSProperties;
  logoImage: React.CSSProperties;
  page: React.CSSProperties;
  sectionHeader: React.CSSProperties;
  mainContent: React.CSSProperties;
  homePage: {
    descriptionText: React.CSSProperties;
    description2Text: React.CSSProperties;
    youtubeVideoContainer: React.CSSProperties;
    videoStyle: React.CSSProperties;
  };
  label: React.CSSProperties;
  form: React.CSSProperties;
  smallButtonGroup: {
    firstButton?: React.CSSProperties;
    secondButton?: React.CSSProperties;
    thirdButton?: React.CSSProperties;
  };
  smallAmountButton: React.CSSProperties;
  checkBox: {
    container: React.CSSProperties;
    label: React.CSSProperties;
  };
  textInput: {
    label: React.CSSProperties;
    input: React.CSSProperties;
  };
  footerButton: React.CSSProperties;
  footerImage: React.CSSProperties;
  subheaderCTA: React.CSSProperties;
}

interface Theme {
  offset: DonationTheme;
  centered: DonationTheme;
  fiftyfifty: DonationTheme;
}

const theme: Theme = {
  offset: {
    header: {
      height: 109,
    },
    mainContent: {},
    logoImage: {
      width: 119,
      height: 51,
    },
    //@media screen and (min-width: 1100px) for page
    page: {
      marginLeft: 'auto',
      marginRight: '4.166666666666667%' /*60px*/,
      backgroundColor: '#fff',
      width: '100%',
      maxWidth: 660,
    },
    //
    homePage: {
      descriptionText: {
        textAlign: 'center',
        margin: '0 0 30px',
      },
      youtubeVideoContainer: {
        margin: '0 auto',
        maxWidth: 600,
      },
      videoStyle: { alignSelf: 'center' },
      description2Text: { textAlign: 'center' },
    },
    sectionHeader: {
      height: 22.7,
      fontSize: 20,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.2,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#4b79bc',
      textTransform: 'uppercase',
      maxWidth: 600,
      marginTop: 40,
    },
    label: {
      display: 'block',
      fontSize: 16,
      marginBottom: 5,
      fontWeight: 'bold',
      color: '#8d8c8f',
    },
    form: {
      flexDirection: 'column',
      flex: '1 1 auto',
    },
    checkBox: {
      label: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 15,
        display: 'inline-block',
      },
      container: {
        paddingLeft: 10,
        backgroundColor: '#efefef',
        borderRadius: 8,
      },
    },
    smallButtonGroup: {
      firstButton: { borderRadius: '8px 0 0 8px', borderRightStyle: 'none' },
      secondButton: { borderRightStyle: 'none' },
      thirdButton: { borderRadius: '0 8px 8px 0' },
    },
    smallAmountButton: {
      fontSize: 20,
      width: 108,
      height: 49,
      borderRadius: 8,
      flex: 0,
      backgroundColor: '#efefef',
      color: '#bfbcba',
    },
    textInput: {
      label: {
        fontWeight: 500,
        fontSize: 14,
      },
      input: {
        border: 'solid 1px #dddddd',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        fontSize: 12,
      },
    },
    footerButton: {
      margin: '10px auto 0',
      minWidth: 140,
    },
    footerImage: {
      paddingBottom: 36,
      margin: '33px auto 0',
    },
    subheaderCTA: {
      fontSize: 16,
      margin: '50px auto 10px',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#000000',
    },
  },

  centered: {
    header: {
      flex: 1,
      justifyContent: 'space-between',
      margin: '0 20px',
    },
    mainContent: {
      maxWidth: 660,
      margin: '0 auto',
    },
    logoImage: {
      width: 87,
      height: 37,
    },
    // @media screen and (min-width: 1100px) for page
    page: {
      flex: 1,
      backgroundColor: '#fff',
    },
    //
    sectionHeader: {
      fontSize: 20,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.2,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#4b79bc',
      textTransform: 'uppercase',
      marginTop: 40,
    },
    homePage: {
      descriptionText: {
        textAlign: 'center',
        margin: '0 0 30px',
        paddingTop: 18,
      },
      youtubeVideoContainer: {
        margin: '0 auto',
        maxWidth: 600,
      },
      videoStyle: { alignSelf: 'center' },
      description2Text: { textAlign: 'center' },
    },

    label: {
      display: 'block',
      fontSize: 16,
      marginBottom: 5,
      fontWeight: 'bold',
      color: '#8d8c8f',
    },

    form: {
      flexDirection: 'column',
      flex: ' 1 1 auto',
    },
    checkBox: {
      label: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 15,
        display: 'inline-block',
      },
      container: {
        paddingLeft: 10,
        backgroundColor: '#efefef',
        borderRadius: 8,
      },
    },

    smallButtonGroup: {
      firstButton: { borderRadius: '8px 0 0 8px', borderRightStyle: 'none' },
      secondButton: { borderRightStyle: 'none' },
      thirdButton: { borderRadius: '0 8px 8px 0' },
    },
    smallAmountButton: {
      fontSize: 20,
      width: 108,
      height: 49,
      borderRadius: 8,
      flex: 0,
      backgroundColor: '#efefef',
      color: '#bfbcba',
    },
    textInput: {
      label: {
        fontWeight: 500,
        fontSize: 14,
      },
      input: {
        border: 'solid 1px #dddddd',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        fontSize: 12,
      },
    },
    footerButton: {
      margin: '20px auto 0',
    },
    footerImage: {
      paddingBottom: 62,
      margin: '47px auto 0',
      minWidth: 140,
    },
    subheaderCTA: {
      fontSize: 16,
      margin: '50px auto 10px',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#000000',
    },
  },

  fiftyfifty: {
    header: {
      flex: 1,
      justifyContent: 'flex-start',
      marginLeft: 18,
    },
    mainContent: {
      maxWidth: 660,
      margin: '0 auto',
    },
    logoImage: {
      width: 87,
      height: 37,
    },
    // @media screen and (min-width: 1100px) for page
    page: {
      flex: 1,
      backgroundColor: '#fff',
    },
    //
    sectionHeader: {
      fontSize: 20,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.2,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#4b79bc',
      textTransform: 'uppercase',
      marginTop: 40,
    },
    homePage: {
      descriptionText: {
        textAlign: 'center',
        margin: '0 0 30px',
        paddingTop: 18,
      },
      youtubeVideoContainer: {
        margin: '0 auto',
        maxWidth: 600,
      },
      videoStyle: { alignSelf: 'center' },
      description2Text: { textAlign: 'center' },
    },

    label: {
      display: 'block',
      fontSize: 16,
      marginBottom: 5,
      fontWeight: 'bold',
      color: '#8d8c8f',
    },

    form: {
      flexDirection: 'column',
      flex: ' 1 1 auto',
    },
    checkBox: {
      label: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 15,
        display: 'inline-block',
      },
      container: {
        paddingLeft: 10,
        backgroundColor: '#efefef',
        borderRadius: 8,
      },
    },

    smallButtonGroup: {
      firstButton: { borderRadius: '8px 0 0 8px', borderRightStyle: 'none' },
      secondButton: { borderRightStyle: 'none' },
      thirdButton: { borderRadius: '0 8px 8px 0' },
    },
    smallAmountButton: {
      fontSize: 20,
      width: 108,
      height: 49,
      borderRadius: 8,
      flex: 0,
      backgroundColor: '#efefef',
      color: '#bfbcba',
    },
    textInput: {
      label: {
        fontWeight: 500,
        fontSize: 14,
      },
      input: {
        border: 'solid 1px #dddddd',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        fontSize: 12,
      },
    },
    footerButton: {
      margin: '20px auto 0',
    },
    footerImage: {
      paddingBottom: 62,
      margin: '47px auto 0',
      minWidth: 140,
    },
    subheaderCTA: {
      fontSize: 16,
      margin: '50px auto 10px',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#000000',
    },
  },
};

export default theme;
