import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import './CreditCard.css';

//TODO this component really shouldn't be called CreditCard, as it handles
// rendering all three payment options...
const CreditCard = props => {
  const {
    isActive,
    paymentMethod,
    paypalEmail,
    cardType,
    billingFirstName,
    billingLastName,
    cardNumberLast4,
    cardExpiryMonth,
    cardExpiryYear,
    institutionName,
    accountType,
    last4,
  } = props;

  if (paymentMethod === 'Paypal') {
    return (
      <div className="CreditCard--payPal">
        <Icon name="PayPal" color={isActive && '#fff'} />
        <p id="paypal-email">{paypalEmail}</p>
      </div>
    );
  }

  if (paymentMethod === 'Plaid') {
    return (
      // TODO These class names are not semantic, these elements do not all deal
      // with credit card transactions
      <div className="CreditCard">
        <div className="CreditCard__center tac">
          <div id="ach-name" className="CreditCard__holderName">
            {institutionName}
          </div>
          <div>
            {accountType} (<span className="CreditCard__last4">{last4}</span>)
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="CreditCard">
      <div className="CreditCard__left">{cardType}</div>
      <div className="CreditCard__center">
        <div className="CreditCard__holderName">
          <span>{billingFirstName}</span> <span>{billingLastName}</span>
        </div>
        <div>
          •••• •••• ••••{' '}
          <span className="CreditCard__last4">{cardNumberLast4}</span>
        </div>
      </div>
      <div className="CreditCard__right">
        <div>Exp.</div>
        <div>
          <span>{cardExpiryMonth}</span>/<span>{cardExpiryYear}</span>
        </div>
      </div>
    </div>
  );
};

CreditCard.propTypes = {
  isActive: PropTypes.bool,
  paymentMethod: PropTypes.string,
  paypalEmail: PropTypes.string,
  cardType: PropTypes.string,
  billingFirstName: PropTypes.string,
  billingLastName: PropTypes.string,
  cardNumberLast4: PropTypes.string,
  cardExpiryMonth: PropTypes.string,
  cardExpiryYear: PropTypes.string,
  institutionName: PropTypes.string,
  accountType: PropTypes.string,
  last4: PropTypes.string,
};

export default CreditCard;
