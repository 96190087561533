import { ROUTES } from 'constants/constants';
import { PaymentActions } from 'redux/dux/payment';
import { setConfirmation } from 'actions/confirmation';

export const handlePaymentSuccess = (
  history: any,
  match: any,
  response: any
) => async dispatch => {
  if (response.data.error) {
    let error = response.data.result;
    if (error.message === 'There was an issue with your transaction.') {
      const errFields = error.fromError ? Object.keys(error.fromError) : [];
      if (errFields.length && errFields[0] === 'email') {
        dispatch(
          PaymentActions.setError(
            'There was an issue with your email address. Please check that it is correct and try again.'
          )
        );
        dispatch(PaymentActions.setErrorReturnRoute(ROUTES.CONTACT_INFO));
      } else {
        dispatch(
          PaymentActions.setError(
            'There was an issue with your donation information. Please check that all of your information is correct and try again.'
          )
        );
      }
    } else {
      dispatch(
        PaymentActions.setError(
          `${response.data.result.paymentResponse.message} Please contact an administrator if the error persists.`
        )
      );
    }
  } else if (response.data.result.paymentResponse.success === false) {
    if (response.data.result.paymentResponse.errCode === '1004') {
      // This code is given when credit card address verification fails
      dispatch(PaymentActions.setErrorReturnRoute(ROUTES.ADDRESS));
    }
    dispatch(
      PaymentActions.setError(response.data.result.paymentResponse.message)
    );
  } else {
    // save data and go to confirmation page
    dispatch(setConfirmation({ ...response.data.result.paymentResponse }));
    history.push(
      `/${match.params.template}${ROUTES.CONFIRMATION}` +
        history.location.search,
      { ...response.data.result.paymentResponse }
    );
  }
};
